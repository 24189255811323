import React from 'react';
import { Paper, Button, Box, Divider } from '@material-ui/core/';
import useStyles from '../../Navbar/styles/theme';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Results from '../containers/Results';
import Skeleton from '@material-ui/lab/Skeleton';
import { omit } from 'lodash';

function SearchPreview(props) {
    const classes = useStyles();
    const { searchRoute, status } = props

    return <Paper className="search-view px-2 d-flex flex-column" classes={{ root: classes.searchResultsContainer }} {...omit(props, ['history', 'location', 'match', 'searchRoute', 'status'])}>
        <Box className={classes.searchPreviewBox}>
            <Results preview show={3} />
        </Box>
        <Divider />
        {status === 'loading' && <Skeleton className="w-25 align-self-center mt-1 mb-2" variant="text" />}
        {status !== 'loading' && <Button to={searchRoute} component={RouterLink} className="w-100 mb-1" color="primary">
            {status === 'complete' ? 'See all results' : 'Search Compass'}
        </Button>}
    </Paper>
}

SearchPreview.defaultProps = {
    status: ''
}

SearchPreview.propTypes = {
    status: PropTypes.string,
    elevation: PropTypes.number,
    searchRoute: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    })
}

export default SearchPreview


