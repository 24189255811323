import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import quickLinks from '../quickLinks';
import SidebarCard from '../../Sidebar/components/Card';
import { Explore } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { AppContext } from '../../App/context';
import '../styles/index.css'

function QuickLinks(props = {}) {
    const { component, headingComponent, ...rest } = props
    const { user } = useContext(AppContext) || {}

    if(!Array.isArray(quickLinks) || quickLinks.length === 0) return null

    const renderChildren = () => {
        return <SidebarCard identifier="quick-links" className="d-flex flex-column pb-1" title={'Quick Links'} headingComponent={headingComponent} headingStart={2} icon={<Explore />}>
            <div className="d-flex flex-wrap px-1 mt-1">
                {quickLinks.filter(o => isEmpty(user) ? !o.authRequired : true).map((o, index) => <Button key={`quick-links#${index}`} to={o.path} component={RouterLink} style={{ textTransform: 'none' }} color="primary" role={undefined}>
                    {o.label}
                </Button>)}
            </div>
        </SidebarCard>
    }

    return React.createElement(component, rest, renderChildren())
}

QuickLinks.defaultProps = {
    component: 'div'
}

export default QuickLinks