import {
    UPDATE_LEARNING_DATA, UPDATE_CURRENT_PAY, UPDATE_LEAVE_BALANCES, UPDATE_CURRENT_TIMESHEET, HR_DATA_STATUS, UPDATE_SERVICENOW_DATA, UPDATE_HR_SERVICENOW_DATA
} from '../actions-index';

export function profileData(state = {}, action = {}) {
    const { payload } = action
    const payloadWithSuccess = {
        ...payload,
        status: payload && payload.status ? payload.status : 'success'
    }
    switch (action.type) {
        case HR_DATA_STATUS: {
            return {
                ...state,
                status: payload
            }
        }
        case UPDATE_CURRENT_PAY:
            return {
                ...state,
                currentPay: payloadWithSuccess
            }
        case UPDATE_LEAVE_BALANCES:
            return {
                ...state,
                leaveBalances: payloadWithSuccess
            }
        case UPDATE_LEARNING_DATA:
            return {
                ...state,
                learning: payloadWithSuccess
            }
        case UPDATE_CURRENT_TIMESHEET:
            return {
                ...state,
                timesheetData: payloadWithSuccess
            }
        case UPDATE_SERVICENOW_DATA:
            return {
                ...state,
                serviceNowData: payload
            }
        case UPDATE_HR_SERVICENOW_DATA:
            return {
                ...state,
                hrServiceNowData: payload
            }
        default:
            return state
    }
}
