import React, { Fragment, useState, useContext, Suspense, memo } from 'react';
import { Switch, Route as ReactRoute, Redirect, useHistory } from "react-router-dom";
import SignInContainer from '../../Authentication/containers/SignIn';
import SignOut from '../../Authentication/containers/SignOut';
import { Helmet } from "react-helmet";
import NoComponentFound from './NoComponentFound';
import { AppContext } from '../context';
import { constructLazyPaths } from './Paths';
import { RedirectOptions } from '../../util/cookies';
import SimpleLoader from '../../Common/components/SimpleLoader';
import RenderableView from './RenderableView';
import { Typography } from '@material-ui/core';
import useInterval from '../../hooks/useInterval';

function AppRoutes({ mobile = false }) {
    const [title, setTitle] = useState('')
    const [announceNewPage, setAnnounceNewPage] = useState(false)
    const { darkMode, user = { status: '' }, showTemperatureTracker } = useContext(AppContext) || {}
    const redirectPath = RedirectOptions.get()
    const history = useHistory()
    const mobileOnlyRoutes = ['about-you']
    const redirectExclusions = ['/login', '/logout']
    const lazyPaths = constructLazyPaths({ mobile, showTemperatureTracker })

    useInterval(() => {
        setAnnounceNewPage(currentState => !!currentState ? false : currentState)
    }, 1000);
    
    const createTitle = (page = '') => (description = '') => {
        const newTitle = `${page}${description ? ` | ${description}` : ''}`

        if(title !== newTitle) setTitle(newTitle)
    }
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [history.length]);

    React.useEffect(() => {
        setAnnounceNewPage(true)
    }, [history.length, title, setAnnounceNewPage]);

    const appleTouchIconSizes = ['180x180', '120x120', '167x167', '152x152', '1024x1024'] //Values based on ios human interface guidelines
    const validateAutoRedirect = () => user.status === 'success' && !!redirectPath && redirectPath.length > 0 && !redirectExclusions.includes(redirectPath)

    const routes = <Switch>
        {validateAutoRedirect() && <Redirect from={`/login`} to={redirectPath} /> }
        {!mobile && mobileOnlyRoutes.map((path, index) =>
            <Redirect key={index} from={`/${path}`} to="/" />
        )}
        <Redirect from="/article/:id" to="/news/article/:id" />
        <ReactRoute exact path='/login' render={() => <SignInContainer generateTitle={createTitle('Log In')} />} />
        <ReactRoute exact path='/logout' render={() => <SignOut generateTitle={createTitle('Log Out')} />} />
        {lazyPaths.map((pathInformation, index) => <ReactRoute
            key={`route#${index}`} 
            exact
            path={pathInformation.path}
            render={(renderProps) => <RenderableView createTitle={createTitle} pathInformation={pathInformation} renderProps={renderProps} />}
         />)}
        <ReactRoute component={NoComponentFound} />
    </Switch>

return <Fragment>
        <Helmet>
            {!!title && <title>{title}</title>}
            {appleTouchIconSizes.map((size, index) => {
                let path = `${process.env.PUBLIC_URL}/images/touch-icons/`
                path += `compass-${darkMode ? 'light' : 'normal'}-${size}.png`

                if (path) return <link key={`apple-touch-${index}`} rel="apple-touch-icon" sizes={size} href={path} />
            })}
        </Helmet>
        {(announceNewPage && !!title) && <AccessibleStatusUpdate title={title} />}
        <Suspense fallback={<SimpleLoader role="alert" />}>
            {routes}
        </Suspense>
    </Fragment>
}

const AccessibleStatusUpdate = memo(({ title }) => {
    return <Typography variant="srOnly" role="status" aria-live="polite" aria-atomic="true">
        {`Navigated to new page ${title}`}
    </Typography>
})

export default AppRoutes