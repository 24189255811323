import React, { Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, Typography, Divider, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Image from '../../Common/components/Image';
import '../styles/index.css'

function BuildingListItem(props) {
    const { className, buttonProps, image, scrollId, id, divider, overline, sections, title, address, description, url, ariaLabel, children } = props
    const linkProps = {
        variant: 'subtitle2',
        ...ariaLabel ? { 'aria-label': ariaLabel } : {}
    }
    const validImage = !!image && !!image.src
    const generateLink = () => <Link {...linkProps} color="primary" target="_blank" rel="noopener" href={url}>{address}</Link>
    const renderDescription = (description) => React.isValidElement(description) ? description :  <ListItemText disableTypography primary={<Typography className="line-clamp-2" color="textSecondary" variant="body2">{description}</Typography>} />
    
    const baseListItem = <ListItem {...buttonProps} id={scrollId || id} className={className} alignItems={description ? 'flex-start' : 'center'}>
        {validImage && <Image hideOnError className="mr-1 building-image" fallbackType={image.fallbackType} alt={image.alt} src={image.src} />}
        <Box style={{ flexGrow: 1 }}>
            {React.isValidElement(overline) ? overline : <Typography color="secondary" variant="overline">{overline}</Typography>}
            <ListItemText
                disableTypography
                className={`my-0 ${!description ? 'pb-1' : ''}`.trim()}
                primary={React.isValidElement(title) ? title : <Typography component="div" variant="subtitle1">{title}</Typography>}
                {...!!address ? { secondary: generateLink() } : {}}
            />
            {!!description && renderDescription(description)}
            {Array.isArray(sections) && sections.length > 0 && sections.map((o, index) =>
                <Fragment key={`building-${id}${index}`}>
                    <Typography variant="body2" color="secondary">{o.title}</Typography>
                    <Typography variant="body2">{o.description}</Typography>
                </Fragment>)}
        </Box>
        {children}
    </ListItem>

    return <Fragment>
        {baseListItem}
        {!!divider && <Divider component="li" />}
    </Fragment>
}

export default BuildingListItem

BuildingListItem.defaultProps = {
    buttonProps: {},
    identifier: null,
    className: '',
    title: '',
    description: '',
    url: '',
    path: '',
    favorite: false,
    divider: true,
    ariaLabel: null
}

BuildingListItem.propTypes = {
    className: PropTypes.string,
    buttonProps: PropTypes.shape({
        button: PropTypes.bool,
        onClick: PropTypes.func
    }),
    divider: PropTypes.bool,
    image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string,
        fallbackType: PropTypes.oneOf(['standard', 'building'])
    }),
    identifier: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    url: PropTypes.string,
    path: PropTypes.string,
    favoriteAction: PropTypes.func,
    favorite: PropTypes.bool,
    ariaLabel: PropTypes.string
}