import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@material-ui/core';
import { externalLinkProps } from '../../util/util';
import { Launch } from '@material-ui/icons';

const ExternalLink = (props = {}) => {
    const { href, eventLabel, children, iconProps, ...rest } = props
    let linkProps = externalLinkProps(href, eventLabel)

    return <Link color="primary" {...linkProps} {...rest}>
        {children}
        <Launch fontSize="small" color="secondary" {...iconProps} className={`customExternalLink ${iconProps.className || ''}`.trim()} />
    </Link>
}

ExternalLink.defaultProps = {
    iconProps: {}
}

ExternalLink.propTypes = {
    iconProps: PropTypes.object,
    href: PropTypes.string,
    eventLabel: PropTypes.string
}

export default ExternalLink