import { WIFI_LOCATIONS } from "../actions-index";

export function wifiLocations(state = {}, action = {}) {
    if(action.type === WIFI_LOCATIONS) {
        return {
            ...state,
            status: 'success',
            ...action.payload
        }
    }
    return state
}