export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const FETCH_USER = 'FETCH_USER';

export const ANNOUNCEMENTS = 'ANNOUNCEMENTS';
export const ANNOUNCEMENTS_STATUS = 'ANNOUNCEMENTS_STATUS';
export const ANNOUNCEMENTS_DISMISS = 'ANNOUNCEMENTS_DISMISS';
export const HIDE_CARD = 'HIDE_CARD';
export const FEED = 'FEED';

export const CONTENT_FEED = 'CONTENT_FEED';
export const FILTER_CONTENT_FEED = 'FILTER_CONTENT_FEED';
export const CONTENT_SECTION_QUERY = 'CONTENT_SECTION_QUERY';
export const SEARCH_CONTENT_SECTION = 'SEARCH_CONTENT_SECTION';

export const ARTICLE = 'ARTICLE';
export const ARTICLE_STATUS = 'ARTICLE_STATUS';

export const NAVIGATION_LINKS = 'NAVIGATION_LINKS';

export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const SEARCH_STATUS = 'SEARCH_STATUS';
export const SEARCH_FILTERS = 'SEARCH_FILTERS'
export const SEARCH_KNOWLEDGBASE_RESULTS = 'SEARCH_KNOWLEDGEBASE_RESULTS'

export const SET_KNOWLEDGBASE_PARAMETERS = 'SET_KNOWLEDGBASE_PARAMETERS'
export const SEARCH_HR_KNOWLEDGBASE_RESULTS = 'SEARCH_HR_KNOWLEDGEBASE_RESULTS'
export const SEARCH_BUILDINGS = 'SEARCH_BUILDINGS';
export const GLOBAL_SEARCH_HELP_LINKS = 'GLOBAL_SEARCH_HELP_LINKS';
export const SEARCH_PEOPLE = 'SEARCH_PEOPLE';
export const SEARCH_GOOGLE = 'SEARCH_GOOGLE';
export const SEARCH_MARCOM = 'SEARCH_MARCOM';
export const SEARCH_COLLIBRA = 'SEARCH_COLLIBRA';

export const HR_DATA_STATUS = 'HR_DATA_STATUS';
// export const SERVICENOW_DATA_STATUS = 'SERVICENOW_DATA_STATUS';
export const UPDATE_LEARNING_DATA = 'UPDATE_LEARNING_DATA';
export const UPDATE_SERVICENOW_DATA = 'UPDATE_SERVICENOW_DATA';
export const UPDATE_HR_SERVICENOW_DATA = 'UPDATE_HR_SERVICENOW_DATA';
export const UPDATE_LEAVE_BALANCES = 'UPDATE_LEAVE_BALANCES';
// export const UPDATE_JOB_DATA = 'UPDATE_JOB_DATA';
export const UPDATE_CURRENT_TIMESHEET = 'UPDATE_CURRENT_TIMESHEET';
export const UPDATE_CURRENT_PAY = 'UPDATE_CURRENT_PAY';

export const PREFERENCES = 'PREFERENCES'
export const DARK_MODE_PREFERENCE = 'DARK_MODE_PREFERENCE'
export const LINKS_PREFERENCE = 'LINKS_PREFERENCE'

export const HELPFUL_LINKS = 'HELPFUL_LINKS';
export const SEARCH_HELPFUL_LINKS = 'SEARCH_HELPFUL_LINKS';
export const HELPFUL_LINKS_QUERY = 'HELPFUL_LINKS_QUERY';
export const TOGGLE_DEPARTMENT = 'TOGGLE_DEPARTMENT';

export const PARKING = 'PARKING';
export const PARKING_STATUS = 'PARKING_STATUS';
export const PARKING_LOCATIONS = 'PARKING_LOCATIONS';

export const BUS_STOP_STATUS = 'BUS_STOP_STATUS';
export const BUS_STOPS = 'BUS_STOPS';
export const BUS_ROUTES = 'BUS_ROUTES';
export const VEHICLE_STOPS = 'VEHICLE_STOPS';
export const VEHICLE_STOPS_STATUS = 'VEHICLE_STOPS_STATUS';
export const UPDATE_BUSES_DRAWER_OPEN = 'UPDATE_BUSES_DRAWER_OPEN';

export const FEEDBACK_CONFIRMATION = 'FEEDBACK_CONFIRMATION';

export const DINING_LOCATIONS = 'DINING_LOCATIONS';
export const DINING_LOCATION_STATUS = 'DINING_LOCATION_STATUS';
export const SEARCH_DINING_LOCATIONS = 'SEARCH_DINING_LOCATIONS'
export const DINING_MENU = 'DINING_MENU';
export const DINING_FILTERS = 'DINING_FILTERS';
export const TOGGLE_DINING_FILTER = 'TOGGLE_DINING_FILTER';
export const UPDATE_ACTIVE_MENU = 'UPDATE_ACTIVE_MENU'
export const FOOD_TRUCKS = 'FOOD_TRUCKS'
export const SEARCH_FOOD_TRUCKS = 'SEARCH_FOOD_TRUCKS'
export const EMERGENCY_ALERT = 'EMERGENCY_ALERT'
export const WIFI_LOCATIONS = 'WIFI_LOCATIONS'
export const POST_TEMPERATURE = 'POST_TEMPERATURE'
export const TEMPERATURE_SUBMISSIONS = 'TEMPERATURE_SUBMISSIONS'
export const TEMPERATURE_STATUS = 'TEMPERATURE_STATUS'
export const TEMPERATURE_INFO = 'TEMPERATURE_INFO'
export const TEMPERATURE_FORM_QUESTIONS = 'TEMPERATURE_FORM_QUESTIONS'

export const MOBILE_GO_LINKS = 'MOBILE_GO_LINKS'

/* Export each action file below */
export * from './Authentication/actions';
export * from './Announcements/actions'
export * from './ContentFeed/actions'
export * from './Navbar/actions'
export * from './Search/actions'
export * from './Sidebar/actions'
export * from './HelpfulLinks/actions'
export * from './Parking/actions';
export * from './Buses/actions';
export * from './Feedback/actions';
export * from './Article/actions';
export * from './Food/actions';
export * from './EmergencyAlert/actions';
export * from './WifiLocations/actions';
export * from './TemperatureTracker/actions';
export * from './MobileTabs/actions';
