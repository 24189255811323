import { ARTICLE, ARTICLE_STATUS } from "../actions-index"

export function article(state = {}, action = {}) {
    const { type = '', payload = {} } = action
    if (type === ARTICLE_STATUS) {
        return {
            ...state,
            status: payload
        }
    } else if (type === ARTICLE) {
        return {
            data: payload,
            status: 'success'
        }
    }
    return state
}