import {
    GLOBAL_SEARCH, SEARCH_STATUS, SEARCH_FILTERS, SEARCH_KNOWLEDGBASE_RESULTS, SEARCH_HR_KNOWLEDGBASE_RESULTS, SEARCH_BUILDINGS, SEARCH_PEOPLE, GLOBAL_SEARCH_HELP_LINKS, SEARCH_GOOGLE, SEARCH_MARCOM, SEARCH_COLLIBRA
} from '../actions-index';
import { generateAction } from '../redux-helpers';
import { API } from 'aws-amplify';
import { KNOWLEDGEBASE_KEY, HR_KNOWLEDGEBASE_KEY, BUILDINGS_KEY, FIND_PEOPLE_KEY, HELPFUL_LINKS_KEY, GOOGLE_SEARCH_KEY, GOOGLE_SEARCH_LIMIT, MARCOM_KEYS, COLLIBRA_KEYS } from '../util/constants';
import { convertBuildingResults, transformContact, searchGlobalHelpfulLinks, formatGoogleSearch, formatPaginatedSearchResponse, searchPaginatedContent } from './transform';
import { getLabel, searchTypes } from './types';
import { fetchHelpfulLinks } from '../HelpfulLinks/actions';
import { createEvent } from '../util/analytics';
import { executePaginatedSearch } from '../util/search';
import { difference } from 'lodash'

const enableGoogleSearch = process.env.REACT_APP_ENABLE_GOOGLE_SEARCH === "true"

export function searchSite(query = '') {
    return (dispatch) => {
        dispatch(generateAction(GLOBAL_SEARCH, query))
    }
}

export function executeSearch(searchParameters) {
    const values = Object.assign({
        query: '',
        byTypes: [],
        updating: false
    }, searchParameters)

    const { byTypes = [] } = values
    const query = !!values.query ? values.query.trim() : ''
    if (!query) {
        return dispatch => {
            dispatch(generateAction(GLOBAL_SEARCH, ''))
        }
    }
    
    return async (dispatch, getState) => {
        const existingState = getState() || {}
        const { helpfulLinks = {}, globalSearch = { helpfulLinks: {} } } = existingState
        const dispatchResponse = (type, response) => dispatch(generateAction(type, response))
        const allowedTypes = Array.isArray(byTypes) && byTypes.length > 0 ? byTypes : searchTypes.map(o => o.type)

        let searchOptions = {
            [KNOWLEDGEBASE_KEY]: {
                reducer: SEARCH_KNOWLEDGBASE_RESULTS,
                promise: ({ query, from, to, updating }) => searchKnowledgebase({ query, from, to, updating })
            },
            [HR_KNOWLEDGEBASE_KEY]: {
                reducer: SEARCH_HR_KNOWLEDGBASE_RESULTS,
                promise: ({ query, from, to, updating }) => searchHrKnowledgebase({ query, from, to, updating })
            },
            [BUILDINGS_KEY]: {
                reducer: SEARCH_BUILDINGS,
                promise: ({ query }) => searchBuildings(query)
            }, 
            [FIND_PEOPLE_KEY]: {
                reducer: SEARCH_PEOPLE,
                promise: ({ query }) => searchPeople(query)
            }, 
            [GOOGLE_SEARCH_KEY]: {
                reducer: SEARCH_GOOGLE,
                promise: ({ query, from }) => searchGoogle(query, from)
            },
            [HELPFUL_LINKS_KEY]: {
                reducer: GLOBAL_SEARCH_HELP_LINKS,
                promise: ({ query }) => searchHelpfulLinks(query, globalSearch, helpfulLinks)
            }
        }
        
        const collibraExclusions = difference(COLLIBRA_KEYS, allowedTypes)
        const marcomExclusions = difference(MARCOM_KEYS, allowedTypes)

        if(collibraExclusions.length !== COLLIBRA_KEYS.length){
            searchOptions[COLLIBRA_KEYS[0]] = {
                reducer: SEARCH_COLLIBRA,
                promise: (values) => searchCollibra({ ...values, exclusions: collibraExclusions.join(",")  })
            }
        }
        if(marcomExclusions.length !== MARCOM_KEYS.length){
            searchOptions[MARCOM_KEYS[0]] = {
                reducer: SEARCH_MARCOM,
                promise: (values) => searchAdminResourceCenter({ ...values, exclusions: marcomExclusions.join(",")  })
            }
        }

        dispatch(generateAction(GLOBAL_SEARCH, query))
        dispatch(generateAction(SEARCH_STATUS, 'loading'))
        allowedTypes.map(type => {
            const { reducer } = searchOptions[type] || {}
            !!reducer && dispatch(generateAction(reducer, { type, status: 'loading' }))
            return type
        })

        try {
            const allowCall = (key = '') => {
                if(COLLIBRA_KEYS.includes(key) || MARCOM_KEYS.includes(key)) {
                    return true
                }
                return query.length > 1 && Array.isArray(allowedTypes) && allowedTypes.includes(key)
            }

            for (const searchType in searchOptions) {
                const currentSearch = searchOptions[searchType] || {}
                const { reducer, promise } = currentSearch || {}
                let action
                if (allowCall(searchType)) {
                    try {
                        action = await promise(values)
                    } catch (error) {
                        console.log(`Error dispatching ${reducer}`, error)
                        dispatchResponse(reducer, { status: 'error' })
                    }
                    dispatchResponse(reducer, action || { status: 'unavailable' })
                }
            }
        } catch (error) {
            dispatch(generateAction(SEARCH_STATUS, 'error'))
            console.log(error)
        } finally {
            let types = byTypes && byTypes.length > 0 ? byTypes.join(', ') : '' 
            createEvent({ category: 'Search', action: `Searched ${types || 'Globally'}`, label: `Query: ${query}` })
            dispatch(generateAction(SEARCH_STATUS, 'complete'))
        }
    }
}

async function searchHelpfulLinks(query, globalSearch = {}, helpfulLinks = {}) {
    let response
    if(!!globalSearch.helpfulLinks && Array.isArray(globalSearch.helpfulLinks._results) && globalSearch.helpfulLinks._results.length > 0) {
        return searchGlobalHelpfulLinks(globalSearch.helpfulLinks._results, query, false)
    } else if(Array.isArray(helpfulLinks.response) && helpfulLinks.response.length > 0) {
        return searchGlobalHelpfulLinks(helpfulLinks.response || [], query)
    }
    
    try {
        response = await fetchHelpfulLinks()
    } catch (error) {
        return { status: 'error' }
    }

    return searchGlobalHelpfulLinks(response, query)
}

async function searchGoogle(query, startIndex) {
    const allowGoogleSearch = enableGoogleSearch && (!startIndex || startIndex < GOOGLE_SEARCH_LIMIT)
    if(!allowGoogleSearch) {
        return null
    }
    let indexStr = !!startIndex ? `&start=${startIndex}` : ''

    const response = await API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME + '-public', `/google/search?q=${query}${indexStr}`);
    let result = {}
    if (!!response) {
        const { queries = {}, items = [] } = response
        result = {
            updating: !!startIndex,
            ...formatGoogleSearch({
                type: GOOGLE_SEARCH_KEY,
                data: items,
                queries
            })
        }
    }
    return result
}

async function searchBuildings(query = '') {
    const response = await fetch(process.env.REACT_APP_CONTENT_V2 + '/api/buildings')
    const images = await fetch(process.env.REACT_APP_CONTENT_V2 + '/application/assets')
    let payload = { _results: [], results: [] }
    if (!!response) {
        const result = await response.json()
        const imageResponse = await images.json()

        if (!!result && result.status === 'success' && !!result.data && Array.isArray(result.data.buildings)) {
            const convertedValues = convertBuildingResults(result.data.buildings, query, imageResponse)
            payload = {
                ...payload,
                _results: result.data.buildings,
                total: convertedValues.results.length,
                ...convertedValues
            }
        }
    }
    return payload
}

async function searchPeople(query = '') {
    const response = await fetch(process.env.REACT_APP_CONTENT_V2 + `/people/search?q=${query}`)
    let payload = { _results: [], results: [], type: FIND_PEOPLE_KEY, label: getLabel(FIND_PEOPLE_KEY) }
    if (!!response) {
        const result = await response.json()
        const customValidationForFindPeople = !!result.data 
            && Array.isArray(result.data.people)
            && result.data.people[0] 
            && (result.data.people[0].hasOwnProperty('firstName') || result.data.people[0].hasOwnProperty('email'))
            
        if (!!result && result.status === 'success' && customValidationForFindPeople) {
            const { people, totalItems } = result.data
            payload.total = totalItems
            payload._results = people
            payload.results = people.map(o => transformContact(o))
        }
    }
    return payload
}

async function searchKnowledgebase(options = {}) {
    try {
        const result = searchPaginatedContent({
            ...options,
            publicEndpoint: true,
            key: KNOWLEDGEBASE_KEY,
            path: '/knowledgebase'
        })
        
        return result
    } catch (error) {
        return true
    }
}

async function searchHrKnowledgebase(options = {}) {
    try {
        const result = searchPaginatedContent({
            ...options,
            authenticated: true,
            key: HR_KNOWLEDGEBASE_KEY,
            path: '/hr-knowledgebase'
        })
        
        return result

    } catch (error) {
        return true
    }
}

async function searchCollibra({ query, from, to, updating = false, exclusions }) {
    try {
        const response = await executePaginatedSearch(query, '/collibra',
            {
                from,
                to,
                authenticated: true,
                exclude: exclusions
            }
        )
        let results = {}
        if (response.data) {
            for(const searchKey in response.data) {
                const formattedResponse = formatPaginatedSearchResponse(updating, searchKey, response.data[searchKey])
                results[searchKey] = formattedResponse
            }
        }
        return results

    } catch (error) {
        return true
    }
}

async function searchAdminResourceCenter({ query, from, to, updating = false, exclusions }) {

    try {
        const response = await executePaginatedSearch(query, '/marcom',
            {
                from,
                to,
                publicEndpoint: true,
                exclude: exclusions
            }
        )
        let results = {}
        if (response.data) {
            for(const searchKey in response.data) {
                const formattedResponse = formatPaginatedSearchResponse(updating, searchKey, response.data[searchKey])
                results[searchKey] = formattedResponse
            }
        }
        return results
    } catch (error) {
        return true
    }
}

export function updateFilterTypes(type = '') {
    return {
        type: SEARCH_FILTERS,
        payload: type
    }
}