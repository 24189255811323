import React from "react"
import { useMediaQuery } from "@material-ui/core"
import theme from "../../App/styles/theme"
import { ACCESSIBLE_REFLOW_HEIGHT } from "../../util/constants"
import { useState, useMemo } from "react"
import usePrevious from "../../hooks/usePrevious"

const withMobile = (props = {}) => WrappedComponent => {
    const reachedHeightThreshold = useMediaQuery(`@media (max-height: ${ACCESSIBLE_REFLOW_HEIGHT})`)
    const [forceReflow, setForceReflow]  = useState(reachedHeightThreshold)
    const portrait = useMediaQuery(`@media screen and (orientation:portrait)`)
    const previousPortrait = usePrevious(portrait);

    useMemo(() => {
        setForceReflow((current) => {
            if(!!portrait && (previousPortrait === portrait) && navigator.userAgent.match(/Android/i)) {
                return current
            }
            return reachedHeightThreshold
        })
    }, [reachedHeightThreshold])

    const mobile = useMediaQuery(theme().breakpoints.down('sm'))

    return <WrappedComponent {...props} mobile={mobile} forceReflow={forceReflow} />
}

export default withMobile