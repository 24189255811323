import React, { Fragment, useContext, useState, useRef } from 'react';
import { Box, Divider, Typography, Link } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons/';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { AppContext } from '../../App/context';
import { uniqueId } from 'lodash';
import { Link as RouterLink } from 'react-router-dom'
import { EXTERNAL_LINK_DESCRIPTOR } from '../../util/constants';

function ResultPreview(props = {}) {
    const { result, divider, count, show, loading } = props
    const { palette = { secondary: {}, primary: {} } } = useContext(AppContext) || {}
    const { type, label, results } = result

    let verifiedResults = Array.isArray(results) ? show ? results.slice(0, show) : results : []
    verifiedResults = verifiedResults.filter(o => !!o.title)
    if (loading) {
        return <Fragment>
            <Skeleton className="w-25" variant="text" />
            <Skeleton className="w-100 mb-1" variant="rect" />
            <Skeleton className="w-100" variant="rect" />
        </Fragment>
    }
    if (verifiedResults.length === 0) return null

    const ariaGroupId = uniqueId(`group${label ? label.replace(/ /g,"_") : ''}`)

    return <div className={divider ? undefined : `mb-1`} key={type + count + 'preview'}>
        <Typography id={ariaGroupId} color="secondary" variant="overline" component="span" className="pb-0" >
            {label}
        </Typography>
        <ul aria-labelledby={ariaGroupId} className="unstylized">
            {verifiedResults.map((res = {}, index = 0) => {
                const { url, title, preview, identifier, internal } = res

                return <Box component="li" key={`result preview${index}${type}`} className="pt-0 pb-1 d-flex">
                    <span className="d-flex flex-column">
                        {!!url ?
                            !!internal
                                ? <Link component={RouterLink} to={url} id={identifier} color="primary">{title}</Link>
                                : <Fragment>
                                    <Link id={identifier} color="primary" target="_blank" rel="noopener" href={url} aria-describedby={EXTERNAL_LINK_DESCRIPTOR} className="d-flex align-items-center">
                                        {title}
                                        <OpenInNew htmlColor={palette.secondary.main} className="ml-1" fontSize="inherit" />
                                    </Link>
                                </Fragment>
                            : <Typography variant="body2">{title}</Typography>
                        }
                        {!!preview && <Typography color="secondary" variant="body2">{preview}</Typography>}
                    </span>
                </Box>
            })}
        </ul>
        {divider && <Divider />}
    </div>
}

ResultPreview.defaultProps = {
    show: 5
}

ResultPreview.propTypes = {
    show: PropTypes.number
}

export default ResultPreview