import React, { useContext, useEffect } from 'react';
import NoComponentFound from '../../App/components/NoComponentFound';
import { AppContext } from '../../App/context';

const SignOut = ({ generateTitle, authSignOut }) => {
    const { showTemperatureTracker } = useContext(AppContext) || {}
    useEffect(() => {
        generateTitle()
    }, [generateTitle]);

    useEffect(() => {
        authSignOut()
    }, [authSignOut]);

    return <NoComponentFound
        error="Log Out"
        phrase="You've been logged out."
        secondaryPhrase="Here's some common destinations:"
        additionalPaths={showTemperatureTracker ? [{
            route: `/health-reporting`,
            label: 'Health Reporting'
        }] : []}
    />
}

export default SignOut;
