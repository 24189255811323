import { isEmpty, intersection, sortBy } from 'lodash'
import moment from 'moment'
import * as JsSearch from 'js-search';
import { encodeGoogleMapUrl } from '../util/util';

function transformDiningProps(locations = [], buildings = []) {
    const findMatchingBuilding = (campusBuildingID) => Array.isArray(buildings) ? buildings.find(o => o.buildingNumber === campusBuildingID) : {}
    let verifiedLocations = Array.isArray(locations) ? locations : []

    let list = verifiedLocations.map(o => {
        const { geo_lat_long = { lat: '', lon: '' }, address1 } = o

        let sections = []
        const buildingMatch = findMatchingBuilding(o.campusBuildingID)

        if (!isEmpty(o.diningStyle)) {
            sections.push({
                title: 'Style',
                description: o.diningStyle
            })
        }
        if (Array.isArray(o.cuisines) && o.cuisines.length > 0) {
            sections.push({
                title: 'Cuisine',
                description: o.cuisines.map(o => o.cuisineType).join(', ')
            })
        }

        const coordinates = !!geo_lat_long.lat && !!geo_lat_long.lon ? {
            coordinates: {
                latitude: geo_lat_long.lat,
                longitude: geo_lat_long.lon
            },
            url: encodeGoogleMapUrl(geo_lat_long.lat, geo_lat_long.lon)
        } : {}
        const menu = o?.locationMenu?.length > 0 ? getMenu(o.locationMenu) : {}

        return {
            summary: o.summary,
            identifier: `${o.id}`,
            buildingName: !isEmpty(buildingMatch) ? buildingMatch.name : '',
            title: o.locationName,
            address: address1,
            ...coordinates,
            menus: menu.sections,
            diningStyle: o.diningStyle,
            cuisines: Array.isArray(o.cuisines) ? o.cuisines.map(b => b.cuisineType) : [],
            thumbnail: {
                src: o.thumbnailUrl,
                alt: `${o.locationName} Thumbnail Image`
            },
            image: {
                src: o.photoUrl,
                alt: `${o.locationName} Full Image`
            }
        }
    })

    return {
        _list: list,
        list
    }
}

function transformFoodTrucks(trucks = []) {
    let validatedTrucks = Array.isArray(trucks) ? trucks : []

    let list = validatedTrucks.map((o, index) => {
        const coordinates = !!o.latitude && !!o.longitude ? {
            coordinates: {
                latitude: o.latitude,
                longitude: o.longitude
            },
            url: encodeGoogleMapUrl(o.latitude, o.longitude)
        } : {}

        return {
            identifier: o.itemHash,
            buildingName: o.locationName,
            title: o.name,
            cuisines: Array.isArray(o.cuisine) ? o.cuisine : [],
            ...coordinates,
            startTime: o.startTime,
            endTime: o.endTime,
            openHours: `${moment(o.startTime).calendar()} to ${moment(o.endTime).format('LT')}`,
            thumbnail: {
                src: o.profilePhoto,
                alt: `${o.name} Thumbnail Image`
            },
            address: o.address1,
            image: {
                src: o.fullPhoto,
                alt: `${o.name} Full Image`
            },
            menuURL: o.menuURL
        }
    })

    return {
        _list: sortBy(list, 'startTime'),
        list: sortBy(list, 'startTime')
    }
}

function getMenu(menus = []) {
    if (!Array.isArray(menus) || menus.length === 0) return null
    if (menus.length > 1) {
        const notDaySpecific = menus.find(o => !o.isDaySpecificMenu)
        const sameDay = menus.find(o => !!o.isDaySpecificMenu && moment().isSame(moment(o.menuDate), 'day'))

        if (!isEmpty(sameDay)) return sameDay
        if (!isEmpty(notDaySpecific)) return notDaySpecific
    }

    return menus[0]
}

function transformDiningFilters(filters = []) {
    if (!Array.isArray(filters) || filters.length === 0) {
        return {
            list: [],
            _list: []
        }
    }
    const validateType = (str = '') => str === 'Preference' ? 'Requirement' : str

    const formattedFilters = filters.map((o) => ({
        id: validateType(o.type) + "#" + o.name,
        title: o.name,
        icon: o.iconUrl,
        type: validateType(o.type),
        active: false
    }))

    return {
        list: formattedFilters,
        _list: filters
    }
}

function searchAcrossMenus(query = '', activeMenu = {}) {
    if (isEmpty(activeMenu) || !Array.isArray(activeMenu._menus) || activeMenu._menus.length === 0) return activeMenu
    const { _menus = [], locationId } = activeMenu
    const baseObj = {
        locationId,
        menus: _menus,
        _menus
    }
    if (!query) return baseObj

    let searchedMenus = []
    _menus.forEach(menu => {
        let newSearch = new JsSearch.Search('identifier')
        newSearch.addIndex('name')
        newSearch.addIndex('course')
        newSearch.addDocuments(menu.menu)

        searchedMenus.push({
            ...menu,
            menu: newSearch.search(query)
        })
    })

    return {
        ...baseObj,
        menus: searchedMenus
    }
}

function filterMenus({ id = '', filters, menus }) {
    const list = Array.isArray(filters) ? filters : []
    const parsedId = id.split("#")
    let filterType = parsedId[0]
    const filterName = parsedId[1]
    const updatedMenus = []
    let activeExclusionFilters = []
    let activeInclusionFilters = []
    
    const newFilters = list.map(o => {
        const active = o.title === filterName && o.type === filterType ? !o.active : o.active
        if (active) {
            if (o.type === "Allergen") activeExclusionFilters.push(o.title.toLowerCase())
            else activeInclusionFilters.push(o.title.toLowerCase())
        }
        return {
            ...o,
            active
        }
    })

    menus.forEach(o => {
        let newFilteredMenu = Array.isArray(o.menu) ? o.menu.filter((item = {}) => {
            const { traits = [] } = item
            let exclusionTraits = []
            let inclusionTraits = []
            traits.forEach(trait => {
                if (trait.category === 'Allergen') exclusionTraits.push(trait.name.toLowerCase())
                else inclusionTraits.push(trait.name.toLowerCase())
            })

            const excludeItem = intersection(exclusionTraits, activeExclusionFilters).length > 0
            if (excludeItem) return false
            if (activeInclusionFilters.length > 0) return intersection(inclusionTraits, activeInclusionFilters).length > 0
            return true
        }) : []

        updatedMenus.push({
            ...o,
            menu: newFilteredMenu
        })
    })

    return {
        filterList: newFilters,
        menus: updatedMenus
    }
}

function searchFoodList(foodList = [], query = '') {
    let searchResults = Array.isArray(foodList) ? foodList : []
    let newSearch = new JsSearch.Search('identifier')
    newSearch.addIndex('buildingName')
    newSearch.addIndex('title')
    newSearch.addIndex('cuisines')
    newSearch.addIndex('address')
    newSearch.addDocuments(searchResults)

    return {
        list: !!query ? newSearch.search(query) : searchResults,
        query
    }
}

export { transformDiningProps, transformDiningFilters, searchAcrossMenus, filterMenus, transformFoodTrucks, searchFoodList }