import { API_STANDARD_LIMIT_COUNT } from "./constants";
const { Auth, API } = require("aws-amplify");

async function executePaginatedSearch(
  query = "",
  rootPath = "",
  {
    apiName = process.env.REACT_APP_DIGITAL_HOME_API_NAME,
    from = 0,
    to = API_STANDARD_LIMIT_COUNT,
    exclude = "",
    publicEndpoint = false,
    authenticated = false
  }
) {
  try {
    if (authenticated) {
      await Auth.currentSession();
    }
    let apiRequestName = publicEndpoint ? apiName += '-public' : apiName

    let size = Number(to) - Number(from);
    size = size < API_STANDARD_LIMIT_COUNT ? size : API_STANDARD_LIMIT_COUNT;

    let path = rootPath;
    path += `?content=${query || ""}`;
    path += `&from=${from}`;
    path += `&to=${to}`;
    path += `&size=${size || API_STANDARD_LIMIT_COUNT}`;
    if (exclude) {
      path += `&exclude=${exclude}`;
    }

    const response = await API.get(apiRequestName, path);
    return response;
  } catch (error) {
    throw error;
  }
}

export { executePaginatedSearch };
