import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

function LoadingCard(props = {}) {
    const { loading, rows, title } = props
    const partial = (num) => <Skeleton style={{ width: `${num}%` }} disableAnimate={!loading} variant="rect" />

    return <List dense component="div">
        {title ? title : <ListItem component="div">
            <ListItemText className="mr-2" disableTypography primary={<Skeleton height={30} disableAnimate={!loading} variant="text" />} />
            <Skeleton disableAnimate={!loading} variant="circle" width={40} height={40} />
        </ListItem>}
        {[...Array(rows).keys()].map((i) => <ListItem variant="text" component="div" key={`skeleton${i}`}>
            <ListItemText
                className="d-flex spaceBetween"
                disableTypography
                primary={partial(75)} secondary={partial(20)} />
        </ListItem>)}
    </List>

}

LoadingCard.defaultProps = {
    loading: true,
    rows: 3
}

LoadingCard.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.number,
    elevation: PropTypes.number
}

export default LoadingCard