import { encodeGoogleMapUrl } from '../util/util'

export function formatWifiLocations(list = []) {
    if(!Array.isArray(list) || list.length === 0) return []
    return list.map((location = {}) => {
        const { city, locationDetails, latitude, longitude, itemHash } = location

        return {
            id: itemHash,
            title: city,
            description: locationDetails,
            url: encodeGoogleMapUrl(latitude, longitude),
            coordinates: {
                latitude,
                longitude
            }
        }
    })
}