import { BUS_STOP_STATUS, BUS_STOPS, BUS_ROUTES, VEHICLE_STOPS_STATUS, VEHICLE_STOPS, UPDATE_BUSES_DRAWER_OPEN } from "../actions-index";
import { generateAction } from "../redux-helpers";

export function setDrawerInformation(drawerInformation = {}){
  return (dispatch) => {
    dispatch(generateAction(UPDATE_BUSES_DRAWER_OPEN, drawerInformation))
  }
}

export function getVehicleStops(vehicleId){
  const vehicleStopsStatus = (status) => generateAction(VEHICLE_STOPS_STATUS, status);
  return (dispatch) => {
    dispatch(vehicleStopsStatus('loading'));
    return fetch(`${process.env.REACT_APP_CONTENT_V2}/bus/vehicles/${vehicleId}/predictions`)
      .then(response => response.json())
      .then(body => {
        if(body){
          dispatch(generateAction(VEHICLE_STOPS, body));
        } else {
          console.warn("Empty response for getVehicleStops")
          dispatch(vehicleStopsStatus('error'));
        }
      })
      .catch( error => {
        console.error("getVehicleStops error:", error)
        dispatch(vehicleStopsStatus('error'));
      })
  }
}

export function getNearestBusStops(latitude, longitude){
  const busStopStatus = (status) => generateAction(BUS_STOP_STATUS, status);
  return (dispatch) => {
    dispatch(busStopStatus('loading'));
    return fetch(`${process.env.REACT_APP_CONTENT_V2}/bus/stops/nearby/${latitude},${longitude}`)
      .then(response => response.json())
      .then(body =>{
        if (body) {
          dispatch(generateAction(BUS_STOPS, body));
        } else {
          console.warn("Empty response for getNearestBusStops")
          dispatch(busStopStatus('error'));
        }
      })
      .catch(error => {
        console.error("getNearestBusStops error:", error)
        dispatch(busStopStatus('error'));
      })
  }
}

export function getBusRoutes(){
  const busStopStatus = (status) => generateAction(BUS_STOP_STATUS, status);
  return (dispatch) => {
    dispatch(busStopStatus('loading'));
    return fetch(`${process.env.REACT_APP_CONTENT_V2}/bus/routes`)
      .then(response => response.json())
      .then(body =>{
        if (body) {
          dispatch(generateAction(BUS_ROUTES, body));
        } else {
          console.warn("Empty response for getBusRoutes")
          dispatch(busStopStatus('error'));
        }
      })
      .catch(error => {
        console.error("getBusRoutes error:", error)
        dispatch(busStopStatus('error'));
      })
  }
}
