import React, { useRef, useEffect, useState } from 'react';
import useStyles from '../styles/theme';
import PropTypes from 'prop-types';
import SearchPreview from '../../Search/containers/SearchPreview';
import SearchBar from '../../Search/containers/Bar';
import { Box, Fade, Typography, ClickAwayListener } from '@material-ui/core';
import { changeFocusWithArrowsUpDown, getPositionValuesForFocusableElements } from '../../util/accessibility';

function Search(props = {}) {
    const { query, elevation, searchRoute } = props
    const searchRef = useRef()
    const searchPreviewRef = useRef()
    const [textInputWasFocused, textInputFocused] = useState(false)
    const [fadeOut, setFadeout] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        const element = searchRef && searchRef.current
        element && element.addEventListener('keydown', onKeyDown);

        return () => {
            element && element.removeEventListener('keydown', onKeyDown);
        };
      }, [searchRef]);

    const onKeyDown = (event) => {
        const previewBox = searchPreviewRef && searchPreviewRef.current
        
        if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
            setFadeout(false)
            event.preventDefault()
            changeFocusWithArrowsUpDown(event, previewBox)
        } else if(['Tab', 'Escape', 'Esc'].includes(event.key)) {
            if(event.key === 'Tab' && previewBox && previewBox.contains(document.activeElement)) {
                event.preventDefault()
                focusNextElement()
            }
            setFadeout(true)
            return
        } else {
            setFadeout(false)
        }

    }

    const focusNextElement = () => {
        const searchInput = document.getElementById("search-preview-input")
        const { focusableElements, currentPosition } = getPositionValuesForFocusableElements(document, searchInput)
        const nextFocusable = focusableElements[currentPosition + 1]

        if(!!nextFocusable) {
            nextFocusable.focus()
        }
    }

    return <ClickAwayListener onClickAway={() => setFadeout(true)}>
        <Box ref={searchRef} aria-label="full compass site" role="search" classes={{ root: classes.search }} className={props.className}>
            <SearchBar onFocus={() => textInputFocused(true)} inputProps={{  "aria-describedby": "search-help-text" }} id="search-preview-input" small />
            <Typography variant="srOnly" id="search-help-text">
                Search results will appear as you type, use up and down arrow to navigate
            </Typography>
            <Box ref={searchPreviewRef}>
                <Fade in={(textInputWasFocused || !!query) && !fadeOut} unmountOnExit>
                    <SearchPreview elevation={elevation} searchRoute={searchRoute} />
                </Fade>
            </Box>
        </Box>
    </ClickAwayListener>
}

Search.defaultProps = {
    query: ''
}

Search.propTypes = {
    elevation: PropTypes.number,
    searchRoute: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    })
}

export default Search


