import { ARTICLE, ARTICLE_STATUS } from "../actions-index"
import { generateAction } from "../redux-helpers"
import { API } from "aws-amplify"
import { isEmpty } from 'lodash'

export function getArticle(id) {
    return async dispatch => {
        let setStatus = (status = '') => dispatch(generateAction(ARTICLE_STATUS, status))
        setStatus('loading')
        try {
            let response = await fetchArticle(id)
            if(!isEmpty(response) && response.data && response.data.article) {
                dispatch(generateAction(ARTICLE, response.data.article))
            } else {
                throw new Error('No article found')
            }
        } catch (error) {
            setStatus('error')
        }
    }
}

async function fetchArticle(id) {
    let response = {}
    try {
        response = API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME + '-public', `/content/article/${id}`)
    } catch (error) {
        console.log('Error fetching content', response)
    }

    return response
}