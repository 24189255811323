import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { authSignOut } from "../actions";
import SignOut from "../components/SignOut";

const mapDispatchToProps = (dispatch) => {
    return {
        authSignOut: () => dispatch(authSignOut())
    };
};

export default withRouter(connect(null, mapDispatchToProps)(SignOut));
