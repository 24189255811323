import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation, useHistory } from "react-router";
import PropTypes from "prop-types";

function sendPageView(location = {}) {
    ReactGA.set({ page: location.pathname });
    
    ReactGA.pageview(location.pathname + location.search);
}

function GoogleAnalyticsListener({ children, trackingId }) {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        ReactGA.initialize(trackingId);
        sendPageView(location);

        return history.listen(sendPageView);
    }, []);

    return children;
}

GoogleAnalyticsListener.propTypes = {
    children: PropTypes.node,
    trackingId: PropTypes.string
};

export default GoogleAnalyticsListener;