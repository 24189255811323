import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    footer: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: `calc(var(--mobile-nav-height) + ${theme.spacing(3)}px)`
        }
    },
    graphicsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'flex-start',
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3)
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(1),
            textAlign: "left",
            flexDirection: 'column'
        }
    }
}));

export default useStyles