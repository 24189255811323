import React, { useContext, Fragment } from 'react';
import { BottomNavigation, BottomNavigationAction, useMediaQuery, Button } from '@material-ui/core';
import { Person, Search, Fastfood } from '@material-ui/icons/';
import useStyles from '../styles/theme';
import '../styles/index.css';
import { AppContext } from '../../App/context';
import { Link as RouterLink } from 'react-router-dom';
import CustomIcon from '../../Common/components/CustomIcon';
import theme from '../../App/styles/theme';

function MobileNavbar({ pathname = '', labelsOnly = false, className }) {
    const classes = useStyles({ tabCount: 5 });
    const context = useContext(AppContext) || {}
    const { palette = { secondary: {}, primary: {} }, darkMode = false } = context
    const showLabels = useMediaQuery(theme().breakpoints.up('sm'))

    const renderIcon = (type = '') => {
        let active = false
        if (type === 'news') active = pathname === '/'
        else if (type === 'parking') active = pathname === '/parking'
        else if (type === 'campus') active = pathname === '/campus'
        return <CustomIcon type={type} active={active} className="bottom-nav-icon"  />
    }

    const getHtmlColor = (path = '') => pathname.includes(path) ? palette.primary.main : palette.secondary.main
    const Action = (props = {}) => {
        const current = pathname === props.to ? 'page' : undefined
        const sharedProps = {
            "aria-current": current,
            role: undefined,
            component: RouterLink
        }

        if(labelsOnly) {
            return <Button color={current ? 'primary' : 'secondary'} classes={{ root: classes.labelOnly, textPrimary: classes.labelBorder }} className="text-center" {...sharedProps} to={props.to}>{props.label}</Button>
        }

        return <BottomNavigationAction
            classes={{ root: classes.bottomNavAction }}
            {...props}
            {...sharedProps}
        />
    }

    let bottomNavProps = labelsOnly ? {} : { showLabels, value: pathname, classes: { root: classes.bottomNav } }

    return <BottomNavigation tabIndex="-1" component="nav" aria-label="top level pages" id="mobile_navigation" className={`bottom-nav-wrapper ${className}`.trim()} {...bottomNavProps}>
        <Action value={'/'} to={'/'} label="News" icon={renderIcon('news')} />
        <Action value={'/about-you'} to={'/about-you'} label={'About You'} icon={<Person htmlColor={getHtmlColor('/about-you')} />} />
        <Action value={'/search'} to={'/search'} label="Search" icon={<Search htmlColor={getHtmlColor('/search')} />} />
        <Action value={'/campus'} to={'/campus'} label="Campus" icon={renderIcon('campus')} />
        <Action value={'/food'} to={'/food'} label="Food" icon={<Fastfood htmlColor={getHtmlColor('/food')} />} />
    </BottomNavigation>
}

MobileNavbar.defaultProps = {
    navIndex: 1,
    className: ""
}

export default MobileNavbar