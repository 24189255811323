import React, { Component, Fragment } from 'react';
import AppContextConsumer from '../../App/context';
import AppBar from './AppBar';
import Account from './Account';
import NavLinks from './NavLinks';
import Search from './Search';
import PropTypes from 'prop-types';
import { filterLinksByUser } from '../transform';
import { Hidden, Button, Typography, Divider, IconButton, Tooltip, Link } from '@material-ui/core';
import Drawer from '../../Drawer/components'
import { Menu, Brightness4, Feedback } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom'

class NavBar extends Component {
    constructor(props) {
        super()
        this.state = {
            searchRedirect: false,
            showDrawer: false
        }
        this.updateDrawer = this.updateDrawer.bind(this)
    }

    componentDidMount() {
        if (!Array.isArray(this.props.links) || this.props.links.length === 0) {
            this.props.getNavLinks()
        }
    }

    constructUserLinks(user = {}, links = []) {
        const filteredLinks = filterLinksByUser(user, links)

        return Array.isArray(filteredLinks) ? filteredLinks : []
    }

    updateDrawer() {
        this.setState((state) => ({
            showDrawer: !state.showDrawer
        }))
    }

    render() {
        const { props = {} } = this
        const { links = [], toggleDarkMode, hideSearch, className, id, query } = props
        const { showDrawer } = this.state
        const searchRoute = { pathname: '/search', search: `?q=${query}` }

        return <AppContextConsumer>
            {(context = {}) => {
                const { darkMode, setUser, signOut, user, elevation = {}, setNavHeight } = context
                const schemeModeLabel = darkMode ? 'Turn off dark mode' : 'Turn on dark mode'
                const renderableLinks = this.constructUserLinks(user, links)
                const internalLinks = renderableLinks.filter(o => o.internal)
                const publicLinks = renderableLinks.filter(o => !o.internal)

                return <AppBar determineHeight={setNavHeight} id={id} elevation={elevation.navigation || 0} className={className} darkMode={darkMode}>
                    <Hidden mdDown>
                        <NavLinks links={renderableLinks.filter(o => !o.mobileOnly)} />
                    </Hidden>
                    <Hidden smDown>
                        {!hideSearch && <Search className="mr-1 my-1" searchRoute={searchRoute} elevation={elevation.navigation ? elevation.navigation - 2 : 0} query={query} />}
                    </Hidden>
                    <Tooltip aria-label={'Feedback'} title="Feedback">
                        <IconButton role={undefined} to="/feedback" color="primary" component={RouterLink} {...props.location && props.location.pathname === '/feedback' ? { "aria-current": 'page'} : {}}>
                            <Feedback />
                        </IconButton>
                    </Tooltip>
                    <Hidden smDown>
                        <Tooltip aria-label={schemeModeLabel} title={schemeModeLabel}>
                            <IconButton color="primary" onClick={() => { toggleDarkMode(!darkMode) }}>
                                <Brightness4 />
                            </IconButton>
                        </Tooltip>
                    </Hidden>
                    <Account darkMode={darkMode} toggleDarkMode={toggleDarkMode} handleLogin={() => setUser(true)} handleLogout={signOut} user={user} />
                    <Hidden lgUp>
                        <Button size="medium" aria-label="menu of additional links" variant="text" color="secondary" onClick={this.updateDrawer}>
                            <Menu />
                        </Button>
                        <Drawer aria-labelledby="navigation-hamburger-more-heading" anchor="right" show={showDrawer} showDrawer={this.updateDrawer}>
                            <div className="d-flex flex-column px-2 pb-2">
                                <Typography id="navigation-hamburger-more-heading" variant="srOnly">
                                    additional navigation links
                                </Typography>
                                {internalLinks.length > 0 && <Fragment>
                                    <Typography variant="overline">Compass Links</Typography>
                                    <NavLinks className="mb-1" links={internalLinks} />
                                    <Divider className="my-1" component="span" />
                                </Fragment>}
                                {publicLinks.length > 0 && <Fragment>
                                    <Typography variant="overline">External Links</Typography>
                                    <NavLinks className="mb-1" links={publicLinks} />
                                </Fragment>}
                                <Hidden mdUp>
                                    <Divider className="my-1" component="span" />
                                    <Typography variant="overline">Preferences</Typography>
                                    <Link className="d-flex align-items-center text-left" variant="body2" style={{ fontWeight: '500' }} color="secondary" component="button" onClick={() => { toggleDarkMode(!darkMode) }}>
                                        <Brightness4 className="mr-1" />
                                        {schemeModeLabel}
                                    </Link>
                                </Hidden>
                            </div>
                        </Drawer>
                    </Hidden>
                </AppBar>
            }}
        </AppContextConsumer>
    }
}

NavBar.defaultProps = {
    className: '',
    hideSearch: false,
    getNavLinks: () => { }
}

NavBar.propTypes = {
    className: PropTypes.string,
    toggleDarkMode: PropTypes.func,
    getNavLinks: PropTypes.func,
    links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                pathname: PropTypes.string.isRequired,
                search: PropTypes.string,
                hash: PropTypes.string,
                state: PropTypes.object
            })
        ]).isRequired,
        internal: PropTypes.bool,
        affiliation: PropTypes.array
    }))
}

export default NavBar