import React, { Fragment } from 'react'
import BreadcrumbTrail from './BreadcrumbTrail'
import PropTypes from 'prop-types'

const withBreadcrumbTrail = (WrappedComponent) => {
    function WithBreadcrumbTrail(props = {}) {
        const [pageTitle, setPageTitle] = React.useState('')
        const [routes, setRoutes] = React.useState([])
        
        const setCurrentRoutes = React.useCallback((currentRoutes) => {
            setRoutes(currentRoutes)
        }, [])
        
        const setCurrentPageTitle = React.useCallback((currentPageTitle) => {
            setPageTitle(currentPageTitle)
        }, [])
        
        return <Fragment>
            <BreadcrumbTrail routes={routes} pageTitle={pageTitle} />
            <WrappedComponent {...props} setCurrentPageTitle={setCurrentPageTitle} setCurrentRoutes={setCurrentRoutes} />
        </Fragment>
    }

    const componentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithBreadcrumbTrail.displayName = `WithBreadcrumbTrail(${componentName})`
    WrappedComponent.propTypes = {
        setCurrentPageTitle: PropTypes.func,
        setCurrentRoutes: PropTypes.func
    }

    return WithBreadcrumbTrail
}

export default withBreadcrumbTrail