import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { searchSite, executeSearch } from '../../actions-index';
import Bar from '../components/Bar';

const mapStateToProps = (state, ownProps) => {
    const { globalSearch = {} } = state
    const { query = '', filters = [] } = globalSearch

    return {
        query,
        filterTypes: filters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchQuery: (query) => dispatch(searchSite(query)),
        executeSearch: ({ query, from, to, byTypes }) => dispatch(executeSearch({ query, from, to, byTypes }))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bar));