import {
    NAVIGATION_LINKS
} from '../actions-index';
import { transformLinks } from './transform';
import { generateAction } from '../redux-helpers';

export function getNavLinks() {
    return async dispatch => {
        const dispatchResponse = (type, response) => dispatch(generateAction(type, response))
        let navLinks
        try {
            navLinks = await fetchNavLinks()
        } catch (error) {
            console.log('Error fetching nav links', error)            
        }
        !!navLinks && dispatchResponse(NAVIGATION_LINKS, navLinks)
    }
}

async function fetchNavLinks() {
    let payload = {}
    const response = await fetch(process.env.REACT_APP_JSON_DOC_LINKS + `/navigationLinks.json`)
    if (!!response) {
        const result = await response.json()
        payload = transformLinks(result)
    }

    return payload
}