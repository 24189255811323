import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Results from "../components/Results";
import { executeSearch, getHelpfulLinks } from "../../actions-index";
import { omit, isEmpty } from 'lodash';
import { filterResults } from '../transform'
import { searchTypes } from "../types";

const mapStateToProps = (state = {}, ownProps = {}) => {
    const { match = {}, show } = ownProps
    const { params = {} } = match
    const { globalSearch = {}, helpfulLinks = {} } = state
    const { _results = [] } = helpfulLinks
    const { query = '', filters = [], status = '' } = globalSearch
    const individual = searchTypes.map(o => o.type).includes(params.type)
    const preparedResults = !isEmpty(globalSearch) ? filterResults(omit(globalSearch || {}, ['query', 'status'])) : []

    return {
        individual,
        query,
        filters,
        show: show ||  (individual ? 10 : 5),
        loading: !status || status === 'loading',
        callHelpfulLinks: !Array.isArray(_results) && _results.length === 0,
        results: preparedResults
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        executeSearch: (values) => dispatch(executeSearch(values)),
        getHelpfulLinks: () => dispatch(getHelpfulLinks())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));