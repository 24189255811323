import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { PROJECT_NAME } from '../../util/constants';
import PropTypes from 'prop-types';
import { Avatar, Button, CircularProgress, Tooltip } from '@material-ui/core';
import useStyles from '../styles/theme';

export default function Account({ user, handleLogin, handleLogout, darkMode, className }) {
    const { status = '', initials = '' } = user
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const userDropdownLabel = "Profile"
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleLoginClick() {
        handleLogin()
        handleClose()
    }

    function handleLogOutClick() {
        handleLogout()
        handleClose()
    }

    let ariaExpanded = Boolean(anchorEl) ? { 'aria-expanded': 'true' } : {}
 
    const dropdown = <Fragment>
        <Tooltip key="dropdown-button" aria-label={userDropdownLabel} title={userDropdownLabel}>
            <IconButton {...ariaExpanded} className={className} aria-label={userDropdownLabel} color="primary" aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar className={classes[`${darkMode ? 'dark' : 'reg'}ModeAvatar`]}>{initials}</Avatar>
            </IconButton>
        </Tooltip>
        <Menu
            key="dropdown-menu"
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <li role="none">
                <MenuItem component="button" role="menuitem" onClick={handleLogOutClick}>Log Out</MenuItem>
            </li>
        </Menu>
    </Fragment>

    if (status === 'success') {
        return dropdown
    } else if (status === 'loading') {
        return <CircularProgress data-testid="loading" className={`${classes.loading}${className ? ` ${className}` : ''}`} color="secondary" />
    }

    return <Button className={className} color="primary" aria-label={`Log in to ${PROJECT_NAME}`} onClick={handleLoginClick}>Log In</Button>

}

Account.defaultProps = {
    user: {
        status: ''
    },
    className: ''
}

Account.propTypes = {
    user: PropTypes.shape({
        status: PropTypes.oneOf(['', 'error', 'success', 'loading']),
        applicationRoles: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        osuid: PropTypes.string
    }),
    className: PropTypes.string,
    handleLogin: PropTypes.func,
    handleLogout: PropTypes.func,
    darkMode: PropTypes.bool,
    toggleDarkMode: PropTypes.func
}
