import React, { useContext, Fragment } from 'react'
import { Paper, Container, Typography, Link, Box, IconButton, Tooltip, Divider } from '@material-ui/core'
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppContext } from '../../App/context'
import useStyles from '../styles/theme'
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import '../styles/index.scss'
import { EXTERNAL_LINK_DESCRIPTOR } from '../../util/constants'

const mark = require('../../Common/assets/osu-web-header-horiz.png')
const markWhite = require('../../Common/assets/osu-web-header-horiz-rev.png')

function Footer(props = {}) {
    const context = useContext(AppContext) || {}
    const { darkMode, elevation = {}, showTemperatureTracker } = context
    const classes = useStyles()
    const location = useLocation() || {}
    const { className } = props
    const ExternalLink = (props = {}) => <Link aria-describedby={EXTERNAL_LINK_DESCRIPTOR} target="_blank" rel="noopener" {...props}></Link>
    const icons = [
        {
            icon: <TwitterIcon />,
            label: 'Twitter',
            href: "https://twitter.com/TechOhioState"
        }, {
            icon: <YouTubeIcon />,
            label: 'YouTube',
            href: "https://www.youtube.com/user/TechOhioState"
        }, {
            icon: <InstagramIcon />,
            label: 'Instagram',
            href: "https://www.instagram.com/techohiostate/"
        }
    ]
    const ariaProps = (page = '') => location.pathname && location.pathname === page ? { "aria-current": 'page'} : {}

    return <Paper tabIndex="-1" {...props} elevation={elevation.high} component="footer" className={`${className} ${classes.footer} footer${darkMode ? '-dark' : ''}`.trim()} id="footer">
        <Container>
            <Box classes={{ root: classes.graphicsWrapper }}>
                <ExternalLink aria-label="The Ohio State University home page" href="https://www.osu.edu/">
                    <img alt="" className={'osu-wordmark'} src={darkMode ? markWhite : mark} />
                </ExternalLink>
                <Box>
                    {icons.map((o, index) => <Tooltip key={`icon-btn-link${index}`} aria-label={o.label} title={o.label}>
                        <IconButton role={undefined} aria-describedby={EXTERNAL_LINK_DESCRIPTOR} component={Link} target="_blank" rel="noopener" href={o.href} className={icons.length - 1 !== index ? 'mr-1' : ''} color="secondary">
                            {o.icon}
                        </IconButton>
                    </Tooltip>)}
                </Box>
            </Box>
            {showTemperatureTracker && <Typography variant="body2">
                Complete a daily health check before returning to campus via the <Link {...ariaProps('/health-reporting')} to="/health-reporting" component={RouterLink}> health reporting page</Link>, the Ohio State app, or call <Link href="tel:6142477000">614-247-7000</Link> to record your health information by phone.
            </Typography>}
            <Typography variant="body2" className="mt-2">
                How do you like Compass? <Link {...ariaProps('/feedback')} to="/feedback" component={RouterLink}>Submit feedback</Link> with issues, ideas, or comments!
            </Typography>
            <Typography variant="body2" className="mt-2">© {new Date().getFullYear()} - Office of Technology and Digital Innovation</Typography>
            <Typography variant="body2">Mount Hall | 1050 Carmack Road | Columbus, Ohio 43210</Typography>
            <Typography variant="body2">
                <span className="mr-1">
                    Contact: <ExternalLink href="https://it.osu.edu/help">IT Help and Support</ExternalLink>
                </span>
                |
                <span className="mx-1">
                    Phone: <Link href="tel:6146884357">614-688-4357</Link>
                </span>
                |
                <span className="ml-1">
                    Text Telephone for the Deaf: 614-688-8743
                </span>
            </Typography>
            <Typography variant="body2" className="mt-2">
                If you have a disability and experience difficulty accessing this content, please call the Accessibility Helpline at <Link href="tel:+16142925000">614-292-5000</Link>.
            </Typography>
        </Container>
    </Paper>
}

export default Footer