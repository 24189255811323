import { combineReducers } from 'redux';
import { authentication } from './Authentication/reducers';
import { announcements } from './Announcements/reducers';
import { content } from './ContentFeed/reducers';
import { navigation } from './Navbar/reducers';
import { globalSearch } from './Search/reducers';
import { profileData } from './Sidebar/reducers';
import { parking } from './Parking/reducers';
import { buses } from './Buses/reducers';
import { helpfulLinks } from './HelpfulLinks/reducers';
import { feedback } from './Feedback/reducers';
import { article } from './Article/reducers';
import { food } from './Food/reducers';
import { emergencyAlert } from './EmergencyAlert/reducers';
import { wifiLocations } from './WifiLocations/reducers';
import { temperatureTracker } from './TemperatureTracker/reducers';
import { mobileGoLinks } from './MobileTabs/reducers';

export default combineReducers({
    authentication,
    announcements,
    content,
    navigation,
    globalSearch,
    profileData,
    parking,
    buses,
    helpfulLinks,
    feedback,
    article,
    food,
    emergencyAlert,
    wifiLocations,
    temperatureTracker,
    mobileGoLinks
})