import React, { useState, Fragment } from 'react';
import { Divider, List as MaterialList, ListItem, ListItemText, Link, Typography, Button } from '@material-ui/core';
import useStyles from '../styles/theme';
import PropTypes from 'prop-types';
import { Launch, ArrowRight, ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { OutboundLink, createEvent } from '../../util/analytics';
import { EXTERNAL_LINK_DESCRIPTOR } from '../../util/constants';

function List(props = {}) {
    const [hidden, setHidden] = useState(true)
    const classes = useStyles();
    const { content, className, offsetDivider, identifier, ariaTitleId, newWindowId } = props
    if (!Array.isArray(content)) return null
    let preparedContent = content.length > 3 ? content.map((o, index) => index > 2 ? { ...o, private: true } : o) : content
    const externalLinkValues = (url, label) => ({
        target: "_blank",
        rel: "noopener",
        role: undefined,
        to: url,
        eventLabel: 'Sidebar: ' + label,
        component: OutboundLink
    })

    const linkProps = ({ idPrefix, url = null, left = '', right = '' }) => {
        if (url) {
            return {
                ...externalLinkValues(url, `${identifier} - ${left}`),
                button: true,
                ...idPrefix ? {'aria-labelledby': `${!!left && idPrefix + '-left'} ${!!right && idPrefix + '-right'}`.trim()} : {},
                ...newWindowId ? { 'aria-describedby': newWindowId } : {}
            }
        }
        return {
            component: 'div'
        }
    }

    const containsPrivateInfo = preparedContent.filter(o => !!o.private).length > 0
    const containsHeadings = preparedContent.filter(o => !!o.subheading).length > 0

    const togglePrivateInfo = () => {
        setHidden(!hidden)
        
        createEvent({ category: 'Sidebar', action: 'Clicked show button', label: `Setting ${identifier} to ${hidden}`})
    }
    
    return <MaterialList className={className} dense component="div">
        {preparedContent
            .filter(o => !(o.private && hidden) && (!!o.subheading || !!o.left))
            .map((item, index) => {
                const idPrefix = identifier ? `${identifier}-${index}` : null
                if (!!item.subheading) {
                    return <ListItem data-testid="card-subheading" key={`list-subheading[${identifier}][${index}]`} className="py-0" component="div">
                        <Typography component="h4" variant="overline" color="secondary">{item.subheading}</Typography>
                    </ListItem>
                } return <Fragment key={`list-content[${identifier}][${index}]`} >
                    <ListItem data-testid={`card-text-wrapper`} {...linkProps({ idPrefix, url: item.url, left: item.left, right: item.right })} >
                        <ListItemText disableTypography data-testid="card-text" className={`${classes.listItem}${containsHeadings ? ' pl-1' : ''}`}
                            primary={item.left && <Typography id={idPrefix && `${idPrefix}-left`} classes={!!item.right ? { root: classes.left } : {}} variant="body2">
                                <span className="mr-1" >
                                    {item.left}
                                </span>
                                {!!item.url && <Launch style={{ verticalAlign: 'middle' }} fontSize="inherit" />}
                            </Typography>}
                            secondary={item.right && <Typography id={idPrefix && `${idPrefix}-right`} classes={{ root: classes.right }} noWrap variant="body2">{item.right}</Typography>} />
                    </ListItem>
                    {!(index === content.length - 1) && <Divider className={offsetDivider ? 'ml-2' : ''} key={`divider${index}`} component="div" />}
                </Fragment>
            })}
            {containsPrivateInfo && <ListItem disableGutters component="span" className="px-1">
                <Button className="m-0-auto" onClick={togglePrivateInfo} aria-labelledby={ariaTitleId} aria-describedby={`${identifier}-show-details`} color="secondary">
                    <span className="d-flex align-items-center" id={`${identifier}-show-details`}>
                        {hidden 
                            ? <Fragment> Show More<ArrowDropDown /></Fragment> 
                            : <Fragment>Show Less<ArrowDropUp /></Fragment>}
                    </span>
                </Button>
            </ListItem>}
    </MaterialList>
}

List.defaultProps = {
    content: [],
    offsetDivider: true,
    newWindowId: EXTERNAL_LINK_DESCRIPTOR
}

List.propTypes = {
    key: PropTypes.string,
    newWindowId: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    ariaTitleId: PropTypes.string,
    className: PropTypes.string,
    offsetDivider: PropTypes.bool,
    content: PropTypes.arrayOf(PropTypes.shape({
        subheading: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string,
        private: PropTypes.bool,
        url: PropTypes.string
    })).isRequired
}

export default List