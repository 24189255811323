import {
  PARKING_STATUS,
  PARKING,
  PARKING_LOCATIONS
} from '../actions-index';
import { transformLocation } from './transform';

export function parking(state = {}, action = {}) {
  switch (action.type) {
      case PARKING:
          let {data, style} = action.payload
          let updatedState = {
            ...state,
            status: 'success'
          };
          if(style === 'lot') {
            updatedState.lot = data;
          } else {
            updatedState.garage = data;
          }
          return updatedState;
        case PARKING_LOCATIONS:
          let {lots = [], garages = []} = transformLocation(action.payload)
          let locationState = {
              ...state,
              lots,
              garages,
              status: 'success'
          }
          console.log('locationState',locationState)
          return locationState;
      case PARKING_STATUS:
          return {
              ...state,
              status: action.payload
          }
      default:
          return state
  }
}
