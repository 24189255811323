import {
    CONTENT_FEED, CONTENT_SECTION_QUERY, SEARCH_CONTENT_SECTION, FILTER_CONTENT_FEED
} from '../actions-index';
import { transformFilters, checkFiltersForActiveId } from '../Filters/transform';
import { isEmpty } from 'lodash'

export function content(state = {}, action = {}) {
    const { payload } = action
    const { filters = [], _list = [] } = state
    const baseFilters = (arr = []) => transformFilters(arr)
    
    switch (action.type) {
        case CONTENT_FEED: {
            let content = Array.isArray(payload) ? payload.map(section => ({ ...section, _articles: section.articles || [] })) : []

            return {
                ...state,
                _list: content,
                list: content,
                featuredContent: content.slice(0, 2),
                filters: baseFilters(content),
                status: payload.status || 'success'
            }
        }
        case FILTER_CONTENT_FEED: {
            let contentFilters = Array.isArray(filters) && filters.length > 0 ? filters : baseFilters(_list)

            let updatedFilters = []
            if(!isEmpty(payload)) {
                updatedFilters = contentFilters.map(o => ({ ...o, active: payload === o.id ? !o.active : o.active }))
            } else {
                updatedFilters = contentFilters.map(filter => ({ ...filter, active: false }))
            }
       
            return {
                ...state,
                list: _list.filter(o => checkFiltersForActiveId(updatedFilters, o.id)),
                filters: updatedFilters
            }
        }
        case SEARCH_CONTENT_SECTION: {
            return {
                ...state,
                ...payload
            }
        }
        case CONTENT_SECTION_QUERY: {
            return {
                ...state,
                query: action.payload
            }
        }
        default:
            return state
    }
}