import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QUERY_PREFIX, PROJECT_NAME } from '../../util/constants';
import SearchInputBar from '../../Common/components/SearchInputBar';

class SearchBar extends Component {
    handleChange(query = '', small = false) {
        const { history, filterTypes, match = { params: { type: '' } } } = this.props
        let route = !!query ? { search: `${QUERY_PREFIX}${query}` } : {}
        const byTypes = !!match.params.type ? [match.params.type] : filterTypes

        this.props.executeSearch({ query, byTypes })
        !small && history.push(route)
    }

    render() {
        const { small, query, elevation, onFocus, onBlur, onClick, onKeyPress, className, onKeyDown } = this.props

        const inputProps = {
            label: `Search ${PROJECT_NAME}`,
            pathname: '/search',
            query: query,
            handleChange: (query) => this.handleChange(query, small),
            small,
            onClick,
            onFocus,
            onBlur,
            onKeyDown,
            onKeyPress: (event) => {
                onKeyPress && onKeyPress(event)
                if (!!small) {
                   if(event.key === 'Enter') this.props.history.push({ search: `${QUERY_PREFIX}${query}`, pathname: '/search' })
                }
            },
            id: this.props.id,
            inputProps: this.props.inputProps
        }
        const clearProps = {
            'aria-label': 'Clear search query',
            onClick: () => this.props.executeSearch({ query: '' })
        }

        return <SearchInputBar className={className} small={small} elevation={elevation}
            inputProps={inputProps} clearProps={clearProps}
        />
    }
}

SearchBar.defaultProps = {
    setSearchQuery: () => { },
    executeSearch: () => { },
    query: "",
    small: false,
    history: {},
    pagination: {},
    filterTypes: [],
    inputProps: {}
}

SearchBar.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            search: PropTypes.string,
            push: PropTypes.func
        })
    }),
    query: PropTypes.string,
    setSearchQuery: PropTypes.func,
    executeSearch: PropTypes.func,
    small: PropTypes.bool,
    pagination: PropTypes.shape({
        to: PropTypes.number,
        from: PropTypes.number
    })
}

export default SearchBar