import React, { useEffect } from 'react'
import { Typography, Box, Container } from '@material-ui/core'
import '../styles/index.scss';
import PropTypes from 'prop-types'
const sanitizeHtml = require('sanitize-html');

function EmergencyAlert(props = {}) {
    const { getEmergencyAlert, feed, className } = props
    useEffect(() => {
        getEmergencyAlert()
    }, [getEmergencyAlert])

    if (feed.length === 0) return null
    return feed.map((item, index) => {
            const { content, id } = item
            const sanitizedHtmlContent = sanitizeHtml(content, {
                transformTags: {
                    'a': sanitizeHtml.simpleTransform('a', {
                        target: '_blank',
                        rel: 'noopener'
                    })
                }
            })

            return <Box key={`emergency-alert-${index}-${id}`} className={`emergency-alert ${className}`.trim()}>
                <Container>
                    <Typography
                        component={'div'}
                        variant="h6"
                        role="alert" 
                        aria-atomic="true"
                        className={`text-wrapper`}
                        dangerouslySetInnerHTML={{
                            __html: sanitizedHtmlContent
                        }}
                    />
                </Container>
            </Box>
    })
}

EmergencyAlert.defaultProps = {
    feed: [],
    className: '',
    getEmergencyAlert: () => { }
}

EmergencyAlert.propTypes = {
    feed: PropTypes.array,
    className: PropTypes.string,
    getEmergencyAlert: PropTypes.func
}

export default EmergencyAlert