import { KNOWLEDGEBASE_KEY, HR_KNOWLEDGEBASE_KEY, BUILDINGS_KEY, LACTATION_ROOM_KEY, GENDER_INCLUSIVE_KEY, FIND_PEOPLE_KEY, HELPFUL_LINKS_KEY, GOOGLE_SEARCH_KEY, ADMIN_RESOURCE_CENTER_KEY, COLLIBRA_BUSINESS_KEY, COLLIBRA_SSRS_KEY, COLLIBRA_WORKDAY_KEY, COLLIBRA_TABLEAU_KEY } from '../util/constants'

function getLabel(type) {
    return [...searchTypes, ...roomTypes].find(o => o.type === type).label
}

function getType(label) {
    return [...searchTypes, ...roomTypes].find(o => o.label === label).type
}

const roomTypes = [
    {
        type: LACTATION_ROOM_KEY,
        key: 'Lactation Room',
        label: 'Lactation Rooms'
    },
    {
        type: GENDER_INCLUSIVE_KEY,
        key: 'Gender Inclusive',
        label: 'Gender Inclusive Restrooms'
    }
]

let searchTypes = [
    {
        type: BUILDINGS_KEY,
        sortPriority: 2,
        label: 'Buildings'
    },
    {
        type: KNOWLEDGEBASE_KEY,
        sortPriority: 3,
        label: 'IT Knowledge Base Articles'
    },
    {
        type: HR_KNOWLEDGEBASE_KEY,
        sortPriority: 4,
        label: 'HR Knowledge Base Articles',
        authenticated: true
    },
    {
        type: FIND_PEOPLE_KEY,
        sortPriority: 5,
        label: 'People'
    },
    {
        type: HELPFUL_LINKS_KEY,
        sortPriority: 1,
        label: 'Helpful Links'
    },
    {
        type: ADMIN_RESOURCE_CENTER_KEY,
        sortPriority: 6,
        label: 'Administrative Resource Center'
    },
    {
        type: COLLIBRA_BUSINESS_KEY,
        sortPriority: 7,
        label: 'Collibra - Business Term',
        authenticated: true
    },
    {
        type: COLLIBRA_SSRS_KEY,
        sortPriority: 8,
        label: 'Collibra - SSRS Reports',
        authenticated: true
    },
    {
        type: COLLIBRA_WORKDAY_KEY,
        sortPriority: 9,
        label: 'Collibra - Workday Reports',
        authenticated: true
    },
    {
        type: COLLIBRA_TABLEAU_KEY,
        sortPriority: 9,
        label: 'Collibra - Tableau Reports',
        authenticated: true
    }
]

if(process.env.REACT_APP_ENABLE_GOOGLE_SEARCH === "true") {
    searchTypes.push({
        type: GOOGLE_SEARCH_KEY,
        sortPriority: searchTypes.length + 1,
        label: 'Search Ohio State'
    })
}

export { getLabel, getType, searchTypes, roomTypes }