import React from 'react'
import { Typography, CircularProgress } from "@material-ui/core"

const SimpleLoader = ({ phrase, className, ...rest }) => {
    return <Typography variant="h5" component="span" color="secondary" className={`d-flex flex-column align-items-center ${className}`.trim()} {...rest}>
        {phrase}
        <CircularProgress className="mt-2" width={60} height={60} color="primary" />
    </Typography>
}

SimpleLoader.defaultProps = {
    className: "",
    phrase: 'Loading...'
}

export default SimpleLoader