import { intersection, isEmpty } from 'lodash';

function filterLinksByUser(user = {}, navigation = []) {
    const filteredLinks = Array.isArray(navigation) ? navigation.filter(
        item => {
            const linkAffiliations = item && item.affiliation && Array.isArray(item.affiliation) ? item.affiliation : []
            const userAffiliations = user && user.affiliation && Array.isArray(user.affiliation) ? user.affiliation : []
            if(isEmpty(user) || isEmpty(userAffiliations)) {
                return Array.isArray(linkAffiliations) ? linkAffiliations.includes('member') : []
            }
            const matchingAffiliation = intersection(linkAffiliations, userAffiliations).length > 0

            return matchingAffiliation
        }
    ) : []

    return filteredLinks
}

function transformLinks(links = []) {
    const formattedLinks = Array.isArray(links) ? 
        links.map(link => {
            const { name = '', url = '', affiliation = [], internal = false, mobileOnly =  false } = link
            return {
                name,
                url,
                affiliation: Array.isArray(affiliation) ? affiliation : [],
                internal: internal ? true : false,
                mobileOnly: mobileOnly ? true : false
            }
        })
    : []

    return formattedLinks
}

export { filterLinksByUser, transformLinks }