import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import { ListSubheader, Paper, ListItem } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { AppContext } from '../../App/context';
import useStyles from '../styles/theme';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

function StickyListWrapper(props = {}) {
    const { className, style, loading, showPartialLoader, headingStart } = props
    const context = useContext(AppContext) || {}
    const { navHeight = 0, elevation = { standard: 0 } } = context
    const classes = useStyles({ navHeight })

    let { children, title, footer } = props
    const childLoading = [1, 2, 3].map((o) => <ListItem key={`skeleton${o}wrapper${camelCase(title)}`} className="py-0" disableGutters>
        <Skeleton className={`w-100 py-1`} key={o} variant="text" />
    </ListItem>)
    
    if (showPartialLoader) {
        children = Array.isArray(children) ? [...children, ...childLoading] : childLoading
    } else if (loading) {
        title = <Skeleton className="py-2 w-50" variant="text" />
        children = childLoading
        footer = <Skeleton className="ml-auto py-2 w-25" variant="text" />
    }


    const renderSubheader = () => {
        if (React.isValidElement(title)) {
            return title
        }
        return <ListSubheader component={headingStart ? `h${headingStart}` : 'div'}  disableGutters className="my-0" classes={{ root: classes.listSubheader }}>
            {title}
        </ListSubheader>
    }
    return <Paper style={style} classes={{ root: classes.listWrapperPaper }} className={className} elevation={props.elevation || elevation.standard}>
        {renderSubheader()}
        {Array.isArray(children)
            ? <List>{children}</List>
            : children}
        {footer}
    </Paper>
}

export default StickyListWrapper

StickyListWrapper.defaultProps = {
    loading: false
}

StickyListWrapper.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    style: PropTypes.object,
    footer: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
}