import React, { useRef, useEffect } from 'react';
import { AppBar as MuiAppBar, Toolbar, Typography, ButtonBase, Divider } from '@material-ui/core/';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useStyles from '../styles/theme';
import { PROJECT_NAME } from '../../util/constants';
import PropTypes from 'prop-types';
import SkipTo from '../../Common/components/SkipTo';
const compass = require('../../Common/assets/compass-normal.svg')
const compassWhite = require('../../Common/assets/compass-darkmode.svg')

function AppBar(props) {
    const { darkMode = false, determineHeight, className, elevation } = props
    const { pathname } = useLocation() || {}
    const ref = useRef({ current: { clientHeight: null } })

    useEffect(() => {
        determineHeight(ref.current.clientHeight)
    }, [ref, determineHeight])

    const classes = useStyles();

    let ariaProps = {
        'aria-label': PROJECT_NAME + ' Homepage',
    }
    if(pathname === '' || pathname === '/') ariaProps = {...ariaProps, 'aria-current': 'page' }

    return <MuiAppBar ref={ref} elevation={elevation} className={className} position="sticky" color="inherit" id="header">
        <SkipTo />
        <Toolbar style={{ borderBottom: 'solid 3px #bb0000' }}>
            <ButtonBase {...ariaProps} role={undefined} className="d-flex align-items-center mr-auto" component={RouterLink} to="/">
                <img className={`${classes.icon} ${classes.brandIcon}`} alt="" src={darkMode ? compassWhite : compass} />
                <Typography color="secondary" classes={{ root: classes.title }} className="pl-1 d-flex flex-column" variant="h6" component="span" noWrap>
                    {PROJECT_NAME}
                </Typography>
            </ButtonBase>
            {props.children}
        </Toolbar>
    </MuiAppBar>
}

export default AppBar

AppBar.defaultProps = {
    className: '',
    elevation: 0,
    determineHeight: (height) => ({ height })
}

AppBar.propTypes = {
    elevation: PropTypes.number,
    className: PropTypes.string,
    darkMode: PropTypes.bool,
    determineHeight: PropTypes.func
}