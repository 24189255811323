
import React, { useContext } from 'react';
import { Typography, Paper, Box, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../context';
import PropTypes from 'prop-types'
import '../styles/index.scss'
const compass = require('../../Common/assets/compass-standard.png')
const compassWhite = require('../../Common/assets/compass-darkmode.png')

function NoComponentFound(props = {}) {
    const { phrase, secondaryPhrase, error, additionalPaths } = props
    const { darkMode } = useContext(AppContext) || {}
    const paths = [
        {
            route: '/',
            label: 'Home'
        }, {
            route: '/helpful-links',
            label: 'Helpful Links'
        }, {
            route: '/buses',
            label: 'Buses'
        }, {
            route: '/parking',
            label: 'Parking'
        },
        ...additionalPaths
    ]
    
    return <Paper className="p-4">
        <Typography component="h1" variant="h6" className="mb-1 w-100">
            {error}
        </Typography>
        <Box className="d-flex flex-row h-100 spaceBetween">
            <Box className="d-flex flex-column spaceBetween">
                <Typography component="h2" variant="h5" className="mb-1">
                    {phrase}
                </Typography>
                <div>
                    <Typography component="h3" variant="subtitle1" className="mb-1">
                        {secondaryPhrase}
                    </Typography>
                    <ul className="no-component-list">
                        {paths.map((o, index) => <li key={`no-comp-found-${index}`} className={'mb-1'}>
                            <Link variant="subtitle2" color="primary" component={RouterLink} to={o.route} key={`404-path-${index}`}>{o.label}</Link>
                        </li>
                        )}
                    </ul>
                </div>
            </Box>
            <img className="compass-404" src={darkMode ? compassWhite : compass} />
        </Box>
    </Paper>
}

NoComponentFound.defaultProps = {
    error: `404 - Page Not Found!`,
    phrase: `Hmm... this route doesn’t look familiar.`,
    secondaryPhrase: `Let's get you pointed in the right direction:`,
    additionalPaths: []
}

NoComponentFound.propTypes = {
    error: PropTypes.string,
    phrase: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    additionalPaths: PropTypes.arrayOf(PropTypes.shape({
        route: PropTypes.string,
        label: PropTypes.string
    }))
}

export default NoComponentFound