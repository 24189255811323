import { ArrowRight, Launch } from '@material-ui/icons';
import { darkModePalette, standardPalette } from '../App/styles/colors';

function transformLinks(links = []) {
    const formattedLinks = Array.isArray(links) ? 
        links.map(link => {
            const { name = '', affiliation = [], backgroundColor = {}, action } = link
            const buttonLabel = !!action.label ? action.label : action.internal ? 'View' : 'Go'
            return {
                label: name,
                backgroundColor: {
                    standard: backgroundColor.standard || standardPalette.primary.main,
                    darkMode: backgroundColor.darkMode || darkModePalette.primary.main
                },
                affiliation,
                button: {
                    internal: action.internal,
                    label: buttonLabel,
                    url: action.url,
                    icon: action.internal ? ArrowRight : Launch
                }
            }
        })
    : []

    return formattedLinks
}


export { transformLinks }