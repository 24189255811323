const { FOCUSABLE_ELEMENT_QUERY } = require("./constants")

const changeFocusWithArrowsUpDown = (event = {}, containerElement) => {
    if (!['ArrowDown', 'ArrowUp'].includes(event.key)) return
    const { focusableElements, currentPosition } = getPositionValuesForFocusableElements(containerElement)

    const currentlyLast = ((currentPosition + 1) === focusableElements.length)

    let newPostion
    if (event.key === 'ArrowDown') {
        newPostion = (currentPosition === -1 || currentlyLast) ? 0 : currentPosition + 1
    } else if (event.key === 'ArrowUp') {
        newPostion = (currentPosition === -1 || currentPosition === 0) ? focusableElements.length - 1 : currentPosition - 1
    }

    const nextElementToFocus = focusableElements[newPostion]
    if (!!nextElementToFocus) {
        nextElementToFocus.focus()
    }
}

const getPositionValuesForFocusableElements = (containerElement = document, currentElement = document.activeElement) => {
    const focusableElements = [...containerElement.querySelectorAll(FOCUSABLE_ELEMENT_QUERY)]
    const currentPosition = focusableElements.findIndex(element => element === currentElement)

    return {
        focusableElements,
        currentPosition
    }
}

export { changeFocusWithArrowsUpDown, getPositionValuesForFocusableElements }