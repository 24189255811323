import typography from './typography'
import overrides from './overrides'
import { standardPalette, darkModePalette } from './colors';
import { createMuiTheme } from '@material-ui/core';

const theme = (darkMode, elevation) => {
    return createMuiTheme({
        typography,
        overrides: overrides(darkMode, elevation),
        palette: darkMode ? darkModePalette : standardPalette
    });
}

export default theme