import React from 'react';
import ReactGA from 'react-ga';
import { EXTERNAL_LINK_DESCRIPTOR } from './constants';

export function createException(description = '', fatal = false) {
    return ReactGA.exception({ description, fatal })
}

export function createEvent(values = { category: '', action: '', label: '' }) {
    return ReactGA.event(values);
}

export const OutboundLink = React.forwardRef((props, ref) => <ReactGA.OutboundLink ref={ref} target="_blank" rel="noopener" aria-describedby={EXTERNAL_LINK_DESCRIPTOR} to={props.href} {...props} />)

export default ReactGA