import {
    ANNOUNCEMENTS_STATUS,
    ANNOUNCEMENTS,
    ANNOUNCEMENTS_DISMISS
} from '../actions-index';
import { isEmpty, findIndex } from 'lodash'

export function announcements(state = {}, action = {}) {

    switch (action.type) {
        case ANNOUNCEMENTS:
            const { list, _list } = action.payload
            const newList = !!list ? list : !!state.list ? state.list : []
            const _newList = !!_list ? _list : !!state._list ? state._list : []
            const convertListObj = (obj = {}) => {
                const { id } = obj
                const matching = Array.isArray(newList) && newList.find(o => o.id === id)
                return {
                    ...obj,
                    archived: isEmpty(matching)
                }
            }
            return {
                ...state,
                list: newList,
                _list: Array.isArray(_newList) ? _newList.map(o => convertListObj(o)) : [],
                status: 'success'
            }
        case ANNOUNCEMENTS_STATUS:
            return {
                ...state,
                status: action.payload
            }
            case ANNOUNCEMENTS_DISMISS: {
                const { list = [], _list = [] } = state
                const { id, status = 'success' } = action.payload
                const getStatus = (identifier = '', existingStatus = '') => identifier === id ? status : existingStatus
                const listItemIndex = findIndex(list, ["id", id]);
                const listItem = list[listItemIndex];
                list.splice(listItemIndex, 1);
                const _listItem = { ...listItem, archived: true };
                let _newList = [..._list ?? []];
                const match = _newList.find(o => o.id === id);
                if (!match) {_newList.push(_listItem)} else {match.archived = true};
                return {
                    ...state,
                    list: list.map(o => ({ ...o, status: getStatus(o.id, o.status) })),
                    _list: _newList
                }
            }
        default:
            return state
    }
}
