import {
    NAVIGATION_LINKS
} from '../actions-index';

export function navigation(state = {}, action = {}) {
    switch (action.type) {
        case NAVIGATION_LINKS:
            return {
                ...state,
                links: action.payload,
                status: 'success'
            }
        default:
            return state
    }
}