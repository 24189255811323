import { WIFI_LOCATIONS } from "../actions-index"
import { generateAction } from "../redux-helpers"
import { formatWifiLocations } from "./transform"

export function getWifiLocations() {
    return async (dispatch) => {
        let response
        const setStatus = (status) => dispatch(generateAction(WIFI_LOCATIONS, { status }))

        setStatus('loading')

        try {
            response = await fetchWifiLocations()
        } catch (error) {
            console.error(`Error fetching wifi locations`, error)
            setStatus('error')
        }
        
        if(response && response.data && response.data.locations) {
            dispatch(generateAction(WIFI_LOCATIONS, { list: formatWifiLocations(response.data.locations) }))
        } else setStatus('error')
    }
}

async function fetchWifiLocations() {
    let payload = {}
    const response = await fetch(process.env.REACT_APP_CONTENT_V2 + `/wifi-access-points`)
    if (!!response) {
        payload = await response.json()
    }

    return payload
}