import { makeStyles } from '@material-ui/core/styles';
import { STYLESHEET_LENGTH } from '../../util/constants';

const useStyles = makeStyles(theme => ({
    headingText: {
        paddingLeft: theme.spacing()
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    left: {
        width: '70%'
    },
    right: {
        width: '30%',
        textAlign: 'right'
    },
    customSidebarIcon: {
        marginRight: '5px'
    }
}), { index: STYLESHEET_LENGTH - 1 });

export default useStyles