import { generateAction } from "../redux-helpers"
import { DINING_LOCATIONS, DINING_LOCATION_STATUS, DINING_MENU, DINING_FILTERS, TOGGLE_DINING_FILTER, UPDATE_ACTIVE_MENU, FOOD_TRUCKS, SEARCH_FOOD_TRUCKS, SEARCH_DINING_LOCATIONS } from "../actions-index"
import { transformDiningProps, transformDiningFilters, searchAcrossMenus, filterMenus, transformFoodTrucks, searchFoodList } from "./transform"
import { orderBy } from 'lodash'

export function getDiningLocations() {
    return async (dispatch) => {
        let response, buildings
        const setStatus = (status = 'loading') => {
            dispatch(generateAction(DINING_LOCATION_STATUS, { status }))
        }
        setStatus()
        try {
            response = await fetchDiningLocations()
            buildings = await fetchBuildings()
        } catch (error) {
            setStatus('error')
            console.log('Error fetching dining locations', error)
        }
        if (response && response.data && response.data.locationsMenus && response.data.locationsMenus.length > 0) {
            dispatch(generateAction(DINING_LOCATIONS, transformDiningProps(response.data.locationsMenus, buildings.data && buildings.data.buildings ? buildings.data.buildings : [])))
        } else {
            setStatus('error')
        }
    }
}

export function searchDiningLocations(query) {
    return (dispatch, getState) => {
        const state = getState() || { food: {} }
        const { campusDining = { locations: {} } } = state.food
        const { locations = { _list: [] } } = campusDining
        const { list } = searchFoodList(locations._list || [], query)
        
        dispatch(generateAction(SEARCH_DINING_LOCATIONS, { locations: { ...locations, list }, query }))
    }
}

export function getFoodTrucks() {
    return async (dispatch) => {
        let response
        const setStatus = (status = 'loading') => {
            dispatch(generateAction(FOOD_TRUCKS, { status }))
        }

        setStatus()
        try {
            response = await fetchFoodTrucks()
        } catch (error) {
            setStatus('error')
            console.log('Error fetching food trucks', error)
        }
        
        if (response && response.data && response.data.events  && response.data.events.length > 0) {
            dispatch(generateAction(FOOD_TRUCKS, transformFoodTrucks(response.data.events)))
        } else {
            setStatus('error')
            console.log('Unexpected response from food trucks', response)
        }
    }
}

export function searchFoodTrucks(query) {
    return (dispatch, getState) => {
        const state = getState() || { food: {} }
        const { foodTrucks = { _list: [], list: [] } } = state.food

        dispatch(generateAction(SEARCH_FOOD_TRUCKS, searchFoodList(foodTrucks._list || [], query)))
    }
}

export function getMenu(idParams = '', sections = []) {
    const locationId = !!idParams ? idParams.split('#')[0] : ''

    return async (dispatch, getState) => {
        const state = getState() || { food: { filters: { list: [] }, cachedMenus: [] } }
        const filtersActive = state.food.filters && Array.isArray(state.food.filters.list) && state.food.filters.list.length > 0
        let payload
        let menus = []
        const setStatus = (status = 'loading') => {
            dispatch(generateAction(DINING_MENU, { status }))
        }
        setStatus()
        
        const matchingMenu = Array.isArray(state.food.cachedMenus) && state.food.cachedMenus.find(menu => menu.locationId === locationId && menu._menus.length > 0)        
        if(!!matchingMenu) {
            payload = matchingMenu
            if(filtersActive) {
                const filteredMenus = filterMenus({ filters: state.food.filters.list, menus: matchingMenu._menus }).menus
                payload = {
                    ...payload,
                    filteredMenus
                }
            }
            dispatch(generateAction(DINING_MENU, payload))
            return
        }
        try {
            for (let section of sections) {
                const { sectionID } = section

                if (sectionID) {
                    let response = await fetchMenu(sectionID)
                    if (!!response && response.data && Array.isArray(response.data.fullMenu)) {
                        menus.push({
                            order: section.order,
                            title: section.menuSectionName,
                            menuId: sectionID,
                            menu: orderBy(response.data.fullMenu, ['sortOrder', 'name'])
                        })
                    }
                }
            };
        } catch (error) {
            setStatus('error')
            console.log('error fetching menus', error)
        }

        if (menus.length > 0) {
            payload = {
                locationId,
                menus,
                _menus: menus
            }
            if(filtersActive) {
                const filteredMenus = filterMenus({ filters: state.food.filters.list, menus }).menus
                payload = {
                    ...payload,
                    filteredMenus
                }
            }
            dispatch(generateAction(DINING_MENU, payload))
        } else setStatus('error')
    }
}

export function getMenuFilters() {
    return async (dispatch) => {
        const setStatus = (status) => dispatch(generateAction(DINING_FILTERS, { status }))
        setStatus('loading')
        let filters = []
        try {
            let response = await fetchFilters()
            if (!!response && response.data && Array.isArray(response.data.lookups)) filters = response.data.lookups
        } catch (error) {
            console.log('error fetching filters', error)
            setStatus('error')
        }
        dispatch(generateAction(DINING_FILTERS, transformDiningFilters(filters)))
    }
}

export function selectFilter(id) {
    return (dispatch, getState) => {
        const state = getState() || { food: {} }
        const { filters = { list: [] }, activeMenu = { menus: [] } } = state.food || {}
        const { filterList, menus } = filterMenus({ id, filters: filters.list, menus: activeMenu.menus })

        dispatch(generateAction(TOGGLE_DINING_FILTER, { filters: filterList, filteredMenus: menus }))
    }
}

export function searchActiveMenu(query) {
    return (dispatch, getState) => {
        const state = getState() || { food: {} }
        const { activeMenu } = state.food || {}
        const searchResults = searchAcrossMenus(query, activeMenu)

        dispatch(generateAction(UPDATE_ACTIVE_MENU, { ...searchResults, query }))
    }
}

async function fetchDiningLocations() {
    let payload = {}
    const response = await fetch(process.env.REACT_APP_CONTENT_V2 + `/api/v1/dining/locations/menus`)

    if (!!response) {
        payload = await response.json()
    }

    return payload
}

async function fetchFoodTrucks() {
    let payload = {}
    const response = await fetch(process.env.REACT_APP_CONTENT_V2 + `/foodtruck/events`)

    if (!!response) {
        payload = await response.json()
    }

    return payload
}

async function fetchBuildings() {
    let payload = {}
    const buildings = await fetch(process.env.REACT_APP_CONTENT_V2 + '/api/buildings')

    if (!!buildings) {
        payload = await buildings.json()
    }

    return payload
}

async function fetchMenu(sectionID) {
    let payload = {}
    const menu = await fetch(process.env.REACT_APP_CONTENT_V2 + `/api/v1/dining/full/menu/section/${sectionID}`)

    if (!!menu) {
        payload = await menu.json()
    }

    return payload
}

async function fetchFilters() {
    let payload = {}
    const filters = await fetch(process.env.REACT_APP_CONTENT_V2 + `/api/v1/dining/lookups/`)

    if (!!filters) {
        payload = await filters.json()
    }

    return payload
}