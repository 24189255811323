import { PARKING_STATUS, PARKING, PARKING_LOCATIONS } from "../actions-index";
import { API } from 'aws-amplify';
import { constructParking, constructLocation } from './transform'
import { generateAction } from "../redux-helpers";

function createParkingInfoQuery(id) {
  return `{
    lastUpdated
    totalNumberOfIntervals
    statuses(ids: ${id}) {
      id
      name
      current {
        day
        hour
        interval
        percentage
      }
      average {
        day
        hour
        interval
        percentage
      }
    }
  }`;
}

function createAvailableLoctionsQuery() {
  return `{
    lastUpdated
    totalNumberOfIntervals
    availableIds {
      id
      name
      style
    }
  }`;
}

export function getParking(id, style) {
  const parkingStatus = (status) => generateAction(PARKING_STATUS, status)
  const parkingPostBody = { body: { query: createParkingInfoQuery(id) } };

  return (dispatch) => {
    dispatch(parkingStatus('loading'));

    return API.post(process.env.REACT_APP_PARKING_API_NAME, `/`, parkingPostBody)
      .then(response => {
        if (response) {
          dispatch(generateAction(PARKING, constructParking(response, style)));
        } else {
          dispatch(parkingStatus('error'));
        }
      })
      .catch(error => {
        console.error(error)
        dispatch(parkingStatus('error'));
      })
  }
}

export function getAvailableLocations() {
  const locationStatus = (status) => generateAction(PARKING_STATUS, status)
  const locationPostBody = { 
    body: { 
      query: createAvailableLoctionsQuery()
    }
  };
  return (dispatch) => {
    dispatch(locationStatus('loading'));
    return API.post(process.env.REACT_APP_PARKING_API_NAME, `/`, locationPostBody)
      .then(response => {
        if (response) {
          dispatch(generateAction(PARKING_LOCATIONS, constructLocation(response)));
        } else {
          dispatch(locationStatus('error'));
        }
      })
      .catch(error => {
        console.error(error)
        dispatch(locationStatus('error'));
      })
  }
}