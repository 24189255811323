let quickLinks = [
    {
        path: '/helpful-links',
        label: 'Helpful Links'
    },{
        path: '/buses',
        label: 'Buses'
    },{
        path: '/parking',
        label: 'Parking'
    },{
        path: '/announcements',
        label: 'Announcements',
        authRequired: true
    }
]

if(process.env.REACT_APP_ENABLE_TEMPERATURE_TRACKING === "true") {
    quickLinks.push({
        path: '/health-reporting',
        label: 'COVID-19 Health Reporting'
    })
}

export default quickLinks