import React, { Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, ListItemSecondaryAction, IconButton, ListItemAvatar, Typography, Divider, SvgIcon } from '@material-ui/core';
import { FavoriteBorder, Favorite } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import Image from '../../Common/components/Image';
import { isEmpty } from 'lodash'
import '../styles/index.css'
import CustomIcon from '../../Common/components/CustomIcon';

function LinkableListItem(props) {
    const { className, image, customIcon, divider, scrollId, id, identifier, title, subtitle, description, url, path, favoriteAction, favorite, ariaLabel } = props
    const CollisionLink = React.forwardRef((props, ref) => (
        <RouterLink innerRef={ref} to={path} {...props} />
    ));
    const ExternalLink =  React.forwardRef((props, ref) => <ReactGA.OutboundLink {...props} eventLabel={title} to={url} target="_blank" rel="noopener" />)
    const linkProps = {
        ...ariaLabel ? { 'aria-label': ariaLabel } : {}
    }
    const titleJsx = <span id={identifier}>{title}</span>
    const generatePrimary = () => {
        let primary
        if (!!path) {
            primary = <Link variant="body1" {...linkProps} component={CollisionLink}>{titleJsx}</Link>
        } else if (!!url) {
            primary = <Link variant="body1"{...linkProps} color="primary" component={ExternalLink}>{titleJsx}</Link>
        } else {
            primary = titleJsx
        }

        if(customIcon && customIcon.type) {
            return <Fragment>
                {primary}
                <CustomIcon className="list-item-custom-icon" {...customIcon} />
            </Fragment>
        }

        return primary
    }

    const sharedListItemProps = {
        disableGutters: true,
        id: scrollId || id,
        alignItems: description ? 'flex-start' : 'center'
    }

    const textJsx = <Fragment>
        <ListItemText
            className="w-100 d-flex spaceBetween w-100"
            disableTypography
            primary={generatePrimary()}
            {...!!subtitle ? { secondary: <Typography className="line-clamp-2" color="textSecondary" variant="body2">{subtitle}</Typography> } : {}}
        />
        {!!description && <ListItemText disableTypography primary={<Typography className="line-clamp-2" color="textSecondary" variant="body2">{description}</Typography>} />}
        {favoriteAction && identifier && <ListItemSecondaryAction>
            <IconButton className="ml-1" aria-labelledby={identifier} color="secondary" edge="end" onClick={favoriteAction}>
                {favorite ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
        </ListItemSecondaryAction>}
    </Fragment>

    const renderImageItem = () => <ListItem {...sharedListItemProps} className={`d-flex flex-row align-items-start ${className || ''}`.trim()}>
        <ListItemAvatar style={{ width: '5rem', height: '5rem' }} className="mr-1">
            <Image style={{ borderRadius: '.5rem' }} className="thumbnail-img" fallbackType={image.fallbackType} alt={image.alt} src={image.src} />
        </ListItemAvatar>
        <div>
            {textJsx}
        </div>
    </ListItem>

    return <Fragment>
        {!isEmpty(image) && !!image.src
            ? renderImageItem()
            : <ListItem {...sharedListItemProps} className={`d-flex flex-column align-items-start ${className || ''}`.trim()}>
                {textJsx}
            </ListItem>}
        {divider && <Divider component="li" />}
    </Fragment>
}

export default LinkableListItem

LinkableListItem.defaultProps = {
    identifier: null,
    className: '',
    title: '',
    description: '',
    url: '',
    updatedDate: '',
    path: '',
    favorite: false,
    ariaLabel: null,
    divider: true
}

LinkableListItem.propTypes = {
    divider: PropTypes.bool,
    className: PropTypes.string,
    image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string,
        fallbackType: PropTypes.oneOf(['standard', 'building'])
    }),
    identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string,
    path: PropTypes.string,
    favoriteAction: PropTypes.func,
    favorite: PropTypes.bool,
    ariaLabel: PropTypes.string
}