import { HELPFUL_LINKS, TOGGLE_DEPARTMENT, SEARCH_HELPFUL_LINKS, HELPFUL_LINKS_QUERY } from "../actions-index";
import { transformHelpfulLinks } from "./transform";
import { generateAction } from "../redux-helpers";

export function getHelpfulLinks(query = '') {
    return async dispatch => {
        const dispatchResponse = (type, response) => dispatch(generateAction(type, response))
        let helpfulLinks
        let setStatus = (status = '') => dispatch(generateAction(HELPFUL_LINKS, { status }))
        setStatus('loading')

        try {
            helpfulLinks = await fetchHelpfulLinks()
            if(Array.isArray(helpfulLinks) && helpfulLinks.length > 0) {
                helpfulLinks = transformHelpfulLinks(helpfulLinks)
            }
        } catch(error) {
            console.log('Error fetching helpful links', error)
            setStatus('error')
        }
        if(!!helpfulLinks) {
            dispatchResponse(HELPFUL_LINKS, helpfulLinks)
            !!query && dispatch(searchHelpfulLinks(query))
        }
    }
}

export async function fetchHelpfulLinks() {
    let payload = {}
    const response = await fetch(process.env.REACT_APP_JSON_DOC_LINKS + `/helpfulLinks.json`)

    if (!!response && !!response.ok) {
        payload = await response.json()
    } else {
        payload = {
            status: 'error'
        }
    }
    return payload
}

export function searchHelpfulLinks(query = '', updatingResults = true) {
    return {
        type: updatingResults ? SEARCH_HELPFUL_LINKS : HELPFUL_LINKS_QUERY,
        payload: query
    }
}

export function toggleDepartment(identifier) {
    return {
        type: TOGGLE_DEPARTMENT,
        payload: identifier,
    }
}