import {
  BUS_STOP_STATUS,
  BUS_STOPS,
  BUS_ROUTES,
  VEHICLE_STOPS,
  VEHICLE_STOPS_STATUS,
  UPDATE_BUSES_DRAWER_OPEN
} from '../actions-index';

export function buses(state = {}, action = {}) {
  switch (action.type) {
        case UPDATE_BUSES_DRAWER_OPEN:
          return {
            ...state,
            drawerOpenVehicle: action.payload.vehicleId,
            drawerOpenStop: action.payload.stopId
          }
        case VEHICLE_STOPS:
          let vechileStopsData = action.payload.data;
          let vehicleStops = vechileStopsData.predictions || [];
          let vehicleStopsState = {
              ...state,
              vehicleStops,
              vehicleStopsStatus: 'success'
          }
          return vehicleStopsState;

        case VEHICLE_STOPS_STATUS:
          return {
            ...state,
            vehicleStopsStatus: action.payload
          }
        case BUS_ROUTES:
          let routesData = action.payload.data;
          let routes = routesData.routes || [];
          let busRouteState = {
              ...state,
              routes,
              status: 'success'
          }
          return busRouteState;
        case BUS_STOPS:
          let stopsData = action.payload.data;
          let stops = stopsData.stops || [];
          let status = state.stops && state.stops.length && state.stops[0].id !== stops[0].id ? 'updated' : 'success';
          let busStopState = {
              ...state,
              stops: stops,
              status
          }
          return busStopState;
      case BUS_STOP_STATUS:
          return {
              ...state,
              status: action.payload
          }
      default:
          return state
  }
}
