import { Update, AttachMoney, CalendarToday, School, SupervisorAccount } from "@material-ui/icons";
import { CURRENT_PAY_KEY, LEARNING_KEY, LEAVE_BALANCES_KEY, CURRENT_TIMESHEET_KEY, SERVICENOW_KEY, HR_SERVICENOW_KEY } from "../util/constants";

const generateWorkdayEndpoint = (identifier) => `${process.env.REACT_APP_WORKDAY_BASE_URL}${identifier}.htmld`

const labels = [
    {
        key: LEAVE_BALANCES_KEY,
        title: 'Time Off',
        url: generateWorkdayEndpoint(`2997$11831`),
        secondaryLink: {
            url: process.env.REACT_APP_HOLIDAY_CALENDAR || 'https://hr.osu.edu/wp-content/uploads/policy620-future-holiday-calendars.pdf',
            label: 'Holiday Calendar'
        },
        CustomIcon: CalendarToday
    },
    {
        key: CURRENT_PAY_KEY,
        title: 'Payslip',
        url: generateWorkdayEndpoint(`2997$1475`),
        CustomIcon: AttachMoney
    },
    {
        key: LEARNING_KEY,
        title: 'Learning',
        url: 'https://buckeyelearn.osu.edu/',
        CustomIcon: School
    },
    {
        key: CURRENT_TIMESHEET_KEY,
        title: 'Timesheet',
        url: generateWorkdayEndpoint(`2997$4767`),
        CustomIcon: Update
    },
    {
        key: SERVICENOW_KEY,
        title: 'IT Service Desk',
        url: 'https://osuitsm.service-now.com/navpage.do',
        CustomIcon: Update
    },
    {
        key: HR_SERVICENOW_KEY,
        title: 'HR Connection',
        url: 'https://hrconnection.osu.edu',
        CustomIcon: SupervisorAccount
    }
]

function getLabel(key = '') {
    const label = labels.find(o => o.key === key)

    return label
}

export { labels, getLabel as default }