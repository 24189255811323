import React, { useState, useRef, useContext } from 'react'
import { AppContext } from '../../App/context'
import PropTypes from 'prop-types';
import { omit } from 'lodash'
import { Typography } from '@material-ui/core';
const standardDefault = require(`../assets/placeholders/standard-day.svg`)
const standardLight = require(`../assets/placeholders/standard-night.svg`)
const buildingDefault = require(`../assets/placeholders/building-day.svg`)
const buildingLight = require(`../assets/placeholders/building-night.svg`)
const lazyImg = require('../assets/LazyLoading.jpg')

const allowedPlaceholders = ['standard', 'building']

function ErrorImage(props = {}) {
    const { descriptor, style, className } = props
    const { darkMode = false } = useContext(AppContext) || {}

    const getPath = () => {
        if (descriptor === 'building') {
            return darkMode ? buildingLight : buildingDefault
        }
        return darkMode ? standardLight : standardDefault
    }

    return <img src={getPath()} alt="" style={style} className={className} />
}

function Image(props = {}) {
    const { fallbackType, alt, hideOnError, placeholderSize, caption, className } = props
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const imageRef = useRef()
    const setErrorImage = () => {
        if (imageRef && imageRef.current && !error) setError(true)
    }
    const handleOnLoad = () => {
        if(!error && !loading) {
            imageOnLoadEvent(imageRef)
            setLoading(true)
        }
    }

    if (error) {
        return <ErrorImage descriptor={fallbackType} {...!hideOnError ? { className, style: { width: placeholderSize, ...props.style } } : { className: 'd-none' }} />
    }

    const passedInImageProps = { ...omit(props, ['src', 'className', 'hideOnError', 'fallbackType', 'placeholderSize']) }
    const image = (imgProps = {}) => {
        return <img
            ref={imageRef}
            onError={setErrorImage}
            src={lazyImg}
            data-set={props.src}
            onLoad={(e) => handleOnLoad()}
            {...passedInImageProps}
            {...imgProps}
            alt={typeof alt === 'string' ? alt : ""}
        />
    }
    
    if (!!caption) {
        return <div className={`d-flex flex-column ${className || ''}`.trim()}>
            {image()}
            <Typography variant="caption">{caption}</Typography>
        </div>
    }
    return image({ className })
}

function imageOnLoadEvent(imageRef) {
    if (imageRef.current) {
        const currentSrc = imageRef.current.getAttribute('src')
        const currentDataSet = imageRef.current.getAttribute('data-set')

        if (currentDataSet && currentSrc !== currentDataSet) {
            imageRef.current.setAttribute('src', currentDataSet)
        }
    }
}

Image.defaultProps = {
    hideOnError: false,
    fallbackType: 'standard',
    alt: "",
}

Image.propTypes = {
    hideOnError: PropTypes.bool,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    fallbackType: PropTypes.oneOf(allowedPlaceholders)
}

const MemoizedImage = Image

export { imageOnLoadEvent, MemoizedImage as default }