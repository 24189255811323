import React, { Component } from 'react';
import { CssBaseline, Container, Typography } from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import IdleTimer from 'react-idle-timer';
import aws_exports from '../../aws-exports';
import apiConfig from '../../api-config';
import CustomAuthStorage from '../../util/CustomAuthStorage';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../styles/theme'
import NavBar from '../../Navbar/containers';
import AppRoutes from './Routes';
import PropTypes from 'prop-types';
import { AppContext } from '../context';
import MobileNavbar from '../../Navbar/components/Mobile';
import { standardPalette, darkModePalette } from '../styles/colors';
import Footer from '../../Footer/components';
import EmergencyAlert from '../../EmergencyAlert/containers';
import withMobile from '../../Common/components/withMobile';
import { EXTERNAL_LINK_DESCRIPTOR } from '../../util/constants';

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120)

class App extends Component {
    constructor(props) {
        super(props)

        const matchMedia = window.matchMedia("(prefers-color-scheme: dark)").matches
        const validUser = props.user && props.user.status && props.user.status === 'success'

        if (matchMedia) {
            this.updateColorVariables(matchMedia)
        }
        this.state = {
            darkMode: matchMedia,
            navIndex: 1
        }
        if (!validUser && this.props.location.pathname !== "/logout") {
            this.props.setUser();
        }
        this.constructProviderValue = this.constructProviderValue.bind(this)
        this.setNavigationHeight = this.setNavigationHeight.bind(this)
        this.onIdle = this._onIdle.bind(this)

    }
    handleLoginClick = (redirectOverride = false) => { this.props.setUser(redirectOverride); }
    handleLogoutClick = () => { 
        this.props.history.push('/logout')
    }

    updateColorVariables(darkMode) {
        let root = document.documentElement
        const palette = darkMode ? darkModePalette : standardPalette

        root.style.setProperty('--root-text-color', palette.textColor)
        root.style.setProperty('--options-background-color', darkMode ? `var(--elevation8)` : palette.background.default)
        root.style.setProperty('--root-background-color', palette.background.default)
    }

    updateColorMode(toggle = false) {
        this.setState({
            darkMode: toggle
        }, () => this.updateColorVariables(this.state.darkMode))
    }

    setNavigationHeight(height) {
        this.setState({ navHeight: height })
    }

    constructProviderValue() {
        const { navHeight } = this.state
        return {
            mobileNavIndex: this.state.navIndex,
            user: this.props.user,
            signOut: this.handleLogoutClick,
            setUser: this.handleLoginClick,
            darkMode: this.state.darkMode,
            navHeight,
            palette: this.state.darkMode ? darkModePalette : standardPalette,
            setNavHeight: (height) => !!height && height !== navHeight && this.setNavigationHeight(height),
            showTemperatureTracker: process.env.REACT_APP_ENABLE_TEMPERATURE_TRACKING === "true",
            elevation: this.state.darkMode ? {
                standard: 2,
                high: 4,
                navigation: 6,
                tabs: 6,
                drawer: 12
            } : {
                    standard: 1,
                    high: 2,
                    tabs: 2,
                    navigation: 4,
                    drawer: 12
                }
        }
    }

    _onIdle(e) {
        console.log('user is idle', e);
        console.log('last active', this.idleTimer.getLastActiveTime());
        this.props.signOut();
    }

    render() {
        const { mobile , forceReflow} = this.props
        const { navIndex } = this.state
        const providerValue = this.constructProviderValue()
        const appTheme = responsiveFontSizes(theme(this.state.darkMode, providerValue.elevation))
        const renderMobileNav = (props = {}) => mobile && <MobileNavbar pathname={this.props.location.pathname} setNavIndex={(navIndex) => this.setState({ navIndex })} navIndex={navIndex} {...props} />

        return (
            <React.Fragment>
                <IdleTimer ref={ref => { this.idleTimer = ref }}
                    onIdle={this.onIdle}
                    timeout={TIMEOUT} />
                <AppContext.Provider value={providerValue}>
                    <ThemeProvider theme={appTheme}>
                        <CssBaseline />
                        <div className="main-wrapper">
                            {process.env.REACT_APP_ENABLE_EMERGENCY_ALERT === "true" && <EmergencyAlert className="py-2" />}
                            <NavBar hideSearch={this.props.location.pathname.includes('search')} toggleDarkMode={(toggle) => this.updateColorMode(toggle)} className={!forceReflow ? 'mb-2' : ""} />
                            {forceReflow && renderMobileNav({ className: 'mb-2', labelsOnly: true })}
                            <Container component="main" tabIndex="-1" id="main" className={`container${mobile ? ' mobile' : ''}`}>
                                <AppRoutes mobile={mobile} pathname={this.props.location.pathname} />
                                <Typography id={EXTERNAL_LINK_DESCRIPTOR} aria-hidden variant="srOnly">&nbsp;(link is external)</Typography>
                            </Container>
                        </div>
                        <Footer className={`mt-3${forceReflow ? ' pb-2' : ''}`} />
                        {!forceReflow && renderMobileNav()}
                    </ThemeProvider>
                </AppContext.Provider>
            </React.Fragment>
        );

    }
}

App.propTypes = {
    user: PropTypes.shape({
        applicationRoles: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        osuid: PropTypes.string
    }),
    setUser: PropTypes.func
}

const MemoizedApp = React.memo(App, (prev, next) => JSON.stringify(prev) === JSON.stringify(next))

export default (props) => withMobile(props)(MemoizedApp);