import React, { Fragment, useContext } from 'react';
import { Paper, Typography, Box, Button, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AppContext } from '../../App/context';
import List from './List';
import LoadingCard from '../components/LoadingCard'
import { EXTERNAL_LINK_DESCRIPTOR } from '../../util/constants';
import { internalLinkProps } from '../../util/util';
import { ArrowRight, Launch } from '@material-ui/icons';
import ExternalLink from '../../Common/components/ExternalLink';
import useStyles from '../styles/theme';

function SidebarCard(props) {
    const { elevation = {} } = useContext(AppContext) || {}
    const { standard = 1 } = elevation
    const { className, title, url, content, CustomIcon, newWindowId, identifier, children, status, headingStart, headingComponent, internal, preListChildren, secondaryLink } = props
    const ariaTitleId = `title-${identifier}`
    const titleClassName = "px-2 pt-1 d-flex"
    const classes = useStyles();
     
    const PlainTitle = (props = {}) => <Typography className="d-flex align-items-center" {...props} variant="subtitle1">
        {CustomIcon && <CustomIcon className={classes.customSidebarIcon} fontSize="inherit" />}
        {title}
    </Typography>
    
    if (status === 'loading') {
      return (
        <Paper className={className} elevation={standard}>
            <LoadingCard title={<PlainTitle className={`${titleClassName} align-items-center`} component="p" />}/>
        </Paper>
      );
    }

    return <Paper className={className} elevation={standard}>
          {!!title && <Box id={ariaTitleId} className={titleClassName}>
            {url ? (
                <SidebarLink internal={internal} url={url} className="d-flex flex-row" variant="subtitle1" eventLabel={`Sidebar: ${identifier} - Primary Go Link`} color="primary">
                   <span className="d-flex align-items-center" >
                    {CustomIcon && <CustomIcon className={classes.customSidebarIcon} fontSize="inherit" />}
                    {title}
                   </span>
                </SidebarLink>
            ) : <PlainTitle component={headingComponent || `h${headingStart}`} />}
            {(secondaryLink && secondaryLink.url && secondaryLink.label) && 
                <SidebarLink internal={secondaryLink.internal} url={secondaryLink.url} className="ml-auto" color="secondary" eventLabel={`Sidebar: ${identifier} - Secondary Go Link`}>
                    {secondaryLink.label}
                </SidebarLink>}
        </Box>}
        {preListChildren}
        {!!content && <List newWindowId={newWindowId} ariaTitleId={ariaTitleId} identifier={identifier} className="pt-0" content={content} />}
        {children}
    </Paper>
}

const SidebarLink = (props = {}) => {
    const { internal, url, eventLabel, children, ...rest } = props
    
    if(internal) {
        return (
          <Link
            color="secondary"
            {...internalLinkProps(url)}
            {...rest}
            className={`d-flex align-items-center ${props.className || ''}`.trim()}
          >
            {children}
            <ArrowRight fontSize="small" color="secondary" />
          </Link>
        );
    }

    return <ExternalLink eventLabel={eventLabel} href={url} {...rest}>
        {children}
    </ExternalLink>
}

SidebarCard.defaultProps = {
    status: '',
    headingStart: 2,
    internal: false,
    preListChildren: null,
    newWindowId: EXTERNAL_LINK_DESCRIPTOR
}

SidebarCard.propTypes = {
    headingStart: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    identifier: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.element,
    content: PropTypes.arrayOf(PropTypes.shape({
        subheading: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string,
        private: PropTypes.bool,
        url: PropTypes.string
    }))
}

export default SidebarCard