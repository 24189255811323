import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { redirectToLogIn } from '../../util/util';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { PROJECT_NAME } from '../../util/constants';
import { capitalize } from 'lodash'
import SimpleLoader from '../../Common/components/SimpleLoader';

class SignIn extends Component {
    componentDidMount() {
        const { location = { state: { from: '' }}, status } = this.props
        const redirectPath = !!location.state ? location.state.from : ''

        this.props.setUser(redirectPath)
        this.props.generateTitle(!!status ? capitalize(status) : '')
    }

    render() {
        const { status, location = { state: { from: '' }} } = this.props
        const redirectPath = !!location.state ? location.state.from : ''

        if (status === 'loading' || (status === 'error' && !!redirectPath)) {
            return <SimpleLoader role="alert" phrase={'Logging in...'} />
        } else if (status === 'error') {
            return <Typography className="d-flex flex-column align-items-center" component="span" variant="h5">
                There was an error logging you in.
           <Button className="mt-2" variant="contained" color="primary" aria-label={`Retry signing in to ${PROJECT_NAME}`} onClick={() => redirectToLogIn()}>Log In</Button>
            </Typography>
        }
        return <Redirect to="/" />
    }
}

SignIn.propTypes = {
    status: PropTypes.oneOf(['', 'error', 'success', 'loading']),
    user: PropTypes.shape({
        applicationRoles: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        osuid: PropTypes.string
    }),
    setUser: PropTypes.func
}

export default SignIn;

