import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import NavBar from "../components";
import { getNavLinks, searchSite } from '../../actions-index';

const mapStateToProps = (state = {}) => {
    const { navigation = {}, globalSearch = {} } = state
    const { links = [] } = navigation
    const { query = '' } = globalSearch

    return {
        links,
        query
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNavLinks: () => dispatch(getNavLinks()),
        searchSite: (query) => dispatch(searchSite(query))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));