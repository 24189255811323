import { createMuiTheme } from '@material-ui/core';
import { fonts } from './typography';
import elevations from './elevations';
import { mapValues } from 'lodash';
import { typeColor } from '../../styles/palette';
import { darken, lighten } from '@material-ui/core/styles';
import '../../styles/imports.scss'

const defaultTheme = createMuiTheme();

const navBackground = () => ({
    flexGrow: 1,
    zIndex: defaultTheme.zIndex.drawer + 1
})

const focus = {
    root: {
        '&:focus': {
            outline: '-webkit-focus-ring-color auto 5px'
        }
    }
}

const overrides = (darkMode = false, elevation = {}) => {
    const typePalette = typeColor[darkMode ? 'darkMode' : 'standard']
    const shade = darkMode ? '#ffffff' : '#000000'
    
    return {
        MuiFormLabel: {
            colorSecondary: {
                color: typePalette.secondary
            }
        },
        MuiFormControlLabel: {
            root: {
                ...focus.root
            },
            label: {
                color: typePalette.default,
                '&.$Mui-disabled': {
                    color: darkMode ? darken(shade, .4) : lighten(shade, .4)
                }
            }
        },
        MuiCheckbox: {
            root: darkMode ? {
                color: darken(shade, .4)
            } : {}
        },
        MuiInputBase: {
            root: {
                color: 'inherit'
            },
            input: {
                ...focus.root,
                '&::placeholder': {
                    color: darkMode ? darken(typePalette.secondary, .2) : lighten(typePalette.secondary, .2),
                    opacity: 1
                },
                '&::-webkit-input-placeholder': {
                    color: darkMode ? darken(typePalette.secondary, .2) : lighten(typePalette.secondary, .2),
                    opacity: 1
                }
            },
        },
        MuiButton: {
            ...darkMode ? {
                contained: {
                    '&.$Mui-disabled': {
                        color: 'rgba(255, 255, 255, 0.3)',
                        boxShadow: 'none',
                        backgroundColor: 'rgba(255, 255, 255, 0.12)'
                    }
                }
            } : {}
        },
        MuiButtonBase: focus,
        MuiPaper: {
            outlined: darkMode ? {
                border: '1px solid rgba(255,255,255, 0.12)'
            } : {},
            root: {
                color: shade
            },
            ...darkMode ? mapValues(elevations, (item => ({ ...item, boxShadow: 'none' }))) : {}
        },
        MuiTypography: {
            root: {
                color: shade
            },
            colorTextSecondary: {
                color: typePalette.secondary
            }
        },
        MuiBottomNavigationAction: {
            root: {
                color: typePalette.secondary
            }
        },
        MuiSvgIcon: {
            colorPrimary: {
                fill: typePalette.action
            },
            colorSecondary: {
                fill: typePalette.secondary
            }
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': fonts
            }
        },
        MuiListSubheader: {
            root: {
                color: shade
            }
        },
        MuiAppBar: {
            root: {
                ...navBackground()
            }
        },
        MuiBottomNavigation: {
            root: {
                ...navBackground(),
                ...darkMode ? elevations[`elevation${elevation.navigation}`] : {},
                overflow: 'hidden',
                position: 'fixed',
                bottom: 0,
                width: '100%'
            }
        },
        MuiNativeSelect: {
            root: {
                color: shade
            },
            select: {
                color: shade,
                '&.option': darkMode ? elevations[`elevation${elevation.high}`]: {}
            },
            selectMenu: {
                color: shade
            },
            icon: {
                color: shade
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: `1px solid ${shade}`
                },
                '&:before': {
                    borderBottom: `1px solid ${shade}`
                },
                '&:hover&:after': {
                    borderBottom: `1.5px solid ${shade}`
                },
                '&:hover&:before': {
                    borderBottom: `1.5px solid ${shade}`
                },
            }
        },
        MuiDivider: {
            root: darkMode ? {
                backgroundColor: 'rgba(255,255,255, 0.12)'
            } : {}
        },
        MuiListItem: {
            divider: darkMode ? {
                borderBottomColor: 'rgba(255,255,255, 0.12)'
            } : {}
        },
        MuiOutlinedInput: {
            notchedOutline: darkMode ? {
                borderColor: 'rgba(255,255,255, 0.12)'
            } : {}
        },
        MuiSlider: {
            markLabel: darkMode ? {
                color: 'rgba(255,255,255, 0.50)'
            } : {},
            markLabelActive: darkMode ? {
                color: 'rgba(255,255,255, 0.90)'
            } : {}
        },
        MuiSkeleton: {
            root: {
                backgroundColor: `rgba(${darkMode ? `255, 255, 255` : `0, 0, 0`}, 0.1)`
            }
        },
        MuiTableCell: {
            head: {
                color: `var(--root-text-color)`
            },
            body: {
                color: `var(--root-text-color)`
            }
        },
        MuiToggleButtonGroup: {
            root: {
                backgroundColor: 'transparent',
            }
        },
        MuiToggleButton: {
            root: {
                color: typePalette.secondary,
                '&:hover': {
                    backgroundColor: `rgba(${darkMode ? `255, 255, 255` : `0, 0, 0`}, 0.1)`,
                    color: darkMode ? lighten(typePalette.secondary, .6) : darken(typePalette.secondary, .6),
                },
                '&$selected': {
                    backgroundColor: typePalette.secondary,
                    color: darkMode ? 'black' : 'white',
                    '&:hover': {
                        backgroundColor: darken(typePalette.secondary, .6),
                        color: 'white',
                    },
                }
            }
        },
        MuiLink: {
            root: {
                fontWeight: 500
            }
        }
    }
}

export default overrides