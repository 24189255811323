import { getLabel, roomTypes, searchTypes } from "./types"
import { omit, sortBy, isEmpty, uniqueId } from 'lodash'
import { BUILDINGS_KEY, FIND_PEOPLE_KEY, HELPFUL_LINKS_KEY, GOOGLE_SEARCH_LIMIT, QUERY_PREFIX, COLLIBRA_BUSINESS_KEY, COLLIBRA_SSRS_KEY, COLLIBRA_WORKDAY_KEY, COLLIBRA_TABLEAU_KEY } from "../util/constants";
import * as JsSearch from 'js-search';
import { searchHelpfulLinks } from "../HelpfulLinks/transform";
import { executePaginatedSearch } from "../util/search";

function formatSearchResults({ type = '', data = [] }) {
    return {
        type,
        label: getLabel(type),
        _results: data.map(o => ({ ...o, identifier: o.id })),
        results: shapeKnowledgebaseResults(data)
    }
}

function formatGoogleSearch({ type = '', data = [], queries = {} }) {
    const validateQueryPage = (page = []) => Array.isArray(page) && page.length > 0 ? page[0] : {}
    const nextPage = validateQueryPage(queries.nextPage)
    const previousPage = validateQueryPage(queries.previousPage)
    const totalResults = !isEmpty(nextPage) ? nextPage.totalResults : !isEmpty(previousPage) ? previousPage.totalResults : 0

    const convertResults = (arr = []) => arr.map((o) => ({
        identifier: uniqueId(`search-result-`),
        title: o.title,
        url: o.link,
        description: o.snippet
    }))

    const newSearch = {
        type,
        total: totalResults > GOOGLE_SEARCH_LIMIT ? GOOGLE_SEARCH_LIMIT : totalResults,
        label: getLabel(type),
        apiStartIndex: nextPage.startIndex,
        _results: convertResults(data),
        results: convertResults(data)
    }

    return newSearch
}

function shapeKnowledgebaseResults(data = []) {
    if (!Array.isArray(data) || data.length === 0) return []
    const formattedData = data.map(item => {
        const { description = '', content = '' } = item
        const formattedTitle = description
        const parsedDescription = content.startsWith(formattedTitle) ? content.substring(formattedTitle.length) : content
        let values = {
            title: formattedTitle,
            description: parsedDescription.length > 250 ? parsedDescription.substring(0, 250).trim() + '...' : parsedDescription.trim(),
            url: item.url,
            identifier: item.id
        }

        if(!!item.certified) {
            values.customIcon = {
                type: 'certified',
                alt: 'Certified'
            }
        }

        return values
    })

    return formattedData
}

function filterResults(values = { filters: [] }) {
    const { filters = [] } = values
    let filteredResults = []
    const results = omit(values, ['filters'])
    const getSortPriority = (type = '') => {
        const matchingType = searchTypes.find(o => o.type === type)
        return !!matchingType && matchingType.hasOwnProperty('sortPriority') ? matchingType.sortPriority : 99
    }

    if (!Array.isArray(filters) || filters.length === 0) {
        filteredResults = Object.values(results).filter(o => !!o.type).map(o => ({ ...o, sortPriority: getSortPriority(o.type) }))
    } else {
        for (let property in results) {
            if (filters.includes(property)) filteredResults.push({ ...results[property], sortPriority: getSortPriority(property) })
        }
    }

    return sortBy(filteredResults.filter(o => (o.status === 'loading' || Array.isArray(o.results) && o.results.length > 0)), 'sortPriority')
}

function formatFilterTypes(search = {}) {
    const { filters = [] } = search
    const types = searchTypes || []
    const list = types.map(item => {
        const matchingSearch = (item.type && search[item.type]) || {}

        return {
            title: item.label,
            id: item.type,
            sortPriority: item.sortPriority,
            disabled: !Array.isArray(matchingSearch.results) || matchingSearch.results.length === 0,
            active: Array.isArray(filters) && filters.includes(item.type),
            authenticated: item.authenticated || false
        }
    })

    return sortBy(list, 'sortPriority')
}

function filterBuildings(buildings = [], query = '') {
    if (!buildings || !query) return []
    let results = buildings.map(o => ({ ...o, searchRooms: o.rooms.map(o => o.type) }))

    let search = new JsSearch.Search('identifier')
    search.addIndex('title')
    search.addIndex('campus')
    search.addIndex('departments')
    search.addIndex('searchRooms')
    search.addDocuments(results)

    let searchResults = search.search(query)
    searchResults = searchResults.map(o => omit(o, ['searchRooms']))
    searchResults = sortBy(searchResults, 'title')

    return searchResults
}

function convertBuildingResults(buildings = [], query = '', imageData = {}) {
    const { status, data = {} } = imageData
    const { assets = {} } = data
    const type = BUILDINGS_KEY
    const imagePath = status === 'success' && assets.images ? assets.images.individualImagesBaseUrl : ''
    const results = filterBuildings(shapeBuildingResults(buildings, query, imagePath), query)

    return {
        type,
        label: getLabel(type),
        results
    }
}

function joinRoomStrings(label = '', rooms = []) {
    return rooms.filter(o => o.type === label).map(o => o.number).join(', ')
}

function generateRoomCaption(labels = [], roomTypes = [], rooms = []) {
    let caption = ''
    labels.forEach((label) => {
        if (roomTypes.includes(label) && !!joinRoomStrings(label, rooms)) {
            caption += `${label}: ${joinRoomStrings(label, rooms)} `
        }
    })

    return caption.trim()
}

function createBuildingSections(building = {}) {
    const { rooms = [], departments = [] } = building
    let sections = []
    const createStr = (key = '') => rooms.filter(o => o.type === key).map(o => o.number).join(', ')

    if (departments.length > 0) sections.push({
        title: 'Departments',
        description: departments.join(', ')
    })
    if (rooms.length > 0) roomTypes.forEach((room = {}) => {
        const { label, key } = room
        const str = createStr(key)
        if (!!str) sections.push({
            title: label,
            description: str
        })
    })

    return sections
}


function shapeBuilding(building = {}, query = '', imagePath = '') {
    const { name, latitude, longitude, itemHash, buildingNumber, buildingCode } = building
    const matchingRoomTypes = !!query && roomTypes.filter(o => !!o && !!o.label && o.label.toLowerCase().includes(query))
    const roomKeys = roomTypes.map(o => o.key)

    let preview = ''
    if (matchingRoomTypes.length > 0) {
        preview = generateRoomCaption(roomKeys, matchingRoomTypes.map(o => o.label), building.rooms)
    }
    const buildingNumberStr = buildingNumber ? `${buildingNumber}${buildingCode ? ` - ${buildingCode}` : ''}` : ''

    return {
        ...omit(building, ['name', 'itemHash']),
        title: name + ` (${buildingNumberStr})`,
        sections: createBuildingSections(building),
        image: buildingNumber ? {
            src: `${!!imagePath ? imagePath : ''}building-image-${buildingNumber}.jpg`,
            alt: `Image of building ${name}`,
            fallbackType: 'building'
        } : {},
        preview,
        identifier: itemHash,
        type: BUILDINGS_KEY,
        url: encodeURI(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`)
    }
}

function shapeBuildingResults(buildings = [], query = '', imagePath = '') {
    return buildings.map(o => shapeBuilding(o, query, imagePath))
}

function transformContact(obj = {}) {
    const { displayName = '', phone = {}, email = '', affiliations, username, firstName, lastName } = obj
    let appointments = Array.isArray(obj.appointments) ? obj.appointments.filter(o => !!o.workingTitle) : []
    let phoneValues = {}
    if (!!phone) {
        const { area_code, exchange, subscriber, formatted } = phone
        const phoneParts = [area_code, exchange, subscriber]
        phoneValues = formatted ? {
            href: phoneParts.length === 3 && `tel:+1-${phoneParts.filter(val => !!val).join('-')}`,
            display: formatted
        } : {}
    }

    const shapedContact = {
        type: FIND_PEOPLE_KEY,
        name: displayName,
        title: `${displayName}${username ? ` (${username})` : ''}`,
        affiliations: Array.isArray(affiliations) ? affiliations.join(', ') : '',
        jobTitles: Array.isArray(appointments) ? appointments.map(o => o.workingTitle).join(', ') : '',
        email: email ? {
            href: `mailto:${email}`,
            display: email
        } : {},
        phone: phoneValues,
        internal: true,
        url: encodeURI(`search/people/${QUERY_PREFIX}${firstName || ''} ${lastName || ''}`)
    }

    return shapedContact
}

function searchGlobalHelpfulLinks(list = [], query = '', formatResults = true) {
    const formattedList = !formatResults ? list : Array.isArray(list) ? list.map(o => {
        const newItem = { ...o, identifier: `${o.id}`, description: o.siteDescription }
        return omit(newItem, ['id', 'siteDescription'])
    }) : []

    const searchResults = searchHelpfulLinks({ query, documents: formattedList })

    return {
        _results: formattedList,
        results: searchResults,
        type: HELPFUL_LINKS_KEY,
        total: searchResults.length,
        label: getLabel(HELPFUL_LINKS_KEY)
    }
}

function formatPaginatedSearchResponse (updating = false, type, response = {}) {
    return {
        updating,
        total: response.total,
        ...formatSearchResults({
            type,
            data: response.data || []
        })
    }
}

async function searchPaginatedContent({ query = '', key = '', path = '', from, to, publicEndpoint = false, authenticated = false, updating = false }) {
    try {
        let options = { from, to }
        if(authenticated) {
            options.authenticated = true
        }
        if(publicEndpoint) {
            options.publicEndpoint = true
        }
        const response = await executePaginatedSearch(query, path, options)

        let result = {}
        if (!!response.data && response.data.length > 0) {
            result = formatPaginatedSearchResponse(updating, key, response)
        } else {
            throw `No data found for ${key} in search paginated content`
        }
        return result

    } catch (error) {
        throw error
    }
}

export { formatSearchResults, shapeKnowledgebaseResults, filterResults, formatFilterTypes, filterBuildings, shapeBuilding, convertBuildingResults, shapeBuildingResults, transformContact, searchGlobalHelpfulLinks, formatGoogleSearch, formatPaginatedSearchResponse, searchPaginatedContent }