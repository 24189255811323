import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../../App/context'
import { Image } from '@material-ui/icons'
import { omit } from 'lodash'

function CustomIcon(props = {}) {
    const { type = '', active = false } = props
    const { darkMode } = useContext(AppContext) || {}
    let passableProps = omit(props, ['type', 'active'])
    if(!passableProps.alt && !props.hasOwnProperty('aria-hidden')) {
        passableProps['aria-hidden'] = true
        passableProps['role'] = 'presentation'
    }
    if (props.src) {
        return <img {...passableProps} alt={passableProps.alt || ""} />
    }

    const renderIcon = (iconName) => {
        return <img src={require(`../assets/${iconName}-${active ? 'Active' : 'Inactive'}${darkMode ? '-Dark' : ''}.svg`)} {...passableProps} alt={passableProps.alt || ""} />
    }
    const renderBrutus = (type = 'Normal') => {
        return  <img src={require(`../assets/Brutus/${type}.svg`)} {...passableProps} alt={passableProps.alt || ""}  />
    }

    switch(type) {
        case 'news':
            return renderIcon('For-You')
        case 'parking':
            return renderIcon('Parking')
        case 'campus':
            return renderIcon('Campus')
        case 'success':
            return renderBrutus('Happy')
        case 'masked':
            return renderBrutus('Masked')
        case 'error':
            return renderBrutus('Surprise')
        case 'loading':
            return renderBrutus('Thinking')
        case 'certified':
            return  <img src={require(`../assets/${type}.svg`)} {...passableProps} alt={passableProps.alt || ""}  />
        default:
            return <Image {...passableProps} />
    }
}

CustomIcon.propTypes = {
    type: PropTypes.oneOf(['news', 'parking', 'campus', 'success', 'masked', 'error', 'loading']),
    active: PropTypes.bool,
}

export default CustomIcon