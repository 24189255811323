import { fade, makeStyles } from '@material-ui/core/styles';
import { standardColor } from '../../styles/palette';
import { STYLESHEET_LENGTH } from '../../util/constants';

const useStyles = makeStyles(theme => ({
    bottomNav: {
        height: 'var(--mobile-nav-height)'
    },
    regModeAvatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    darkModeAvatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black
    },
    links: {
        marginRight: theme.spacing(3),
        fontWeight: theme.typography.fontWeightMedium
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    icon: {
        width: theme.spacing(4),
        height: '100%',
    },
    brandIcon: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    loading: {
        padding: theme.spacing()
    },
    searchResultsContainer: {
        position: 'absolute',
        transition: theme.transitions.create('height'),
        width: '100%',
        marginTop: theme.spacing(),
        [theme.breakpoints.up('sm')]: {
            width: '150%',
            left: '-50%'
        },
        [theme.breakpoints.up('md')]: {
            width: '200%',
            left: '-100%'
        }
    },
    searchHistoryBtnRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    searchHistoryBtnLabel: {
        justifyContent: 'flex-start'
    },
    searchHistoryBtnLabelText: {
        textTransform: 'none',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    bottomNavAction: ({tabCount}) => ({
        minWidth: `${(100/tabCount)}%`,
        padding: '0px',
        '&.$Mui-selected': {
            paddingTop: '0px',
            borderBottom: `solid .25em ${theme.palette.primary.main}`
        }
    }),
    labelOnly: {
        border: 'none'
    },
    labelBorder: {
        borderRadius: 0,
        borderBottom: `solid .25rem ${theme.palette.primary.main}`
    },
    toolbar: {
        borderBottom: `solid 3px ${standardColor.red}`
    },
    searchPreviewBox: {
        maxHeight: '50vh',
        overflow: 'scroll',
        margin: `.75rem 0`
    }
}), { index: STYLESHEET_LENGTH });

export default useStyles