import { HELPFUL_LINKS, TOGGLE_DEPARTMENT, SEARCH_HELPFUL_LINKS, HELPFUL_LINKS_QUERY } from "../actions-index";
import { filterHelpfulLinks } from "./transform";
import { transformFilters, checkFiltersForActiveId } from "../Filters/transform";

export function helpfulLinks(state = {}, action = {}) {
    const { departments = [], _results = [] } = state
    const { payload } = action

    switch (action.type) {
        case HELPFUL_LINKS_QUERY:
            return {
                ...state,
                query: action.payload
            }
        case HELPFUL_LINKS:
            return {
                ...state,
                ...payload,
                status: !!payload && !!payload.status ? payload.status : 'success'
            }
        case SEARCH_HELPFUL_LINKS: {
            const updatedResults = filterHelpfulLinks(action.payload, state._results)
            const generateDepartmentProps = (id) => {
                const match = updatedResults.find(o => o.identifier === id) || {}
                const links = match.hasOwnProperty('links') && Array.isArray(match.links) ? match.links : []
                return {
                    title: `${match.title} (${links.length} Result${links.length === 1 ? '' : 's'})`,
                    disabled: links.length === 0
                }
            }
            return {
                ...state,
                query: action.payload,
                departments: Array.isArray(departments) ? departments.map(o => ({
                    ...o,
                    ...generateDepartmentProps(o.id)
                })) : [],
                results: updatedResults
            }
        }
        case TOGGLE_DEPARTMENT:
            let newFilters = payload && Array.isArray(departments) && departments.length > 0
                ? departments
                : transformFilters(_results)
            const updatedFilters = newFilters.map(o => ({ ...o, active: payload === o.id ? !o.active : o.active }))
            const updatedResults = _results.filter(o => {
                return !payload || checkFiltersForActiveId(updatedFilters, o.identifier)
            })

            return {
                ...state,
                departments: updatedFilters,
                results: updatedResults
            }
        default:
            return state
    }
}