import Cookies from 'js-cookie';

const TEMPERATURE_SETTING = 'TEMPERATURE_SETTING'

export const TemperatureOptions = {
    get: () => Cookies.get(TEMPERATURE_SETTING),
    set: (value, options) => Cookies.set(TEMPERATURE_SETTING, value, options),
    remove: () => Cookies.remove(TEMPERATURE_SETTING)
}

const redirectKey = 'REDIRECT_PATHNAME'

const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);

export const RedirectOptions = {
    get: () => Cookies.get(redirectKey),
    set: (value, options) => Cookies.set(redirectKey, value, Object.assign({ expires: inFiveMinutes }, options)),
    remove: () => Cookies.remove(redirectKey)
}

export default Cookies