import React, { lazy } from 'react';

const NewsTab = lazy(() => import('../../MobileTabs/components/NewsTab'))
const Home = lazy(() => import('../../Home/components'))
const AboutYouTab = lazy(() => import('../../MobileTabs/components/AboutYouTab'))
const ParkingTab = lazy(() => import('../../MobileTabs/components/ParkingTab'))
const BusesTab = lazy(() => import('../../MobileTabs/components/BusesTab'))
const ContentFeed = lazy(() => import('../../ContentFeed/containers'))
const Article = lazy(() => import('../../Article/containers'))
const HelpfulLinks = lazy(() => import('../../HelpfulLinks/containers'))
const Feedback = lazy(() => import('../../Feedback/containers'))
const Food = lazy(() => import('../../Food/components'))
const Search = lazy(() => import('../../Search/containers'))
const Announcements = lazy(() => import('../../Announcements/containers/'))

export const constructLazyPaths = ({ mobile, showTemperatureTracker }) => [
    {
        title: "Home",
        path: '/',
        ...mobile ? {
            LazyComponent: NewsTab
        } : {
            LazyComponent: Home,
            props: {
                showTemperatureTracker
            }
        }
    },
    {
        title: 'Home',
        path: '/about-you',
        LazyComponent: AboutYouTab,
        props: {
           showTemperatureTracker
        }
    },
    {
        title: 'Parking',
        path: '/parking',
        props: {
            mobile
        },
        LazyComponent: ParkingTab
    },
    {
        title: 'Buses',
        path: '/buses',
        props: {
            mobile
        },
        LazyComponent: BusesTab
    },
    {
        title: 'Campus',
        path: '/campus',
        props: {
            mobile
        },
        LazyComponent: BusesTab
    },
    {
        title: 'News',
        path: '/news',
        LazyComponent: ContentFeed
    },
    {
        title: 'News',
        path: '/news/article/:id',
        LazyComponent: Article
    },
    {
        title: 'Search Helpful Links',
        path: '/helpful-links',
        LazyComponent: HelpfulLinks
    },
    {
        title: 'Search Compass',
        path: '/search/:type?',
        LazyComponent: Search,
        noIndex: true
    },
    {
        title: 'Send Feedback',
        path: '/feedback',
        LazyComponent: Feedback
    },
    {
        title: 'Food',
        path: '/food/:type?/:id?',
        props: {
            fullview: true,
            mobile
        },
        LazyComponent: (props) => <Food displayCount={mobile && !props.match.params.type ? 5 : null} {...props}/>
    },
    {
        title: 'All Announcements',
        path: '/announcements',
        props: {
            showList: true
        },
        LazyComponent: Announcements,
        noIndex: true,
        authRequired: true
    }
]