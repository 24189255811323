import { generateAction } from "../redux-helpers"
import { POST_TEMPERATURE, TEMPERATURE_SUBMISSIONS, TEMPERATURE_STATUS, TEMPERATURE_INFO, TEMPERATURE_FORM_QUESTIONS, authSignOut } from "../actions-index"
import { transformSymptomTracking } from "./transform"
import { isEmpty, keyBy, mapValues } from 'lodash'
import { API } from "aws-amplify"
import ReactGA from 'react-ga';

const fallbackQuestions = require('./healthReportingForm.json')

export function postTemperature(values = {}) {
    return async (dispatch, getState) => {
        const { temperatureTracker } = getState() || {}
        const { formFields = { status: '', temperature: null, questions: [] } } = temperatureTracker
        let formQuestions = formFields.questions 
        let formTemperature = formFields.temperature

        if(isEmpty(values)) {
            dispatch(generateAction(POST_TEMPERATURE, { status: 'cleared' }))
            return
        }
        if(isEmpty(formTemperature)) {
            formTemperature = fallbackQuestions.temperature
        }
        if(!Array.isArray(formQuestions) || formQuestions.length === 0) {
            formQuestions = fallbackQuestions.questions
        }

        const setStatus = (status = '', postFields = {}) => {
            dispatch(generateAction(TEMPERATURE_SUBMISSIONS, { status }))
            dispatch(generateAction(TEMPERATURE_STATUS, { status }))
            dispatch(generateAction(POST_TEMPERATURE, { ...postFields, status }))
        }

        setStatus('loading')

        const { temperature, questions } = values

        let body = {}, errors = []
        
        if(!isEmpty(formTemperature)) {
            const { required, minimumValue, maximumValue, id } = formTemperature
            const response = parseFloat(temperature)
            body[id] = response

            const validTemp = !isNaN(body[id]) && (body[id] >= minimumValue && body[id] <= maximumValue)
            if(!!response ? !validTemp : required) {
                errors.push(id)
            }
        }
        formQuestions.forEach(question => {
            const { identifier, required, choices = [] } = question
            const response = questions[identifier]

            body[identifier] = response

            const validQuestion = choices.map(choice => choice.value).includes(response)
            if(!!response ? !validQuestion : required) {
                errors.push(identifier)
            }
        })

        let overallValid = errors.length === 0

        if(overallValid) {
            let response = {}
            try {
                response = await API.post(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/health-reporting`, { body })
                if(response.data) {
                    const symptomTracker = transformSymptomTracking(response.data)
        
                    dispatch(generateAction(TEMPERATURE_SUBMISSIONS, { updating: true, list: symptomTracker.submissions }))
                    dispatch(generateAction(TEMPERATURE_STATUS, symptomTracker.alert))
                    
                    return
                } 
            } catch (error) {
                const errorCode = error.response && error.response.status
                ReactGA.exception({
                    description: `Error reached in postTemperature function. Status: ${error === 'No current user' ? error : errorCode}`,
                    fatal: false
                });
                if(error === 'No current user' || (errorCode === 401)) {
                    dispatch(authSignOut())
                    return
                }
                
                let postFields = {}
                if(error.response && error.response.data && error.response.data.error) {
                    const errorResponse = error.response.data.error
                    const keyedErrors = keyBy(errorResponse.fields, 'id')
                    const stringifiedErrors = mapValues(keyedErrors, 'message');

                    postFields = {
                        error: {
                            message: errorResponse.message,
                            fields: stringifiedErrors,
                            existingForm: values
                        },
                    }
                }

                setStatus('error', postFields)
                return
            }
        } else {
            let messageFields = {}
            errors.forEach(err => {
                messageFields[err] = ''
            })
            setStatus('error', {
                error: { 
                    existingForm: values,
                    fields: messageFields
                }
            })
        }
    }
}

export function getSymptomTracker() {
    return async (dispatch) => {
        let response = {}
        const setStatus = (status = '') => {
            dispatch(generateAction(TEMPERATURE_SUBMISSIONS, { status }))
            dispatch(generateAction(TEMPERATURE_STATUS, { status }))
        }
        setStatus('loading')

        try {
            response = await API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/health-reporting`)
        } catch (error) {
            const errorCode = error.response && error.response.status
            ReactGA.exception({
                description: `Error reached in getSymptomTracker function. Status: ${error === 'No current user' ? error : errorCode}`,
                fatal: false
            });
            if(error === 'No current user' || (errorCode === 401)) {
                dispatch(authSignOut())
                return
            }

            setStatus('error')
            return
        }
        
        const symptomTracker = transformSymptomTracking(response.data)

        dispatch(generateAction(TEMPERATURE_SUBMISSIONS, { list: symptomTracker.submissions }))
        dispatch(generateAction(TEMPERATURE_STATUS, symptomTracker.alert))
        dispatch(generateAction(TEMPERATURE_INFO, response.data.introText))
    }
}

export function getHealthQuestions() {
    return async (dispatch) => {
        const dispatchResponse = (payload) => dispatch(generateAction(TEMPERATURE_FORM_QUESTIONS, payload))
        dispatchResponse({ status: 'loading' })

        let payload = null

        try {
            let jsonResponse
            const response = await fetch(process.env.REACT_APP_JSON_DOC_LINKS + `/healthReportingForm.json`)
            if (!!response && !!response.ok) {
                jsonResponse = await response.json()
                if(!!jsonResponse.questions) {
                    payload = jsonResponse
                }
            } 
            if(!payload) {
                throw Error ('Unexpected response from health questions')
            }
        } catch (error) {
            console.error(error)
            payload = fallbackQuestions
        }
        dispatch(generateAction(TEMPERATURE_FORM_QUESTIONS, payload))
    }
}