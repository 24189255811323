import { Auth } from 'aws-amplify';
import {HOUR_INTERVAL_RANGE , HOURS_IN_A_DAY, FEET_IN_A_MILE, QUERY_PREFIX, EXTERNAL_LINK_DESCRIPTOR} from './constants';
import { round } from 'lodash'
import { RedirectOptions } from './cookies'
import { Link as RouterLink } from 'react-router-dom';
import { OutboundLink } from './analytics';

export function redirectToLogIn(redirectPath = '') {
    const{
        oauth
    } = Auth.configure();

    
    if(!!redirectPath || !!window.location.pathname) {
        let path = redirectPath

        if(!path) {
            path = window.location.pathname
            if(!!window.location.search) {
                path += window.location.search
            }
        }
        RedirectOptions.remove()
        RedirectOptions.set(path)
    }

    Auth.federatedSignIn({customProvider: oauth.idp});
}

export const createHourInterval = (overrideRange) => {
    const range = overrideRange || HOUR_INTERVAL_RANGE;
    const currentHour = (new Date()).getHours();
    const beginning = currentHour - range > 0 ? currentHour - range : 0;
    const end = currentHour + range < HOURS_IN_A_DAY ? currentHour + range : HOURS_IN_A_DAY

    return [beginning, end];
}

export const convertFeetToMiles = (distanceInFeet) => {
    return (distanceInFeet/FEET_IN_A_MILE).toFixed(2)
  }

export const encodeGoogleMapUrl = (latitude, longitude) => {
    if(!latitude || !longitude) return

    return encodeURI(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`)
}

export function getAccessibleTextColor(hex = ''){
    const formattedHex = hex.charAt(0) === "#" ? hex.substring(1,7) : hex
		
    /*
    Based on codepen: https://codepen.io/davidhalford/pen/ywEva
    Quoting from this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
    
    Color brightness is determined by the following formula: 
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000    
    */
    
   const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */
    const getHex = (color) => {
        const getColor = (start, end) => parseInt(formattedHex.substring(start, end), 16)
        
        if(color === 'red') return getColor(0, 2) * 299
        if(color === 'green') return getColor(2, 4) * 587
        if(color === 'blue') return getColor(4, 6) * 114
    }

    const colorBrightness = ((getHex('red')) + (getHex('green')) + (getHex('blue'))) / 1000;
    if (colorBrightness > threshold){
          return "#000000"
    }
    
    return "#ffffff"
  }

  export function fahrenheitToCelsius(temperature) {
    let num = parseFloat(temperature);
    num = (num - 32)/1.8

    return round(num, 2)
  }

  export function celsiusToFahrenheit(temperature) {
    let num = parseFloat(temperature);
    num = (num * 1.8) + 32
    
    return round(num, 2)
  }

 export function parseLocationQueryString(searchPath = '') {
    const queryString = searchPath.startsWith(QUERY_PREFIX) ? decodeURI(searchPath.substring(QUERY_PREFIX.length)).trim() : ''
    if (queryString == '') return null
    return queryString
}

export function internalLinkProps(url) {
    return {
        component: RouterLink,
        to: url,
        ...window.location.pathname === url ? { 'aria-current': 'page' } : {}
    }
}

export function externalLinkProps(url, eventLabel) {
    let props = {
        href: url,
        target: '_blank',
        rel: 'noopener',
        'aria-describedby': EXTERNAL_LINK_DESCRIPTOR
    }

    if(eventLabel) {
        props.to = url
        props.role = undefined
        props.eventLabel = eventLabel
        props.component = OutboundLink
    } else {
        props.href = url
    }

    return props
}