import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField } from '@material-ui/core/';
import useStyles from '../styles/theme';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import useDebounce from '../../hooks/useDebounce';
import { parseLocationQueryString } from '../../util/util';

function SearchInputBase(props) {
    const location = useLocation();
    const { small, query = '', handleChange, onKeyUp, onKeyPress } = props
    const classes = useStyles({ small });
    const defaultInputValue = location.pathname && location.pathname.includes('search') ? parseLocationQueryString(encodeURI(location.search)) || query : ''
    const [inputValue, setInputValue] = useState(defaultInputValue)
    const typingInterval = 500
    const debouncedValue = useDebounce(inputValue, typingInterval)

    useEffect(() => {
        setInputValue(q => query !== q ? query : q)
    }, [query])

    useEffect(() => {
        query !== debouncedValue && handleChange(debouncedValue)
    }, [debouncedValue])

    function handleOnKeyUp(event) {
        onKeyUp && onKeyUp(event)
    }

    function handleOnKeyPress(event) {
        const q = event.target.value
        if (event.key === 'Enter') {
            handleChange(q)
        }
        onKeyPress && onKeyPress(event)
    }

    return <TextField
        className={`w-100${small ? '' : ` m-2`}`}
        classes={{
            root: classes.inputWrapper,
        }}
        value={inputValue}
        onKeyUp={handleOnKeyUp}
        onKeyPress={handleOnKeyPress}
        onChange={(event = { target: { value: "" } }) => setInputValue(event.target.value)}
        autoComplete="off"
        {...omit(props, ['small', 'onKeyUp', 'onKeyPress', 'query', 'pathname', 'enterHandler', 'handleChange'])}
        InputProps={{
            disableUnderline: small,
            className: small ? 'mb-2' : '',
            classes: {
                input: `${classes.inputInput} ${small ? classes.inputSm : ''}`
            }
        }}
        InputLabelProps={{
            color: small ? 'secondary' : 'primary'
        }}
    />
}

SearchInputBase.defaultProps = {
    small: false,
    query: '',
    handleChange: () => {},
    inputProps: {}
}

SearchInputBase.propTypes = {
    small: PropTypes.bool,
    handleChange: PropTypes.func,
    query: PropTypes.string,
    enterHandler: PropTypes.func
}

export default SearchInputBase


