import { FEEDBACK_CONFIRMATION } from "../actions-index";

export function feedback(state = {}, action = {}) {
    const { type = '', payload } = action
    if (type === FEEDBACK_CONFIRMATION) {
        return {
            ...payload === 'cleared' ? {} : state,
            status: payload
        }
    }
    return state
}