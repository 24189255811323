import { DINING_LOCATIONS, DINING_LOCATION_STATUS, DINING_MENU, DINING_FILTERS, TOGGLE_DINING_FILTER, UPDATE_ACTIVE_MENU, FOOD_TRUCKS, SEARCH_FOOD_TRUCKS, SEARCH_DINING_LOCATIONS } from "../actions-index";

export function food(state = {}, action = {}) {
    const { payload } = action

    switch (action.type) {
        case DINING_LOCATIONS:
            return {
                ...state,
                campusDining: {
                    ...state.campusDining,
                    status: 'success',
                    locations: payload
                }
            }
        case FOOD_TRUCKS:
            return {
                ...state,
                foodTrucks: {
                    ...state.foodTrucks,
                    status: 'success',
                    ...payload
                }
            }
        case DINING_LOCATION_STATUS:
            return {
                ...state,
                campusDining: {
                    ...state.campusDining,
                    ...payload
                }
            }
        case DINING_MENU:
            const { cachedMenus = [] } = state
            return {
                ...state,
                activeMenu: {
                    status: 'success',
                    ...payload
                },
                cachedMenus: payload.locationId ? Array.isArray(cachedMenus) ? [...cachedMenus.filter(o => o.locationId !== payload.locationId), payload] : [payload] : cachedMenus
            }
        case UPDATE_ACTIVE_MENU:
            return {
                ...state,
                activeMenu: {
                    status: 'success',
                    ...payload
                }
            }
        case DINING_FILTERS:
            return {
                ...state,
                filters: {
                    status: 'success',
                    ...action.payload
                }
            }
        case TOGGLE_DINING_FILTER:
            const { filters, filteredMenus } = action.payload || {}

            return {
                ...state,
                activeMenu: {
                    ...state.activeMenu,
                    filteredMenus
                },
                filters: {
                    ...state.filters,
                    list: filters
                }
            }
        case SEARCH_FOOD_TRUCKS:
            return {
                ...state,
                foodTrucks: {
                    ...state.foodTrucks,
                    ...action.payload
                }
            }
        case SEARCH_DINING_LOCATIONS:
            return {
                ...state,
                campusDining: {
                    ...state.campusDining,
                    locations: {
                        ...state.campusDining.locations,
                        ...payload.locations
                    },
                    query: action.payload.query
                }
            }
        default:
            return state
    }
}