import React from 'react';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useStyles from '../styles/theme';
import PropTypes from 'prop-types';
import { externalLinkProps } from '../../util/util';

function NavLinks(props) {
    const { links, className } = props
    const { pathname } = useLocation() || {}
    const classes = useStyles();

    const internalLinkProps = (url) => ({
        component: RouterLink,
        to: url,
        ...pathname === url ? { 'aria-current': 'page' } : {}
    })

    const createLink = (item, index) => {
        const { internal = false } = item
        return <Link color="secondary" variant="body1" className={`${classes.links} ${className}`.trim()} key={index} {...internal ? internalLinkProps(item.url) : externalLinkProps(item.url, `Primary Navigation - ${item.name}`)}>
            {item.name}
        </Link>
    }
    
    return Array.isArray(links)
        ? links.map((item, index) => createLink(item, index))
        : []
}

NavLinks.defaultProps = {
    className: ''
}

NavLinks.propTypes = {
    className: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                pathname: PropTypes.string.isRequired,
                search: PropTypes.string,
                hash: PropTypes.string,
                state: PropTypes.object
            })
        ]).isRequired,
        internal: PropTypes.bool
    }))
}

export default NavLinks