import { ANNOUNCEMENTS_STATUS, ANNOUNCEMENTS, ANNOUNCEMENTS_DISMISS, authSignOut } from "../actions-index";
import { API } from 'aws-amplify';
import { constructAnnouncements } from './transform'
import { generateAction } from "../redux-helpers";

export function getAnnouncements() {
    const announcementsStatus = (status) => generateAction(ANNOUNCEMENTS_STATUS, status)

    return async dispatch => {
        dispatch(announcementsStatus('loading'));
        let payload
        let response
        let doneFetching = false;
        try {
            response = await fetchAnnouncements({ allowAll: true, dispatch })
            if (Array.isArray(response)) {
                payload = constructAnnouncements(response);
                dispatch(generateAction(ANNOUNCEMENTS, { _list: payload }))
            } else if (!Array.isArray(response)) {
                doneFetching = true;
                let payload;
                if (Array.isArray(response.previous)) {
                    payload = constructAnnouncements(response.previous);
                    dispatch(generateAction(ANNOUNCEMENTS, { _list: payload }));
                }
                if (Array.isArray(response.current)) {
                    payload = constructAnnouncements(response.current);
                    dispatch(generateAction(ANNOUNCEMENTS, { list: payload }));
                } 
                    // payload = [...prev_payload, ...curr_payload];
                    // dispatch(generateAction(ANNOUNCEMENTS, { list: payload }));
                // }
            } else {
                dispatch(announcementsStatus('error'));
            }
        } catch (error) {
            console.error(error)
            dispatch(announcementsStatus('error'));
        }
        if (!doneFetching) {
            try {
                const response = await fetchAnnouncements({ dispatch })
                if (Array.isArray(response)) {
                    payload = constructAnnouncements(response)
                    dispatch(generateAction(ANNOUNCEMENTS, { list: payload }))
                } else {
                    dispatch(announcementsStatus('error'));
                }
            } catch (error) {
                console.error(error)
                dispatch(announcementsStatus('error'));
            }
        }
    }
}

async function fetchAnnouncements({ allowAll = false, dispatch }) {
    let response = null
    try {
        response = API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/announcements${allowAll ? '/all' : ''}?source=COMPASS`);
    } catch (error) {
        console.error(error)
        if(error.response && error.response.status  && error.response.status === 401) {
            dispatch(authSignOut())
        }
    }
    return response
}


export function dismissAnnouncement(id) {

    const announcementsStatus = (status) => generateAction(ANNOUNCEMENTS_DISMISS, { id, status });
    console.log("dismissAnnouncement id ", id);
    let params = {
        body: { id: id }, // replace this with attributes you need
        headers: {} // OPTIONAL
    }

    return (dispatch) => {
        dispatch(announcementsStatus('loading'));
        API.post(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/announcements/dismiss/`, params)
            .then(response => {
                if (Array.isArray(response)) {
                    dispatch(generateAction(ANNOUNCEMENTS, { list: constructAnnouncements(response) }));
                } else {
                    dispatch(announcementsStatus('error'));
                }
            })
            .catch(error => {
                console.log(error.response)
            })

    }

}