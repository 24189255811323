import {
    MOBILE_GO_LINKS
} from '../actions-index';
import { generateAction } from '../redux-helpers';
import { transformLinks } from './transform';

export function getMobileGoLinks() {
    return async dispatch => {
        const dispatchResponse = (type, response) => dispatch(generateAction(type, response))
        dispatchResponse(MOBILE_GO_LINKS, { status: 'loading' })
        let links
        try {
            links = await fetchMobileGoLinks()
        } catch (error) {
            console.log('Error fetching mobile go links', error) 
            dispatchResponse(MOBILE_GO_LINKS, { status: 'error' })   
        }

        !!links && dispatchResponse(MOBILE_GO_LINKS, { links })
    }
}

async function fetchMobileGoLinks() {
    let payload = {}
    const response = await fetch(process.env.REACT_APP_JSON_DOC_LINKS + `/mobileGoLinks.json`)

    if (!!response) {
        const result = await response.json()
        payload = transformLinks(result)
    }

    return payload
}