import {
    MOBILE_GO_LINKS
} from '../actions-index';

export function mobileGoLinks(state = {}, action = {}) {
    switch (action.type) {
        case MOBILE_GO_LINKS:
            return {
                ...state,
                status: 'success',
                ...action.payload
            }
        default:
            return state
    }
}