import { useSelector } from "react-redux";

function useAuthentication() {
    const authentication = useSelector(state => state.authentication);

    return { 
        authentication
    }
}

export default useAuthentication