import {isEmpty} from 'lodash';
import { AM, PM } from '../util/constants';

function constructLocation (response){
  if(!response.errors.length) {
    return JSON.parse(response.data).availableIds;
  } else {
    return new Error(JSON.stringify(response.errors));
  }
}

function transformLocation(parkingLocations = []){
  let results = {lots: [], garages: []};
  for(let location of parkingLocations){
    (location.style === 'lot') ? results.lots.push(location) : results.garages.push(location)
  }
  return results;
}

function constructParking (response, style){
  if(!response.errors.length){
    let {lastUpdated, totalNumberOfIntervals, statuses} = JSON.parse(response.data);
    let {id, name, current, average} = (statuses && statuses[0]) || {}
    if(!id){
      return {error: `No status available for garage`};
    }

    let data = {
      lastUpdated,
      totalNumberOfIntervals,
      id,
      name,
      current,
      average,
    }
    return { data , style };
  } else {
    return new Error(JSON.stringify(response.errors));
  }
}

const transform24HourTime = (hour) => { 
  let modHour = hour % 12 || 12;
  let meridian = (hour > 11 && hour !== 24) ? PM : AM;
  return {
    hour: modHour,
    meridian
  };
}

const convertToTimezone = (data) => {
  let result = { current: {}, average: [] };

  const convertStatus = (status) => {
      let date = new Date(new Date(0, 0, status.day, status.hour));
      const tzo = new Date().getTimezoneOffset() / 60;

      date.setHours(date.getHours() - tzo);

      return {
          ...status,
          day: date.getDay(),
          hour: date.getHours(),
      };
  }

  if (data && data.current) { // current data
      result.current = convertStatus(data.current);
  }

  if (data && data.average) { // average data
      result.average = [...Array(data.average.length)];
      for (let i in data.average) {
          result.average[i] = convertStatus(data.average[i]);
      }
  }

  return { ...data, ...result };
}

const constructHistogramData = (rawData, day, hourInterval, darkMode, createPercentage) => {
  createPercentage = createPercentage || ((status) => status.percentage)
  let data = [];
  const START = 0, END = 1;

  if (rawData) {
      rawData = convertToTimezone(rawData);
      const current = rawData.current ? rawData.current : {};
      const average = rawData.average ? rawData.average : [];

      let totalNumberOfIntervals = rawData.totalNumberOfIntervals;



      // generate average (static) data
      let dayAverage = average.filter((g) => g.day === day);
      let hourAverage = hourInterval && dayAverage.filter((g) => g.hour >= hourInterval[START] && g.hour < hourInterval[END]);
      let filteredAverage = hourAverage || dayAverage;

      // data array with initial values
      data = [...Array(filteredAverage.length)];
      for (let i in data) {
          data[i] = { percentage: 0 };
      }

      const createAria = (hour, min, meridian) => (p) => `${createPercentage({ percentage: p })} full at ${hour}${min ? ':' + min : ''}${meridian}m`;

      for (let stat of filteredAverage) {
          let offset = (hourInterval && hourInterval[START]) || 0;
          let index = (stat.hour - offset) * totalNumberOfIntervals + stat.interval;
          data[index].percentage = createPercentage(stat);

          // labels
          data[index].axisLabelAlignment = 'start';
          
          let {hour, meridian} = transform24HourTime(stat.hour);
          let min = 60 / totalNumberOfIntervals * stat.interval;

          if (stat.interval === 0) {
              data[index].axisLabel = hour + meridian;
          }

          // aria labels
          data[index].ariaTitle = createAria(hour, min, meridian)
          
          //staticColor
          // data[index].staticColor = darkMode ? "#9db8db" : "#0f4fc9"
      }
      
      // generate current (active) data
      if (!isEmpty(current)) {
          let offset = (hourInterval && hourInterval[START]) || 0;
          let index = (current.hour - offset) * totalNumberOfIntervals + current.interval;

          let currentHourInInterval = hourInterval && current.hour >= hourInterval[START] && current.hour < hourInterval[END];

          if (current.day === day && currentHourInInterval) {
              data[index].activePercentage = createPercentage(current);
              data[index].activePulse = true;

              data[index].ariaTitle = (p) => `${createPercentage({ percentage: p })} full currently`;
          } else if(currentHourInInterval){
              data[index].activePercentage = data[index].percentage;
              data[index].activeColor = 'var(--palette-primary-dark)';

              data[index].ariaTitle = (p) => `${createPercentage({ percentage: p })} full`;
          }
      }
  }

  return data;
  
}


export { transform24HourTime, constructLocation, constructParking, transformLocation, constructHistogramData };