import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ResultFull from './ResultFull';
import ResultPreview from './ResultPreview';
import { Typography, Paper, Button, Divider } from '@material-ui/core';
import QuickLinks from '../../Home/components/QuickLinks'
import { AppContext } from '../../App/context';

class Results extends Component {
    constructor(props) {
        super(props);
        this.expandSearch = this.expandSearch.bind(this);
    }

    componentDidMount() {
        if (this.props.callHelpfulLinks) {
            this.props.getHelpfulLinks()
        }
    }

    expandSearch(values = {}) {
        this.props.executeSearch({ ...values, updating: true })
    }

    render() {
        const { loading, results, filters, constructWrapper, preview, show, query, individual, match = {} } = this.props
        const { elevation = { standard: 2 } } = this.context || {}
        
        function filterResults(comparisonFilters = []) {
            const validateArr = (arr = []) => !!Array.isArray(arr) && arr.length > 0
            if (!validateArr(comparisonFilters)) return results

            return results.filter(res => !!res.type && comparisonFilters.includes(res.type))
        }
        const individualFilterType = match && match.params && match.params.type
        const verifiedResults = filterResults(individual ? [individualFilterType] : filters)
        const searchStrings = ["Benjamin Hancock", "Mount Hall Lactation Room", "How to setup wifi", "Buckeyelearn Training"]
        const fullView = (count, result = {}) => <ResultFull loading={loading || result.status === 'loading'} expandSearch={(values) => this.expandSearch(values)} result={result} count={count} show={show} query={query} />
        const partialView = (count, result = {}) => <ResultPreview divider={count < (verifiedResults.length - 1)} loading={loading || result.status === 'loading'} result={result} count={count} show={show}/>
        let renderableResults = verifiedResults.map((result, index) => {
            const standardJsx = constructWrapper
                ? constructWrapper({
                    children: fullView(index, result),
                    identifier: `wrapped${index}`
                }) : fullView(index, result)

            return <Fragment key={`results${index}`}>
                {preview ? partialView(index, result) : standardJsx}
            </Fragment>
        })

        if (Array.isArray(renderableResults) && renderableResults.length > 0) {
            return <Fragment>
                {(!preview && !individual) && <QuickLinks className="mb-2" />}
                {preview && <Typography variant="srOnly" aria-live="polite" aria-atomic="true">
                    {`${renderableResults.length} groups of results`}
                </Typography>}
                {renderableResults}
            </Fragment>
        } else if (preview) {
            return <Fragment>
                <Typography className="pb-1" variant="subtitle2">Search Compass</Typography>
                <Typography variant="body2">
                    Search for people, buildings, university systems, IT knowledgebase articles and more!
                 </Typography>
            </Fragment>
        }
        return <Fragment>
            <QuickLinks className="mb-2" />
            <Paper elevation={elevation.standard} className="p-2">
                <Typography className="mb-1" variant="h6" component="h2">Suggested Search</Typography>
                <Divider className="mb-1" component="div" />
                <Typography className="mb-2" variant="body2">Here are a few suggestions to search across Compass.</Typography>
                {searchStrings.map((o, index) => <Button className="mr-1 mb-1" variant="outlined" key={`suggested-search${index}`} color="secondary" onClick={() => this.props.executeSearch({ query: o })}>{o}</Button>)}
            </Paper>
        </Fragment>
    }

}

Results.defaultProps = {
    results: [],
    filters: [],
    preview: false,
    show: 10,
    constructWrapper: null
}

Results.propTypes = {
    show: PropTypes.number,
    preview: PropTypes.bool,
    query: PropTypes.string,
    constructWrapper: PropTypes.func,
    filters: PropTypes.arrayOf(PropTypes.string),
    results: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        results: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
            description: PropTypes.string,
            identifier: PropTypes.string
        }))
    }))
}

Results.contextType = AppContext

export default Results