import moment from 'moment';

function constructAnnouncements(list = []) {
    const formattedList = Array.isArray(list)
        ? list.map(item => transformAnnouncement(item))
            .filter(item => {
                const { heading, description, id, updatedDate, action } = item

                return heading && description && id && moment(updatedDate).isValid() && action.url
            })
        : []
    return formattedList
}

const transformAnnouncement = (obj) => {
    const announcement = ({
        heading: obj.title ? obj.title : obj.displayName,
        description: obj.message,
        id: obj.id ? obj.id : obj.sk,
        updatedDate: obj.updatedDate,
        action: {
            url: obj.url
        }
    })
    return announcement
}


function groupArchivedList (list) {

    let ancmntsDOW = '';
    let allAncmntsArray = [];
    let ancmntsContentObj = {};
    
    for (let i = 0; i < 7; i++) {
        ancmntsDOW = getDayOfWeek(i);
        let indexedDay = moment().subtract(i,'d').format('L');
        let recentList = list.filter(o => moment(o.updatedDate).format('L') == indexedDay);
        if (recentList.length !== 0) {
            ancmntsContentObj = { title: ancmntsDOW, content: recentList };
            allAncmntsArray.push(ancmntsContentObj);
        }
 
    }

    let todayMinus7 = moment().subtract(7,'d').toISOString();
    let todayMinus14 = moment().subtract(14,'d').toISOString();
    let oneWeekOldList = list.filter(o => moment(o.updatedDate).isBetween(todayMinus14, todayMinus7));

    if (oneWeekOldList.length !== 0) {
        ancmntsDOW = getDayOfWeek(7);
        ancmntsContentObj = { title: ancmntsDOW, content: oneWeekOldList };
        allAncmntsArray.push(ancmntsContentObj);
    }

    let todayMinus21 = moment().subtract(21,'d').toISOString();
    let twoWeeksOldList = list.filter(o => moment(o.updatedDate).isBetween(todayMinus21, todayMinus14));

    if (twoWeeksOldList.length !== 0) {
        ancmntsDOW = getDayOfWeek(14);
        ancmntsContentObj = { title: ancmntsDOW, content: twoWeeksOldList };
        allAncmntsArray.push(ancmntsContentObj);
    }

    let todayMinus28 = moment().subtract(28,'d').toISOString();
    let threeWeeksOldList = list.filter(o => moment(o.updatedDate).isBetween(todayMinus21, todayMinus28));

    if (threeWeeksOldList.length !== 0) {
        ancmntsDOW = getDayOfWeek(21);
        ancmntsContentObj = { title: ancmntsDOW, content: threeWeeksOldList };
        allAncmntsArray.push(ancmntsContentObj);
    }

    let olderList = list.filter(o => moment(o.updatedDate).isBefore(todayMinus28));
    
    if (olderList.length !== 0) {
        ancmntsDOW = getDayOfWeek(28);
        ancmntsContentObj = { title: ancmntsDOW, content: olderList };
        allAncmntsArray.push(ancmntsContentObj);
    }
    
    // allAncmntsArray.map((o,idx) => {console.log("o.title is ", o.title)
    //                                 console.log("o.content is ", o.content)
    //                             })
    return allAncmntsArray;

}



function getDayOfWeek(offset) {
    let dow;
    switch(offset) {
        case 0 :
            dow = 'Today';
            break;
        case 1 :
            dow = 'Yesterday';
            break;    
        case 7 :
            dow = 'Last Week';
            break;
        case 14 :
            dow = '2 Weeks Ago';
            break;
        case 21 :
            dow = '3 Weeks Ago';
            break;
        case 28 :
            dow = 'Older';
            break;
        default :
            dow = moment().subtract(offset,'d').format('dddd');
    }
    return dow
}


export { constructAnnouncements, transformAnnouncement, groupArchivedList, getDayOfWeek }
