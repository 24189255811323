import { camelCase } from 'lodash'
import { transformFilters } from '../Filters/transform'
import * as JsSearch from 'js-search';

const transformLink = (obj = {}) => {
    const link = {
        title: obj.title,
        description: obj.siteDescription,
        url: obj.url,
        department: obj.category,
        identifier: obj.id,
        meta: obj.meta
    }

    return link
}

function getGroupedFields(arr = [], field = 'category') {
    let departments = []
    const map = new Map(Array.from(arr, obj => {
        return [obj[field], []]
    }));
    arr.forEach(obj => {
        return map.get(obj[field]).push(obj)
    });
    const links = Array.from(map.values(), (o, index) => {
        const category = !!o[0] && !!o[0].category ? o[0].category : ''
        const identifier = camelCase(category) + index
        departments.push({
            id: identifier,
            title: `${category} (${o.length} Results)`
        })
        return {
            title: category,
            links: o,
            identifier
        }
    });

    return {
        links,
        departments
    }
}

const transformHelpfulLinks = (list = []) => {
    const { links = [], departments = [] } = getGroupedFields(list, 'category')
    const shape = (obj = {}) => ({
        title: obj.title,
        links: Array.isArray(obj.links) ? obj.links.map(o => transformLink(o)) : [],
        identifier: obj.identifier
    })
    const preparedLinks = links
        .filter(o => !!o && !!o.title && Array.isArray(o.links) && o.links.length > 0)
        .map(o => shape(o))

    return {
        response: list,
        _results: preparedLinks,
        results: preparedLinks,
        departments: transformFilters(departments)
    }
}

const searchHelpfulLinks = ({ query = '', documents = [], customIndexes = [] }) => {
    const indexesToSearch = [...customIndexes, 'title', 'meta', 'category', 'description']
    let search = new JsSearch.Search('identifier')
    indexesToSearch.map(o => search.addIndex(o))
    search.addDocuments(documents)

    return search.search(query)
}

const filterHelpfulLinks = (query = '', results = []) => {
    if (!query) return results
    if (!Array.isArray(results) || results.length === 0) return []
    let newResults = results.map((group) => {
        return {
            ...group,
            links: searchHelpfulLinks({ query, documents: group.links })
        }
    })

    return newResults
}

const transformDepartments = (list = []) => {
    const departments = list.map((o, index) => (
        {
            id: o.identifier,
            title: o.title,
            active: true
        }
    ))

    return departments
}

export { transformLink, transformHelpfulLinks, transformDepartments, filterHelpfulLinks, searchHelpfulLinks }