import {
    GLOBAL_SEARCH, SEARCH_STATUS, SEARCH_KNOWLEDGBASE_RESULTS, SEARCH_HR_KNOWLEDGBASE_RESULTS, SEARCH_FILTERS, SET_KNOWLEDGBASE_PARAMETERS, SEARCH_BUILDINGS, SEARCH_PEOPLE, GLOBAL_SEARCH_HELP_LINKS, SEARCH_GOOGLE, SEARCH_MARCOM, SEARCH_COLLIBRA
} from '../actions-index';
import { KNOWLEDGEBASE_KEY, HR_KNOWLEDGEBASE_KEY, BUILDINGS_KEY, FIND_PEOPLE_KEY, HELPFUL_LINKS_KEY, GOOGLE_SEARCH_KEY, MARCOM_KEYS, COLLIBRA_KEYS } from '../util/constants';
import { uniqBy } from 'lodash'


export function globalSearch(state = {}, action = {}) {
    const { payload } = action
    const { knowledgebase = {} } = state
    const filters = Array.isArray(state.filters) ? state.filters : []
    const appendToFilters = (newType = '') => !!newType && filters.includes(newType) ? filters.filter(o => o !== newType) : [...filters, newType]
    const getResults = (obj = {}) => ({ results: Array.isArray(obj.results) ? obj.results : [], _results: Array.isArray(obj._results) ? obj._results : [] })
    const updateReduxResults = (key = '', payloadOverride) => {
        const existingResults = getResults(state[key] || {})
        const payloadResults = getResults(payloadOverride || payload || {})
        const newResults = {
            results: uniqBy([...existingResults.results, ...payloadResults.results], 'identifier'),
            _results: uniqBy([...existingResults._results, ...payloadResults._results], 'identifier')
        }
        return newResults
    }

    switch (action.type) {
        case SEARCH_STATUS: {
            let preparedState = {
                ...state,
                status: payload
            }
            if(payload !== 'complete') {
                return preparedState
            }

            for (const prop in preparedState) {
                if(preparedState[prop] && preparedState[prop].hasOwnProperty('status') && preparedState[prop].status === 'loading') {
                    preparedState[prop].status = 'complete'
                }
            }
            return preparedState

        }
        case GLOBAL_SEARCH:
            if (!payload) {
                return {
                    query: '',
                    status: 'cleared'
                }
            }
            return {
                ...state,
                query: payload
            }
        case SEARCH_FILTERS:
            return {
                ...state,
                filters: appendToFilters(payload)
            }
        case SEARCH_KNOWLEDGBASE_RESULTS:
            return {
                ...state,
                [KNOWLEDGEBASE_KEY]: {
                    ...state[KNOWLEDGEBASE_KEY] || {},
                    status: 'success',
                    ...payload,
                    ...payload.updating ? updateReduxResults(KNOWLEDGEBASE_KEY) : {}
                }
            }
        case SEARCH_HR_KNOWLEDGBASE_RESULTS:
            return {
                ...state,
                [HR_KNOWLEDGEBASE_KEY]: {
                    ...state[HR_KNOWLEDGEBASE_KEY] || {},
                    status: 'success',
                    ...payload,
                    ...payload.updating ? updateReduxResults(HR_KNOWLEDGEBASE_KEY) : {}
                }
            }
        case SEARCH_BUILDINGS:
            return {
                ...state,
                [BUILDINGS_KEY]: {
                    status: 'success',
                    ...payload
                }
            }
        case SEARCH_PEOPLE:
            return {
                ...state,
                [FIND_PEOPLE_KEY]: {
                    status: 'success',
                    ...payload
                }
            }
        case GLOBAL_SEARCH_HELP_LINKS:
            return {
                ...state,
                [HELPFUL_LINKS_KEY]: {
                    status: 'success',
                    ...payload
                }
            }
        case SET_KNOWLEDGBASE_PARAMETERS:
            return {
                ...state,
                knowledgebase: {
                    ...knowledgebase,
                    ...payload
                }
            }
        case SEARCH_GOOGLE:
            return {
                ...state,
                [GOOGLE_SEARCH_KEY]: {
                    ...state[GOOGLE_SEARCH_KEY] || {},
                    status: 'success',
                    ...payload,
                    ...payload.updating ? updateReduxResults(GOOGLE_SEARCH_KEY) : {}
                }
            }
        case SEARCH_MARCOM:
            let marcomResults = { ...state }
            for (const marcomKey in (payload || {})) {
                if (MARCOM_KEYS.includes(marcomKey)) {
                    marcomResults[marcomKey] = {
                        ...state[marcomKey] || {},
                        status: 'success',
                        ...payload[marcomKey],
                        ...payload[marcomKey].updating ? updateReduxResults(marcomKey, payload[marcomKey]) : {}
                    }
                }
            }
            return marcomResults
        case SEARCH_COLLIBRA:
            let results = { ...state }
            for (const collibraKey in (payload || {})) {
                if (COLLIBRA_KEYS.includes(collibraKey)) {
                    results[collibraKey] = {
                        ...state[collibraKey] || {},
                        status: 'success',
                        ...payload[collibraKey],
                        ...payload[collibraKey].updating ? updateReduxResults(collibraKey, payload[collibraKey]) : {}
                    }
                }
            }
            return results
        default:
            return state
    }
}