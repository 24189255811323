import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    inputWrapper: {
        marginLeft: '.25rem'
    },
    inputInput: {
        width: '100%',
        display: 'block',
        textOverflow: 'ellipsis'
    },
    inputSm: {
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: 140,
            '&:focus': {
                width: 180
            },
            '&:focus-within': {
                width: 180,
            }
        },
    },
    iconButtonClear: {
        marginRight: '.25em'
    },
    iconButtonSearch: {
        margin: '0 .25em'
    }
}));

export default useStyles