import { UPDATE_LEARNING_DATA, UPDATE_LEAVE_BALANCES, UPDATE_CURRENT_PAY, UPDATE_CURRENT_TIMESHEET, HR_DATA_STATUS, UPDATE_SERVICENOW_DATA, UPDATE_HR_SERVICENOW_DATA, authSignOut } from '../actions-index';
import { transformPersonalData, transformLeaveData, transformTimesheetData, transformCurrentPay, transformLearningData, transformServiceNowData, transformNoTimesheet } from './transform';
import { API } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { generateAction } from '../redux-helpers';

export function getHrData() {
    return async dispatch => {
       let response;
        const sidebarTypes = [HR_DATA_STATUS, UPDATE_LEAVE_BALANCES, UPDATE_CURRENT_TIMESHEET, UPDATE_CURRENT_PAY]
        const setGlobalHRStatus = (status = '') => sidebarTypes.map(o => dispatch(generateAction(o, { status })))
        
        setGlobalHRStatus('loading')
        try {
            response = await API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/hr-profile`)
            console.log("hr-profile response ", response)
        } catch (error) {
            console.log('HR Profile', error)
            setGlobalHRStatus('error')
            if(error.response && error.response.status  && error.response.status === 401) {
                dispatch(authSignOut())
            }
        }

        if (response) { 
            console.log("response has ", response);
            const hasLeave = !!response.JOB_DATA && response.JOB_DATA.isLeaveEligible && !isEmpty(response.LEAVE_BALANCES)
            const hasTimesheet = !!response.JOB_DATA && response.JOB_DATA.isTimesheetEmployee && !isEmpty(response.CURRENT_TIMESHEET)
            const notCreatedTimesheet = response.JOB_DATA.isTimesheetEmployee && isEmpty(response.CURRENT_TIMESHEET)
            console.log("hasTimesheet is ", hasTimesheet);
            console.log("notCreatedTimesheet is ", notCreatedTimesheet);

            if (hasTimesheet) {
                try {
                    const time = transformTimesheetData(response.CURRENT_TIMESHEET);
                    console.log("time is ", time);
                    if (time.content.length !== 0) {
                        dispatch(updateTimesheet(transformPersonalData(time)));
                    } else {
                        const noTime = transformNoTimesheet();
                        dispatch(updateTimesheet(transformPersonalData(noTime)));
                    }
                } catch (error) {
                    console.log('Timesheet error', error)
                    dispatch(updateTimesheet({ status: 'error' }))
                }
            } else if (notCreatedTimesheet) {
                const noTime = transformNoTimesheet();
                dispatch(updateTimesheet(transformPersonalData(noTime)));
            } else {
                dispatch(updateTimesheet({ status: 'unavailable' }))
            }
            if (hasLeave) {
                try {
                    const leave = transformLeaveData(response.LEAVE_BALANCES)
                    dispatch(updateLeave(transformPersonalData(leave)))
                } catch (error) {
                    console.log('Leave error', error)
                    dispatch(updateLeave({ status: 'error' }))
                }
            } else {
                dispatch(updateLeave({ status: 'unavailable' }))
            }
            if (!isEmpty(response.CURRENT_PAY)) {
                try {
                    const pay = transformCurrentPay(response.CURRENT_PAY)
                    dispatch(updatePay(transformPersonalData(pay)));
                } catch (error) {
                    console.log('Pay error', error)
                    dispatch(updatePay({ status: 'error' }))
                }
            } else {
                dispatch(updatePay({ status: 'unavailable' }))
            }
        }
    }
}

export function getLearningData() {
    return (dispatch) => {
        dispatch(updateLearningData({ status: 'loading' }));
        API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/learning`)
            .then(response => {
                if (response) {
                    const transformedLearningData = transformPersonalData(transformLearningData(response));
                    dispatch(updateLearningData(transformedLearningData));
                }
            })
            .catch(error => {
                dispatch(updateLearningData({ status: 'error' }));
                console.log(error)
                if(error.response && error.response.status  && error.response.status === 401) {
                    dispatch(authSignOut())
                }
            })
    }
}

export function getServiceNowData() {
    return (dispatch) => {
        API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/servicenow`)
            .then(response => {
                if (response) {
                    const transformedServiceNowData = transformPersonalData(transformServiceNowData(response.data.it, "it"));
                    dispatch(updateServiceNowData(transformedServiceNowData));

                    const transformedHrServiceNowData = transformPersonalData(transformServiceNowData(response.data.hr, "hr"));
                    dispatch(updateHrServiceNowData(transformedHrServiceNowData));
                }
            })
            .catch(error => {
                console.log(error.response)
                if(error.response && error.response.status  && error.response.status === 401) {
                    dispatch(authSignOut())
                }
            })
    }
}

export function updateLeave(payload = {}) {
    return {
        type: UPDATE_LEAVE_BALANCES,
        payload
    }
}

export function updateTimesheet(payload = {}) {
    return {
        type: UPDATE_CURRENT_TIMESHEET,
        payload
    }
}

export function updatePay(payload = {}) {
    return {
        type: UPDATE_CURRENT_PAY,
        payload
    }
}

export function updateLearningData(payload = {}) {
    return {
        type: UPDATE_LEARNING_DATA,
        payload
    }
}
export function updateServiceNowData(payload = {}) {
    return {
        type: UPDATE_SERVICENOW_DATA,
        payload
    }
}
export function updateHrServiceNowData(payload = {}) {
    return {
        type: UPDATE_HR_SERVICENOW_DATA,
        payload
    }
}