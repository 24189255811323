import { POST_TEMPERATURE, TEMPERATURE_SUBMISSIONS, TEMPERATURE_STATUS, TEMPERATURE_INFO, TEMPERATURE_FORM_QUESTIONS } from "../actions-index";

export function temperatureTracker(state = {}, action = {}) {
    switch (action.type) {
        case POST_TEMPERATURE:
            return {
                ...state,
                postTemperature: {
                    status: 'success',
                    ...action.payload
                }
            }
        case TEMPERATURE_SUBMISSIONS:
            let postTemperature = action.payload.updating ? { ...state.postTemperature, status: 'success' } : {}
            return {
                ...state,
                submissions: {
                    status: 'success',
                    ...action.payload
                },
                postTemperature
            }
        case TEMPERATURE_STATUS:
            return {
                ...state,
                alert: {
                    status: 'success',
                    ...action.payload
                }
            }
        case TEMPERATURE_INFO:
            return {
                ...state,
                infoText: action.payload
            }
        case TEMPERATURE_FORM_QUESTIONS:
            return {
                ...state,
                formFields: {
                    status: 'success',
                    ...action.payload
                }
            }
        default:
            return state
    }

}