import moment from 'moment';
import { MINIMUM_FEATURED_ARTICLE_COUNT } from '../util/constants';
import { omit, sortBy } from 'lodash'
import * as JsSearch from 'js-search';

function transformArticle(obj) {
    const { metadata = {} } = obj
    return {
        img: {
            src: metadata.thumbnailURL,
            alt: metadata.excerpt,
            title: metadata.excerpt
        },
        id: obj.identifier,
        path: `/news/article/${obj.identifier}`,
        title: obj.title,
        subdescription: `${moment(obj.publishStartDate).toNow(true)} ago`,
        description: obj.subtitle,
        ...omit(obj, ['subtitle'])
    }
}

function transformContent(list = []) {
    const validateContent = (content) => content && content.title && content.articles && Array.isArray(content.articles) && content.articles.length >= MINIMUM_FEATURED_ARTICLE_COUNT

    const featuredList = Array.isArray(list)
        ? list.filter(content => validateContent(content)).map(item => {
            const { articles = [] } = item

            return {
                sortPriority: item.sectionPriority,
                title: item.title,
                id: item.sectionId,
                articles: Array.isArray(articles)
                    ? sortBy(articles.map(article => transformArticle(article)), 'priority')
                    : []
            }
        })
        : []

    return sortBy(featuredList, 'sectionPriority')
}

function searchSectionArticles(list = [], query = '') {
    if(!Array.isArray(list)) return []
    const updatedArticles = list.map(section => ({
        ...section,
        articles: filterSectionArticles(section._articles, query)
    })).filter(o => o.articles.length > 0)

    return updatedArticles
}

function filterSectionArticles(articles = [], query = '') {
    let search = new JsSearch.Search('identifier')
    search.addIndex('title')
    search.addIndex('description')
    search.addDocuments(articles)

    if (!query) return articles || []
    return search.search(query)
}

export { transformArticle, transformContent, searchSectionArticles }