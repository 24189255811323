import {
    CONTENT_FEED, CONTENT_SECTION_QUERY, SEARCH_CONTENT_SECTION, FILTER_CONTENT_FEED
} from '../actions-index';
import { transformContent, searchSectionArticles } from './transform';
import { generateAction } from '../redux-helpers';
import { sortBy, isEmpty } from 'lodash'
import { API, Auth } from 'aws-amplify';

export function filterContent(id) {
    return {
        type: FILTER_CONTENT_FEED,
        payload: id
    }
}

export function getContent(sectionId = null) {
    return async dispatch => {
        let allContent
        let setStatus = (status = '') => dispatch(generateAction(CONTENT_FEED, { status }))
        setStatus('loading')
        try {
            let validatedSections = []
            let response = await fetchContent()

            if (!isEmpty(response) && response.data && Array.isArray(response.data.sections)) validatedSections = response.data.sections
            allContent = sortBy(transformContent(validatedSections), o => o.sortPriority)
        } catch (error) {
            console.log('Error fetching featured content', error)
            setStatus('error')
        }
        if (Array.isArray(allContent)) {
            dispatch(generateAction(CONTENT_FEED, allContent))
            !!sectionId && dispatch(filterContent(sectionId))
        }
    }
}

export function searchSection(query = '', updateResults = false) {
    return (dispatch, getState) => {
        const { content } = getState() || {}
        const { _list, list = [] } = content

        if (updateResults) {
            dispatch({
                type: SEARCH_CONTENT_SECTION,
                payload: {
                    query,
                    list: Array.isArray(list) ? searchSectionArticles(list, query) : _list
                }
            })
        } else {
            dispatch({
                type: CONTENT_SECTION_QUERY,
                payload: query
            })
        }
    }
}

async function fetchContent() {
    let response
    try {
        await Auth.currentSession()
        response = await API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, `/content/channel/compass`)
        if (!isEmpty(response) && response.data && response.data.sections  && Array.from(response.data.sections).length > 0) {
            return response
        } else {
            throw new Error('Unexpected response from authenticated content')
        }
    } catch (error) {
        console.error(`Error fetching content`, error)
        return API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME + '-public', `/content/channel/compass`)  
    }
}