import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SearchPreview from "../components/SearchPreview";

const mapStateToProps = (state, ownProps) => {
    const { globalSearch = {} } = state
    const { status } = globalSearch

    return {
        status
    };
};

function verifySearchPreviewProps(prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

export default withRouter(connect(mapStateToProps)(React.memo(SearchPreview, verifySearchPreviewProps)));