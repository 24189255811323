export const alertMapping = {
    YELLOW: 'warning',
    GREEN: 'success',
    RED: 'error'
}

export const additionalResources = [{
    label: 'COVID-19 Health Reporting Reports',
    href: `https://healthreporting.osu.edu/`
}, {
    label: 'Safe and Healthy Buckeyes',
    href: `https://safeandhealthy.osu.edu/`
}, {
    label: 'Student Health Services',
    href: 'https://shs.osu.edu/covid-19/appointments/'
}, {
    label: 'CDC - Coronavirus Disease 2019 (COVID-19)',
    href: 'https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/'
}]