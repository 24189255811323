import React, { Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { Link, Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import '../styles/index.css'
import useStyles from '../styles/theme';

function ContactListItem(props = {}) {
    const classes = useStyles()
    const { name, affiliations, jobTitles, email = {}, phone = {}, className, divider, component } = props
    const verifyObj = (obj = {}) => !!obj && !!obj.display && !!obj.href
    if (!name) return null
    return <Fragment>
        <ListItem component={component} disableGutters className={`${className || ''} contactList`} classes={{ root: classes.contactList }} alignItems={'flex-start'}>
            <Typography noWrap variant="body1">{name}</Typography>
            {!!affiliations && <Typography color="secondary" noWrap variant="body2">{affiliations}</Typography>}
            {!!jobTitles && <Typography color="secondary" noWrap variant="body2">{jobTitles}</Typography>}
            {verifyObj(phone) && <Link classes={{ root: classes.contactLink }} href={phone.href} color="primary">{phone.display}</Link>}
            {verifyObj(email) && <Link classes={{ root: classes.contactLink }} href={email.href} color="primary">{email.display}</Link>}
        </ListItem>
        {divider && <Divider component="li" />}
    </Fragment>
}

export default ContactListItem

ContactListItem.defaultProps = {
    identifier: null,
    divider: true,
    className: '',
    title: '',
    description: '',
    url: '',
    path: '',
    favorite: false,
    ariaLabel: null,
    image: {}
}

ContactListItem.propTypes = {
    className: PropTypes.string,
    image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string
    }),
    identifier: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    path: PropTypes.string,
    favoriteAction: PropTypes.func,
    favorite: PropTypes.bool,
    divider: PropTypes.bool,
    ariaLabel: PropTypes.string
}