import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from "react-router-dom";
import useAuthentication from '../../hooks/useAuthentication'
import SimpleLoader from '../../Common/components/SimpleLoader'
import Hidden from '../../Helmet/components/Hidden';

export function RenderableView( { pathInformation = {}, renderProps = {}, createTitle }) {
    const { path, props = {}, title, LazyComponent, noIndex, authRequired } = pathInformation
    const { authentication = { status: '' } } = useAuthentication()
    const generateTitle = createTitle && createTitle(title)

    if (!!authRequired && (authentication.status !== 'success')) {
        return <UserValidation path={path} status={authentication.status} />
    }

    return <Fragment>
        {(noIndex || authRequired) && <Hidden />}
        <LazyComponent  {...renderProps}  {...props} generateTitle={generateTitle} />
    </Fragment>
}

const UserValidation = ({ path = '', status = '' }) => {
    if (status === 'loading') {
        return <SimpleLoader role="alert" />
    } else if (!status || status === 'error') {
        return <Redirect
            to={{
                pathname: "/login",
                state: { from: path }
            }}
        />
    }

    return <Redirect to={'/'} />
}

RenderableView.defaultProps = {
    renderProps: {},
    pathInformation: {
        props: {},
        noIndex: false, 
        authRequired: false,
    }
}

RenderableView.propTypes = {
    pathInformation: PropTypes.shape({
        path: PropTypes.string.isRequired, 
        props: PropTypes.object, 
        title: PropTypes.string.isRequired, 
        LazyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired, 
        noIndex: PropTypes.bool, 
        authRequired: PropTypes.bool
    }).isRequired, 
    renderProps: PropTypes.object, 
    createTitle: PropTypes.func,
}

export default RenderableView