export const SEARCH_DATA_SIZE = 10
export const MINIMUM_FEATURED_ARTICLE_COUNT = 1
export const MAXIMUM_FEATURED_ARTICLE_COUNT = 6
export const PROJECT_NAME = 'Compass'
export const LEAVE_BALANCES_KEY = 'LEAVE_BALANCES'
export const JOB_DATA_KEY = 'JOB_DATA'
export const CURRENT_TIMESHEET_KEY = 'CURRENT_TIMESHEET'
export const LEARNING_KEY = 'LEARNING'
export const SERVICENOW_KEY = 'SERVICENOW'
export const HR_SERVICENOW_KEY = 'HR_SERVICENOW'
export const CURRENT_PAY_KEY = 'CURRENT_PAY'
export const STYLESHEET_LENGTH = document && document.styleSheets ? document.styleSheets.length : 99
export const KNOWLEDGEBASE_KEY = 'knowledgebase'
export const HR_KNOWLEDGEBASE_KEY = 'hr-knowledgebase'
export const BUILDINGS_KEY = 'buildings'
export const LACTATION_ROOM_KEY = 'lactationRooms'
export const GENDER_INCLUSIVE_KEY = 'genderInclusive'
export const FIND_PEOPLE_KEY = 'people'
export const HELPFUL_LINKS_KEY = 'helpfulLinks'
export const GOOGLE_SEARCH_KEY = 'google'
export const ADMIN_RESOURCE_CENTER_KEY = 'admin-resource-center'
export const MARCOM_KEYS = [ADMIN_RESOURCE_CENTER_KEY]
export const COLLIBRA_BUSINESS_KEY = 'business-term'
export const COLLIBRA_SSRS_KEY = 'ssrs'
export const COLLIBRA_WORKDAY_KEY = 'workday-reports'
export const COLLIBRA_TABLEAU_KEY = 'tableau-reports'
export const COLLIBRA_KEYS = [COLLIBRA_BUSINESS_KEY, COLLIBRA_SSRS_KEY, COLLIBRA_WORKDAY_KEY, COLLIBRA_TABLEAU_KEY]
export const GOOGLE_SEARCH_LIMIT = 20
export const QUERY_PREFIX = '?q='
export const TITLE_PREFIX = '?t='
export const AM = 'a';
export const PM = 'p';
export const HOURS_IN_A_DAY = 24;
export const HOUR_INTERVAL_RANGE = 3;
export const FEET_IN_A_MILE = 5280;
export const DEFAULT_COORDINATES = { latitude: 40.001671, longitude: -83.019726 };
export const FAHRENHEIT_SYMBOL = String.fromCharCode(8457)
export const CELSIUS_SYMBOL = String.fromCharCode(8451)
export const TEMPERATURE_SCALE = {
    fahrenheit: 'FAHRENHEIT',
    celsius: 'CELSIUS'
}
export const TEMPERATURE_RANGE = {
    min: 92.00,
    max: 105.00
}
export const ACCESSIBLE_REFLOW_HEIGHT = `399px`
export const FOCUSABLE_ELEMENT_QUERY = 'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
export const EXTERNAL_LINK_DESCRIPTOR = 'external-link-helper'
export const API_STANDARD_LIMIT_COUNT = 10
export const COMPASS_SUMMARY = 'view assigned BuckeyeLearn trainings, personalized news, HR and IT tickets, paycheck information, and more.'