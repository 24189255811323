import React from 'react'
import moment from "moment";
import { isEmpty, sortBy } from 'lodash';
import getLabel from './labels'
import { CURRENT_TIMESHEET_KEY, LEAVE_BALANCES_KEY, CURRENT_PAY_KEY, LEARNING_KEY, SERVICENOW_KEY, HR_SERVICENOW_KEY } from "../util/constants";
import ExternalLink from '../Common/components/ExternalLink';

function transformCardRowData(obj = {}) {
    return ({
        left: obj.left,
        right: obj.right,
        subheading: obj.subheading,
        private: obj.private,
        url: obj.url
    })
}

function transformCardData(obj = {}) {
    return {
        key: obj.key,
        content: Array.isArray(obj.content) ? obj.content.map(card => transformCardRowData(card)) : []
    }
}

function transformPersonalData(data) {
    if (data && Array.isArray(data)) {
        return data.map((item) => transformCardData(item))
    } else if (data && data.key) {
        return transformCardData(data)
    }
}

function transformLeaveData(leaveData) {
    console.log("leaveData has ", leaveData);
    var hrContentArray = [];
    var hrElements = {};

    if (!isEmpty(leaveData) && Object.values(leaveData.current.Balances).length > 0) {
        let hrContentAsOf, hrContentSck, hrContentCmp, hrContentVac
        if (leaveData.current) {
            const { current } = leaveData;
            const { Balances } = current;
            hrContentAsOf = { left: "Balance as of", right: formatDate(current.BalanceAsOf.split('T')[0]) };
            for (let b in Balances) {
                if (Balances[b].description === "Vacation") {
                    hrContentVac = { left: Balances[b].description + ' Hours', right: Balances[b].hours };
                } else if (Balances[b].description === "Sick") {
                    hrContentSck = { left: Balances[b].description + ' Hours', right: Balances[b].hours };
                } else if (Balances[b].description === "Comp Time") {
                    hrContentCmp = { left: Balances[b].description, right: Balances[b].hours };
                }
            };
        }
        let pendingVac = []
        let pendingSick = []
        let pendingCmp = []
        if (Object.values(leaveData.pending).length > 0) {
            for (var prpty in leaveData.pending) {
                if (prpty === "Vacation") {
                    var hrPendingVac = { left: "Pending Vacation Hours", right: leaveData.pending[prpty] };
                    pendingVac.push(hrPendingVac);
                } else if (prpty === "Sick") {
                    var hrPendingSck = { left: "Pending Sick Hours", right: leaveData.pending[prpty] };
                    pendingSick.push(hrPendingSck);
                }  else if (prpty === "CompTime") {
                    var hrPendingCmp = { left: "Pending Comp Time Hours", right: leaveData.pending[prpty] };
                    pendingCmp.push(hrPendingCmp);
                }
            }
        }
        hrContentAsOf && hrContentArray.push(hrContentAsOf);
        hrContentVac && hrContentArray.push(hrContentVac);
        if (pendingVac.length > 0) hrContentArray.push(...pendingVac);
        hrContentSck && hrContentArray.push(hrContentSck);
        if (pendingSick.length > 0) hrContentArray.push(...pendingSick);
        hrContentCmp && hrContentArray.push(hrContentCmp);
        if (pendingCmp.length > 0) hrContentArray.push(...pendingCmp);

        hrElements = { key: LEAVE_BALANCES_KEY, content: hrContentArray };
    }
    return hrElements;
}

function transformTimesheetData(tsData = {}) {
    console.log("tsData has ", tsData);
    var hrContentArray = [];
    var hrElements = {};
    // const tsDataArr = Object.values(tsData);
    const tsDataArr = Object.values(tsData);
    console.log("tsDataArr has ", tsDataArr);
    Object.values(tsData).forEach(item => {
        let hrContentObj = {};
        // hrContentObj = { left: "Position Title", right: item.positionTitle};
        // hrContentArray.push(hrContentObj);
        if (parseInt(item.totalReportedHours) !== 0) { 
            hrContentObj = { left: "Week Of", right: formatDate(item.weekOf)};
            hrContentArray.push(hrContentObj);
            if (parseInt(item.regular) > 0) {
                hrContentObj = { left: "Regular", right: item.regular};
                hrContentArray.push(hrContentObj);
            }
            if (parseInt(item.timeOff) > 0) {
                hrContentObj = { left: "Time Off", right: item.timeOff};
                hrContentArray.push(hrContentObj);
            }
            if (parseInt(item.overTime) > 0) {
                hrContentObj = { left: "Overtime", right: item.overTime};
                hrContentArray.push(hrContentObj);
            }
            if (parseInt(item.compTime) > 0) {
                hrContentObj = { left: "Comp Time Earned", right: item.compTime};
                hrContentArray.push(hrContentObj);
            }
            if (parseInt(item.shiftTime) > 0) {
                hrContentObj = { left: "Shift Time", right: item.shiftTime};
                hrContentArray.push(hrContentObj);
            }
            if (parseInt(item.holidayBenefit) > 0) {
                hrContentObj = { left: "Holiday Benefit", right: item.holidayBenefit};
                hrContentArray.push(hrContentObj);
            }
            if (parseInt(item.holidayWorked) > 0) {
                hrContentObj = { left: "Holiday Worked", right: item.holidayWorked};
                hrContentArray.push(hrContentObj);
            }
            hrContentObj = { left: "Total Reported Hours", right: item.totalReportedHours};
            hrContentArray.push(hrContentObj);
        } else {
            hrContentObj = { left: "Week Of", right: formatDate(item.weekOf)};
            hrContentArray.push(hrContentObj);
            hrContentObj = { left: "You have not entered any hours for this week so login into Workday and add time to your timesheet.", right: ""};
            hrContentArray.push(hrContentObj);
        }
    });

  
    hrElements = { key: CURRENT_TIMESHEET_KEY, content: hrContentArray };
    console.log(`hrElements has `, hrElements);
    return hrElements;
}

const formatDate = function(input) {
    console.log("do format date")
    var pattern = /(\d{4})-(\d{2})-(\d{2})/;
    if (!input || !input.match(pattern)) {
      return null;
    }
    return input.replace(pattern, '$2/$3/$1');
};


function transformNoTimesheet() {
    var hrContentArray = [];
    var hrContentObj = {};
    var hrElements = {};
    hrContentObj = { left: "You haven’t entered any time for this pay period! Head to Workday to enter your time.", right: ""};
    hrContentArray.push(hrContentObj);
    hrElements = { key: CURRENT_TIMESHEET_KEY, content: hrContentArray };
    return hrElements;
}

function transformCurrentPay(payData) {
    let hrCurrPayArray = [{
        left: <span>
            Payslips prior to Workday will be accessible through <ExternalLink href="https://legacypaydocs.osu.edu/" eventLabel="Sidebar: Legacy Pay Docs">Legacy Pay Documents</ExternalLink>.
        </span>
    }];
    var hrElements = {};

    if (!isEmpty(payData)) {
        payData.date && hrCurrPayArray.push({ left: "Check Date", right: moment(payData.date.toString()).format('MM/DD/YYYY') });
        payData.net && hrCurrPayArray.push({ left: "Net Pay", right: formatter.format(payData.net), private: true });
    }

    if(hrCurrPayArray.length > 0) {
        hrElements = { key: CURRENT_PAY_KEY, content: hrCurrPayArray };
    }

    return hrElements;
}

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function transformLearningData(learningData) {
    let learningDataArray = [];
    var learningElements = {};

    if (!isEmpty(learningData)) {
        Object.values(learningData).forEach(ld => {
            var learningDataObj = {};
            const learningDate = ld.dueDate == null ? 'No Due Date' : moment(ld.dueDate.toString()).format('MM/DD/YYYY');
            learningDataObj = { left: ld.name, right: learningDate, url: ld.url };
            learningDataArray.push(learningDataObj);
        })
        learningElements = { key: LEARNING_KEY, content: sortBy(learningDataArray, o => new Date(o.right)) };
    } else {
        learningElements = {
            key: LEARNING_KEY,
            content: [{
                left: 'You have no trainings assigned in your transcript! Visit BuckeyeLearn for more training and professional development opportunities.'
            }]
        }
    }

    return learningElements
}

function transformServiceNowData(serviceNowData, key) {
    var transformedServiceNowData = [];
    let serviceNowDataArray = [];
    var serviceNowElements = {};

    if (!isEmpty(serviceNowData)) {
        Object.values(serviceNowData).forEach(snd => {
            var serviceNowDataObj = {};
            serviceNowDataObj = { left: snd.description, right: snd.status, url: snd.url };
            serviceNowDataArray.push(serviceNowDataObj);
        })
        serviceNowElements = { key: key === "it" ? SERVICENOW_KEY : HR_SERVICENOW_KEY, content: serviceNowDataArray };
        transformedServiceNowData = serviceNowElements;
    }
    return transformedServiceNowData;
}


const transformPropTypes = (obj = {}) => {
    const { status = '', key = '' } = obj
    const label = obj.key ? getLabel(obj.key) : {}
    const { title = '', CustomIcon = null, url = '', secondaryLink } = label
    const loading = status === 'loading'
    const invalid = !label || (!Array.isArray(obj.content) || obj.content.length === 0)
    if (!loading && invalid) return {}

    const shape = {
        key: '',
        title: '',
        CustomIcon: null,
        url: '',
        status: '',
        content: [],
        secondaryLink: {}
    }
    const assignment = Object.assign(shape, {
        key,
        title,
        CustomIcon,
        url,
        secondaryLink,
        status: obj.status,
        content: Array.isArray(obj.content) ? obj.content : []
    })

    return assignment
}

export { transformCardRowData, transformCardData, transformPersonalData, transformLeaveData, transformTimesheetData, transformCurrentPay, transformLearningData, transformServiceNowData, transformPropTypes, transformNoTimesheet }