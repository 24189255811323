import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import LinkableListItem from '../../CustomListItems/components/LinkableListItem';
import StickyListWrapper from '../../CustomListItems/components/StickyListWrapper';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { QUERY_PREFIX, BUILDINGS_KEY, FIND_PEOPLE_KEY } from '../../util/constants';
import BuildingListItem from '../../CustomListItems/components/BuildingListItem';
import ContactListItem from '../../CustomListItems/components/ContactListItem';
import { searchTypes } from "../types";
import withBreadcrumbTrail from '../../Common/components/withBreadcrumbTrail'

function ResultFull(props = {}) {
    const { loading, result = {}, count, showFooter, query, expandSearch, setCurrentPageTitle, setCurrentRoutes } = props
    const location = useLocation()
    const { type, total = 0, label, results = [], _results, identifier } = result
    const params = useParams() || {}
    const individual = searchTypes.map(o => o.type).includes(params.type)
    const show = individual ? 10 : 5
    const [showingResultCount, setShowingResultCount] = useState(show)

    useEffect(() => {
        const newNum = individual ? 10 : 5
        setShowingResultCount(count => newNum !== count ? newNum : count)
    }, [individual])

    useEffect(() => {
        if(!!label && individual) {
            setCurrentPageTitle(`Results in ${label}`)
            setCurrentRoutes([
                {
                    to: { pathname: '/search', search: '' },
                    label: 'Search'
                },
                {
                    to: { pathname: '/search', search: location.search },
                    label: query
                }
            ])
        } else {
            setCurrentPageTitle('')
            setCurrentRoutes([])
        }
    }, [label, individual, query, setCurrentPageTitle, setCurrentRoutes])
    
    const statusForRendering = !individual && loading ? 'loading' : result.status
    const verifiedResults = Array.isArray(results) ? results.slice(0, showingResultCount) : []
    const allResultsShowing = results.length >= result.total
    const scrollId = 'scroll-last-child-id'
    const showCard = statusForRendering === 'loading' || verifiedResults.length > 0
    const byTypes = individual && type ? [type] : []


    const increaseSearch = () => {
        const dataCompleted = Array.isArray(_results) && _results.length === total
        const newShow = showingResultCount + 10
        if (newShow > _results.length && !allResultsShowing && !dataCompleted) {
            expandSearch({ updating: true, query, byTypes, from: _results.length, to: newShow })
        }
        setShowingResultCount(newShow)
    }

    const footer = () => {
        if (showFooter && !individual) {
            return <Button
                className="ml-auto"
                color="primary"
                component={RouterLink}
                to={{
                    pathname: `/search/${type}/`,
                    search: `${QUERY_PREFIX}${query}`
                }}
            >
                See more
        </Button>
        } else if (verifiedResults.length < total) {
            return <Button
                className="m-0-auto"
                color="primary"
                onClick={() => increaseSearch()}
            >
                Show more
         </Button>
        }
        return null
    }

    const stickyWrapperProps = {
        className: "d-flex flex-column",
        key: type,
        title: individual && !!label ? `${label} (${verifiedResults.length || 0} of ${total || 0} results)` : label,
        loading,
        showPartialLoader: individual && verifiedResults.length > 0 && loading,
        footer: footer(),
        headingStart: 2
    }

    return <Fragment>
        {showCard && <StickyListWrapper {...stickyWrapperProps}>
            {verifiedResults
                .map((item, index) => {
                    const props = {
                        scrollId: verifiedResults.length - 2 === index && scrollId,
                        key: `search#card#${identifier}${type}${count}${index}`,
                        className: 'pl-0',
                        ...item,
                        title: item.title,
                        divider: index !== verifiedResults.length - 1,
                        status: statusForRendering
                    }

                    return item.type === BUILDINGS_KEY
                        ? <BuildingListItem  {...props} />
                        : item.type === FIND_PEOPLE_KEY
                            ? <ContactListItem {...props} />
                            : <LinkableListItem {...props} />
                })}
        </StickyListWrapper>}
    </Fragment >
}

ResultFull.defaultProps = {
    showFooter: true,
    show: 10
}

ResultFull.propTypes = {
    showFooter: PropTypes.bool
}

export default withBreadcrumbTrail(ResultFull)