import { orderBy } from 'lodash'
import moment from 'moment'
import { alertMapping } from './constants'
import { FAHRENHEIT_SYMBOL, TEMPERATURE_SCALE, CELSIUS_SYMBOL } from '../util/constants'
import { TemperatureOptions } from '../util/cookies'
import { fahrenheitToCelsius } from '../util/util'

function transformSubmissions(submissions = []) {
    if (!Array.isArray(submissions)) return []

    const sortedSubmissions = orderBy(submissions, submission => new Date(submission.date), 'desc')
        .map(submission => {
            let temperature = ''
            let submitDateTime = moment(submission.date).format('MM/DD/YYYY h:mm a')
            let data = [
                {
                    label: 'When',
                    value: submitDateTime,
                }, 
                ...submission.data.map(entry => {
                    if(entry.label.toLowerCase() === 'temperature') {
                        if(!entry.value) return entry
                        temperature = `${entry.value} ${FAHRENHEIT_SYMBOL}`
                        const scale = TemperatureOptions.get()
                        if(scale === TEMPERATURE_SCALE.celsius) {
                            temperature = `${fahrenheitToCelsius(entry.value)} ${CELSIUS_SYMBOL}`
                        }
                        return {
                            ...entry,
                            value: temperature
                        }
                    }
                    return entry
                })
            ]

            return {
                submitDateTime,
                temperature,
                data
            }
        })

    return sortedSubmissions
}

function transformTemperatureAlert(alert = {}) {
    const { message, statusCode, nextEntryRequiredBy, overdue, backgroundColor, darkColor , cleared} = alert

    return {
        severity: alertMapping[statusCode] || 'info',
        nextEntryRequiredBy: !!nextEntryRequiredBy ? moment(nextEntryRequiredBy).format('dddd, MMMM D, YYYY h:mm A') : '',
        overdue,
        backgroundColor,
        darkColor,
        cleared,
        message
    }
}

export function transformSymptomTracking(symptomTracker = {}) {
    const { status = { statusCode: '', message: '' }, history = [] } = symptomTracker

    return {
        submissions: transformSubmissions(history || []),
        alert: transformTemperatureAlert(status)
    }
}

export function transformHeadings(rows = []) {
    let headings = []
    rows.forEach(row => {
        row.data.forEach(submission => {
            if(!headings.includes(submission.label)) {
                headings.push(submission.label)
            }
        })
    })

    return headings
}