
import React from 'react';
import { Helmet } from "react-helmet";

function Hidden() {
    return <Helmet>
      <meta id="meta-hidden" name="robots" content="noindex" />
    </Helmet>
}

export default Hidden