import { SET_USER, USER_STATUS } from "../actions-index";
import { Auth } from 'aws-amplify';
import { redirectToLogIn } from '../util/util';
import { transformUser } from "./transform";
import { createException } from "../util/analytics";

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: transformUser(user)
    }
}

export function setUserStatus(status) {
    return {
        type: USER_STATUS,
        payload: status
    }
}

export function authSignOut() {
    return function (dispatch) {
        Auth.signOut()
        dispatch(setUserStatus('error'))
        dispatch({
            type: SET_USER,
            payload: null
        })
    }
}

export function setUser(forceRedirect = false, redirectPath = '') {
    return async function (dispatch) {
        const maxRetry = 2
        dispatch(setUserStatus('loading'))

        try {
            const session = await getCurrentSession({ redirectPath, forceRedirect });
            if (!session || !session.idToken || !session.idToken.payload) {
                throw 'No session id token'
            } 

            dispatch(recievedSetUser(session.idToken.payload));
        } catch (error) {
            dispatch(setUserStatus('error'))
            
            let gaDescription = `Unable to get currentSession from Auth, retried ${maxRetry} times.`
            if(!!redirectPath) gaDescription += ` User attempted redirect from ${redirectPath}.`

            createException(gaDescription)
        }
        
        return

    }
}

function createTimeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
 
export async function getCurrentSession(options = {}) {
    const { currentCount = 0, maxRetry = 2, timeInterval = 1500, redirectPath = '', forceRedirect = false } = options
    if(currentCount > maxRetry) {
        throw new Error('Maximum retries exceeded')
    }
    
    try {
        let session = await Auth.currentSession();
        if (!session) {
            throw 'Invalid session'
        }
        return session
    }
    catch (error) {
        if (forceRedirect && error === 'No current user') {
            redirectToLogIn(redirectPath);
            return null
        }

        await createTimeout(timeInterval * currentCount)
        return await getCurrentSession({ currentCount: currentCount + 1, maxRetry })
    }
}
