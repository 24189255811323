import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, Link } from "@material-ui/core"
import { Link as RouterLink, useLocation } from "react-router-dom"

const BreadcrumbTrail = (props = {}) => {
    const { pathname, search } = useLocation() || {}    
    const { routes, pageTitle, ...rest } = props
    if(!Array.isArray(routes) || routes.length === 0 || !pathname) return null
    let to = pathname
    if(!!search) to += search

    return <Breadcrumbs {...rest} className="mb-1" aria-label="Breadcrumb">
        {routes.map((route, index) => <Link key={`breadcrumb#${index}`} variant="body2" color="inherit" component={RouterLink} to={route.to}>{route.label}</Link>)}
        <Link variant="body2" color="textPrimary" aria-current="page" component={RouterLink} to={to}>{pageTitle}</Link>
    </Breadcrumbs>
}   

BreadcrumbTrail.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({ 
        to: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
        label: PropTypes.string
    })),
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default BreadcrumbTrail