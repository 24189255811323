import { API } from 'aws-amplify';
import { generateAction } from '../redux-helpers';
import { FEEDBACK_CONFIRMATION } from '../actions-index';
import { capitalize, isEmpty } from 'lodash'

export function postFeedback(values) {
    return (dispatch) => {
        const dispatchStatus = (status = '') => dispatch(generateAction(FEEDBACK_CONFIRMATION, status))
        if(isEmpty(values)) return dispatchStatus('cleared')
        dispatchStatus('loading')

        const { description, type, email, appVersion, appName, userAgent } = values
        const formattedType = capitalize(type || '')
        let str = `Description: ${description}` + "\n" + "\n"
        str += `Email: ${!!email ? email : 'Anonymous'}` + "\n"
        if (!!process.env.REACT_APP_VERSION) str += 'Compass Version: ' + process.env.REACT_APP_VERSION + "\n" + "\n"
        str += 'App Version: ' + appVersion + "\n"
        str += 'App Name: ' + appName + "\n"
        str += 'User Agent: ' + userAgent + "\n"

        const body = {
            type: formattedType,
            message: str
        }
        
        if (!description) {
            dispatchStatus('error')
            return
        }
        API.post(process.env.REACT_APP_DIGITAL_HOME_API_NAME + '-public', `/feedback/`, { body })
            .then(response => {
                if (response) {
                    dispatchStatus('success')
                } else {
                    console.log('Error in feedback response', response)
                    dispatchStatus('error')
                }
            })
            .catch(error => {
                dispatchStatus('error')
                console.error(error)
            })
    }
}