const transformFilters = (arr = []) => {
    if (!Array.isArray(arr)) return []
    const expectedFilters = arr.map(o =>
        ({ id: o.id || o.identifier, title: o.title, active: o.active || false })
    )

    return expectedFilters
}

const checkFiltersForActiveId = (filters = [], id = '') => {
    const allFiltersInactive = filters.filter(o => !o.active).length === filters.length
    const match = Array.isArray(filters) && filters.find(o => o.id === id)
    return allFiltersInactive || (!!match && !!match.active)
}

export { transformFilters, checkFiltersForActiveId }