import { makeStyles } from '@material-ui/core/styles';
import { ACCESSIBLE_REFLOW_HEIGHT } from '../../util/constants';

const useStyles = makeStyles(theme => ({
    listSubheader: ({ navHeight }) => ({
        top: navHeight,
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        [`@media (max-height: ${ACCESSIBLE_REFLOW_HEIGHT})`]: {
            position: 'relative',
            top: 0
        }
    }),
    contactList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '0 0 50%',
        width: '50%'
    },
    contactLink: {
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight
    },
    contactAffiliation: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight
    },
    updatedDate: {
        align: 'right'
    },
    listWrapperPaper: {
        padding: '.75rem',
        [theme.breakpoints.up('sm')]: {
            padding: '1rem'
        },
        marginBottom: '1rem'
    }
}));

export default useStyles