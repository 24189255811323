import React, { useContext } from 'react';
import { Search as SearchIconMui, Clear } from '@material-ui/icons/';
import PropTypes from 'prop-types';
import '../styles/index.scss'
import { IconButton, Paper } from '@material-ui/core';
import SearchInputBase from './SearchInputBase';
import { omit } from 'lodash'
import { AppContext } from '../../App/context';
import { QUERY_PREFIX } from '../../util/constants';
import { Link as RouterLink } from 'react-router-dom'
import useStyles from '../styles/theme';

function SearchInputBar(props = {}) {
    const appContext = useContext(AppContext) || {}
    const { className, searchIconProps = {}, inputProps = {}, clearProps = {}, small } = props
    const { palette = { secondary: {}, primary: {} } } = appContext
    const appElevation = appContext.elevation ? appContext.elevation.standard : 1
    const { query } = inputProps
    const classes = useStyles()
    const baseClassName = `d-flex align-items-center ${className || ""}`.trim()
    const elevation = props.hasOwnProperty('elevation') ? props.elevation : appElevation
    const clearingProps = !!query ? { classes: {  root: classes.iconButtonClear }, ...clearProps } : {
        'aria-hidden': true,
        component: 'div',
        'data-testid': 'clear-button',
        className: 'd-none'
    }
    const SearchIcon = (props = {}) => {
        const formattedProps = omit(props, ['className'])
        const formattedSearchIconProps = omit(searchIconProps, ['className'])
        const mergedProps = {
            ...formattedProps || {},
            ...formattedSearchIconProps || {},
            className: searchIconProps.className
        }

        return <SearchIconMui {...mergedProps} htmlColor={palette.primary.main} />
    }

    const renderEnterSearch = () => {
        const { handleChange, query = "" } = inputProps
        if (!handleChange) return null
        let searchProps = small ? {
            role: undefined,
            component: RouterLink,
            to: { search: query ? `${QUERY_PREFIX}${query}` : '', pathname: '/search' }
        } : {
            onClick: (event) => handleChange(query || "")
        }
        return <IconButton size={small ? 'small' : 'medium'} aria-label="search" classes={{ root: classes.iconButtonSearch }} {...searchProps}  >
            <SearchIcon htmlColor={palette.secondary.main} />
        </IconButton>
    }

    return <Paper elevation={elevation} className={baseClassName} {...omit(props, ['className', 'searchIconProps', 'inputProps', 'clearProps', 'small'])}>
        <SearchInputBase {...inputProps} />
        {renderEnterSearch()}
        <IconButton size={small ? 'small' : 'medium'} {...clearingProps}>
            <Clear htmlColor={palette.secondary.main} />
        </IconButton>
    </Paper>
}

SearchInputBar.defaultProps = {
    small: false
}

SearchInputBar.propTypes = {
    inputProps: PropTypes.shape({
        small: PropTypes.bool,
        handleChange: PropTypes.func,
        query: PropTypes.string
    }),
    clearProps: PropTypes.shape({
        onClick: PropTypes.func,
        classes: PropTypes.object,
        color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
        disabled: PropTypes.bool,
        disableFocusRipple: PropTypes.bool,
        disableRipple: PropTypes.bool,
        edge: PropTypes.oneOf(['start', 'end', 'false']),
        size: PropTypes.oneOf(['small', 'medium'])
    })
}

export default SearchInputBar


