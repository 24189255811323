import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SwipeableDrawer, Drawer as MUIDrawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { omit } from 'lodash';
import { AppContext } from '../../App/context';

function Drawer(props) {
    const { show, showDrawer, swipeable, showClose } = props
    const { elevation = {} } = useContext(AppContext) || {}
    const { drawer } = elevation
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    const toggleDrawer = (open = true) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        showDrawer(open);
    };

    const Component = swipeable ? SwipeableDrawer : MUIDrawer;

    return <Component
        role="dialog" 
        aria-modal="true"
        elevation={drawer}
        {...omit(props, ['show', 'showDrawer', 'swipeable', 'children'])}
        open={show}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer()}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        key="drawer"
        >
            {showClose && <IconButton size="small" aria-label="close" className="ml-auto mt-1 mr-1" onClick={() => showDrawer(false)}>
                <CloseIcon />
            </IconButton>}
            {props.children}
        </Component>

}

Drawer.defaultProps = {
    show: false,
    swipeable: true,
    showClose: true
}

Drawer.propTypes = {
    children: PropTypes.node,
    show: PropTypes.bool,
    showDrawer: PropTypes.func,
    swipeable: PropTypes.bool
}

export default Drawer